import React, {  Fragment, PureComponent } from 'react'
//import './AdministrationLogout.css'
import { AdministrationLogoutStatus } from './AdministrationLogoutconstants';
import AdministrationLogout from "./AdministrationLogout"
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import Loading from '../../Utils/Loading';

export class AdministrationLogoutView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    }

  }
 
  closeSnackBar = () => {
    this.setState({
      open: false
    })

  }
  
  getScreen(status) {
    switch (status) {
      case AdministrationLogoutStatus.AdministrationLogout.NEW:
        return (
          <Fragment >
            <AdministrationLogout
             AdministrationLogout={this.props.AdministrationLogout} 
            className={this.props.className}
            cookies={this.props.cookies}
            />
          </Fragment>
        );


      case AdministrationLogoutStatus.AdministrationLogout.SUCCESS:
         // alert(JSON.stringify(this.props.AdministrationLogout))
        // this.props.cookies.remove('admin_token', { path: '/' });
        // this.props.cookies.remove('njt_admin_userId', { path: '/' });
       
        return (
          <Fragment >
            <AdministrationLogout
             cookies={this.props.cookies}
            AdministrationLogout={this.props.AdministrationLogout} 
            className={this.props.className}
            />
          </Fragment>
        );
        break;
      case AdministrationLogoutStatus.AdministrationLogout.FAILED:
        // alert(this.props.AdministrationLogout_Error)
        toast.error(this.props.AdministrationLogout_Error)
        return (
          <Fragment>
            
            <AdministrationLogout 
             cookies={this.props.cookies}
            AdministrationLogout={this.props.AdministrationLogout} 
            className={this.props.className}
            />

          </Fragment>
        );

      case AdministrationLogoutStatus.AdministrationLogout.LOADING:
        this.state.open = true
        return (
          <div className="admin-loading">
            <Loading/>
          </div>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
   // alert(this.props.AdministrationLogout_status)
    return (
      this.getScreen(this.props.AdministrationLogout_status)
    )
  }
}

export default withRouter(AdministrationLogoutView)
