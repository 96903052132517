
//******************Exchange Rate Server Calls******************;

import {ExchangeRatesDollarToPointsActions} from './ExchangeRatesDollarToPointsconstants';
import store from '../../Store/store'
import {ROOT_URL, getCookie} from '../../Config/Config';
import {failure_messages} from 'Messages'

export const ExchangeRatesDollarToPointsServer = {
    getExchangeRatesDollarToPoints:getExchangeRatesDollarToPoints,
    saveExchangeRatesDollarToPoints:saveExchangeRatesDollarToPoints,
    updateExchangeRatesDollarToPoints:updateExchangeRatesDollarToPoints
};

//******************Insert ExchangeRatesDollarToPointss******************;

function saveExchangeRatesDollarToPoints(data){
      const request=fetch(ROOT_URL+'/api/exchangeRate/postExchangeRatesDollarToPoints', {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             if(response.status == "200"){
           
               store.dispatch({type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.SUCCESS,payload:response.data, message:response.message});
            return ;
            }else
            {
              store.dispatch({type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.FAILED, error:response.error});
              return ;
              }    
           }).catch((error) => {
            store.dispatch({type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.FAILED, error:failure_messages.unExpectedError});
             })
             
          }).catch((error) => {
           store.dispatch({type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.FAILED, error:failure_messages.unExpectedError});
            })
      return {type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.LOADING}

};
  
//******************Get ExchangeRatesDollarToPointss******************;


//******************Update ExchangeRatesDollarToPoints******************;

  
function getExchangeRatesDollarToPoints(data){
  //alert(JSON.stringify(data))
      const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRatesDollarToPoints/', {
          method: 'POST',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
            response.json().then(response=>{
              // alert(response.status )
              if(response.status == "200"){
                console.log(response)
                if(response.exchangeRates.length>0)
             store.dispatch({type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Get.SUCCESS,payload:response.exchangeRates});
             else
             store.dispatch({type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.NEW});
             return ;
             }else
             {
               store.dispatch({type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Get.FAILED, error:response.error});
               return ;
               }    
            });
           }).catch((error) => {
            store.dispatch({type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Get.FAILED, error:failure_messages.ExchangeRateGetError});
          })
       return {type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.LOADING}
 
    };
  
function updateExchangeRatesDollarToPoints(data){
  const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
    }).then((response)=>{
     response.json().then(response=>{
       if(response.status == "200"){
         console.log(response)
           
      store.dispatch({type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.SUCCESS, payload:data.data, message:response.message});
      return ;
      }else
      {
        store.dispatch({type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.FAILED, error:response.error});
        return ;
        }    
     });
    }).catch((error) => {
      store.dispatch({type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.FAILED, error:failure_messages.unExpectedError});
    })
return {type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.LOADING}

};
