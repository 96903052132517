export const ExchangeRatesPointsToCouponActions= {
	
	
	ExchangeRatesPointsToCoupon_Get :{
		NEW:"ExchangeRatesPointsToCoupon_Get_NEW",
		SUCCESS:"ExchangeRatesPointsToCoupon_Get_SUCCESS",
		FAILED:"ExchangeRatesPointsToCoupon_Get_FALIURE",
		LOADING:"ExchangeRatesPointsToCoupon_Get_LOADING",
		NOT_AUTHORIZED: "ExchangeRatesPointsToCoupon_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
	,
	ExchangeRatesPointsToCoupon_Post :{
		NEW:"ExchangeRatesPointsToCoupon_Post_NEW",
		SUCCESS:"ExchangeRatesPointsToCoupon_Post_SUCCESS",
		FAILED:"ExchangeRatesPointsToCoupon_Post_FALIURE",
		LOADING:"ExchangeRatesPointsToCoupon_Post_LOADING",
		NOT_AUTHORIZED: "ExchangeRatesPointsToCoupon_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const ExchangeRatesPointsToCouponStatus ={

	ExchangeRatesPointsToCoupon_Get :{
		NEW:"ExchangeRatesPointsToCoupon_Get_NEW",
		SUCCESS:"ExchangeRatesPointsToCoupon_Get_SUCCESS",
		FAILED:"ExchangeRatesPointsToCoupon_Get_FALIURE",
		LOADING:"ExchangeRatesPointsToCoupon_Get_LOADING",
		NOT_AUTHORIZED: "ExchangeRatesPointsToCoupon_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	ExchangeRatesPointsToCoupon_Post :{
		NEW:"ExchangeRatesPointsToCoupon_Post_NEW",
		SUCCESS:"ExchangeRatesPointsToCoupon_Post_SUCCESS",
		FAILED:"ExchangeRatesPointsToCoupon_Post_FALIURE",
		LOADING:"ExchangeRatesPointsToCoupon_Post_LOADING",
		NOT_AUTHORIZED: "ExchangeRatesPointsToCoupon_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
