import React, { Component, Fragment } from "react";
//import './ExchangeRatesDollarToPoints.css'
import { ExchangeRatesDollarToPointsStatus } from "./ExchangeRatesDollarToPointsconstants";
import ExchangeRatesDollarToPoints from "./ExChangeRatesDollarToPointsForm";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
// import Loading from "../Utils/Loading";

export class ExchangeRatesDollarToPointsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.ExchangeRatesDollarToPoints_post_status === ExchangeRatesDollarToPointsStatus.ExchangeRatesDollarToPoints_Post
      .SUCCESS){
        toast.success(nextProps.ExchangeRatesDollarToPoints_Message)
      nextProps.setRecentlySaved()
    
      nextProps.resetReducerState()
   }
    return null;
 }
  getScreen(status) {
    switch (status) {
      case ExchangeRatesDollarToPointsStatus.ExchangeRatesDollarToPoints_Post
        .NEW:
        return (
          <Fragment>
            <ExchangeRatesDollarToPoints
              EditActiveDate={this.props.EditActiveDate}
              ViewMode={this.props.ViewMode}
              minFromDate={this.props.minFromDate}
              saveExchangeRatesDollarToPoints={
                this.props.saveExchangeRatesDollarToPoints
              }
              DeleteMode={this.props.DeleteMode}
              FromDate={this.props.FromDate}
              toDateReadOnly={this.props.toDateReadOnly}
              DateRanges={this.props.DateRanges}
              setRecentlyDeleted={this.props.setRecentlySaved}
              resetReducerState={this.props.resetReducerState}
            />
          </Fragment>
        );
      case ExchangeRatesDollarToPointsStatus.ExchangeRatesDollarToPoints_Get
        .SUCCESS:
        // alert('test')
        return (
          <ExchangeRatesDollarToPoints
            EditActiveDate={this.props.EditActiveDate}
            minFromDate={this.props.minFromDate}
            ViewMode={this.props.ViewMode}
            saveExchangeRatesDollarToPoints={
              this.props.updateExchangeRatesDollarToPoints
            }
            setRecentlyDeleted={this.props.setRecentlySaved}
            FromDate={this.props.FromDate}
            DeleteMode={this.props.DeleteMode}
            toDateReadOnly={this.props.toDateReadOnly}
            ExchangeRatesDollarToPoints={this.props.ExchangeRatesDollarToPoints}
            DateRanges={this.props.DateRanges}
            resetReducerState={this.props.resetReducerState}
          />
        );

      case ExchangeRatesDollarToPointsStatus.ExchangeRatesDollarToPoints_Post
        .SUCCESS:
           // alert(this.props.ExchangeRatesDollarToPoints_Message)

        return (
          <Fragment>
            

            <ExchangeRatesDollarToPoints
              minFromDate={this.props.minFromDate}
              ViewMode={this.props.ViewMode}
              saveExchangeRatesDollarToPoints={
                this.props.updateExchangeRatesDollarToPoints
              }
              EditActiveDate={this.props.EditActiveDate}
              FromDate={this.props.FromDate}
              DeleteMode={this.props.DeleteMode}
              toDateReadOnly={this.props.toDateReadOnly}
              ExchangeRatesDollarToPoints={this.props.ExchangeRatesDollarToPoints}
              setRecentlySaved={this.props.setRecentlySaved}
              DateRanges={this.props.DateRanges}
            />
          </Fragment>
        );

      case ExchangeRatesDollarToPointsStatus.ExchangeRatesDollarToPoints_Post
        .FAILED:
        
        toast.error(this.props.ExchangeRatesDollarToPoints_Message)
        return (
          <Fragment>
            
            <ExchangeRatesDollarToPoints
              minFromDate={this.props.minFromDate}
              ViewMode={this.props.ViewMode}
              saveExchangeRatesDollarToPoints={
                this.props.saveExchangeRatesDollarToPoints
              }
              FromDate={this.props.FromDate}
              DeleteMode={this.props.DeleteMode}
              toDateReadOnly={this.props.toDateReadOnly}
              EditActiveDate={this.props.EditActiveDate}
              DateRanges={this.props.DateRanges}
            />
          </Fragment>
        );
      case ExchangeRatesDollarToPointsStatus.ExchangeRatesDollarToPoints_Get
        .FAILED:
        toast.error(this.props.ExchangeRatesDollarToPoints_Message)
        return (
          <div>
 
            <ExchangeRatesDollarToPoints
              minFromDate={this.props.minFromDate}
              ViewMode={this.props.ViewMode}
              saveExchangeRatesDollarToPoints={
                this.props.saveExchangeRatesDollarToPoints
              }
              EditActiveDate={this.props.EditActiveDate}
              DateRanges={this.props.DateRanges}
              FromDate={this.props.FromDate}
              toDateReadOnly={this.props.toDateReadOnly}
              DeleteMode={this.props.DeleteMode}
            />
          </div>
        );
        break;
      case ExchangeRatesDollarToPointsStatus.ExchangeRatesDollarToPoints_Post
        .LOADING:
        this.state.open = true;

        return (
          <>

          <div style={{opacity:"0.3", pointerEvents:"none"}}>
          <ExchangeRatesDollarToPoints
              EditActiveDate={this.props.EditActiveDate}
              ViewMode={this.props.ViewMode}
              minFromDate={this.props.minFromDate}
              saveExchangeRatesDollarToPoints={
                this.props.saveExchangeRatesDollarToPoints
              }
              DeleteMode={this.props.DeleteMode}
              FromDate={this.props.FromDate}
              toDateReadOnly={this.props.toDateReadOnly}
              DateRanges={this.props.DateRanges}
              setRecentlyDeleted={this.props.setRecentlySaved}
              resetReducerState={this.props.resetReducerState}
            />
         
            </div>

          {/* <div style={{ textAlign: "center",display:"flex",height:"40vh", width:"100%", justifyContent:"center", alignItems:"center" }}>

            <Loading   />
          </div> */}
          </>
        );
        break;
      default:
        return <div />;
    }
  }
  render() {
    // alert(this.props.ExchangeRatesDollarToPoints_post_status)
    return (this.getScreen(this.props.ExchangeRatesDollarToPoints_post_status))
  }
}

export default withRouter(ExchangeRatesDollarToPointsView);
