import { RefrenceDataUpdateStatus, RefrenceDataUpdateActions } from './RefrenceDataUpdateConstants';

const RefrenceDataUpdate_initialState = {
    RefrenceDataUpdate_Status: RefrenceDataUpdateStatus.RefrenceData_Update.NEW,
    RefrenceDataUpdate_Message: '',
    RefrenceDataUpdateDetails: [],
}
export default  function  (state = RefrenceDataUpdate_initialState, action) {
    switch (action.type) {
        case RefrenceDataUpdateActions.RefrenceData_Update.LOADING:
            return { ...state, RefrenceDataUpdate_Status: RefrenceDataUpdateStatus.RefrenceData_Update.LOADING }
            case RefrenceDataUpdateActions.RefrenceData_Update.NEW:
                return { ...state, RefrenceDataUpdate_Status: RefrenceDataUpdateStatus.RefrenceData_Update.NEW, RefrenceDataUpdate_Message:"" }
    
        case RefrenceDataUpdateActions.RefrenceData_Update.FAILED:
            return { ...state,  RefrenceDataUpdate_Status: RefrenceDataUpdateStatus.RefrenceData_Update.FAILED, RefrenceDataUpdate_Message:action.error}
        case RefrenceDataUpdateActions.RefrenceData_Update.SUCCESS:
          
          return { ...state,  RefrenceDataUpdate_Status: RefrenceDataUpdateStatus.RefrenceData_Update.SUCCESS, RefrenceDataUpdateDetails:action.payload, RefrenceDataUpdate_Message:action.message}
          
        default:
            return { ...state,
                RefrenceDataUpdate_Status: RefrenceDataUpdateStatus.RefrenceData_Update.NEW,
            }
    }
}
