import React, { Component, Fragment } from 'react';
// import "./ReferenceTableDropDown.css";
// import dateFormat from "dateformat";
// import { CSVLink } from 'react-csv';
import LabelDropDownInput from '../../Inputs/LabelDropDownInput';

export class ReferenceTableDropDown extends Component {
  constructor(props) {
    super(props);
    // if (props.dataTable && Array.isArray(props.dataTable))
    //   props.dataTable.unshift({ value: '', name: 'Select Table' });
  }

  downloadHandler = () => {
    //  alert("downloadHandler works");
  };

  render() {
    // let dataTable = [];
    // let selectData = [{ value: '', name: 'Select Table' }];
    // if (this.props.dataTable) dataTable = this.props.dataTable;
    // dataTable = selectData.concat(dataTable);
    // dataTable.unshift(    { value: "", name: "Select Table" },    )

    return (
      <Fragment>
        <LabelDropDownInput
          data={this.props.dataTable}
          type='text'
          name='table_id'
          label='table_name'
          id="referenceTableDropDown"
          FirstValue=''
          FirstName='Select Table'
          containerStyle={{ marginTop: '20px' }}
          onChange={this.props.onSelectedTableChange}
        />
        {this.props.LoadingIcon && (
          <div className='outer-loader'>
            <div
              className='inner-loader'
              style={{ top: '12px', left: '277px' }}
            >
              <span className='input-group-addon'>
                <i className='fa fa-refresh fa-spin bc'></i>
              </span>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default ReferenceTableDropDown;
