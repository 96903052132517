import React, { Component } from "react";
import UploadBannerHeader from "../UploadBanner/UploadBannerHeader/UploadBannerHeader";
import "../ReferenceDataConfiguration/ReferenceData.css";
// import { Link } from "react-router-dom";
// import ReferenceModal from '../Cards/UploadBannerCard/ReferenceModal';
import AddRefrenceDataContainer from "./AddRefrenceData/AddRefrenceDataContainer";
import { CSVLink } from "react-csv";
import ReferenceDataListContainer from "./ReferenceDataList/ReferenceDataListContainer";
import ReferenceTableDropDownContainer from "./ReferenceTableDropDown/ReferenceTableDropDownContainer";
import dateFormat from "dateformat"

class ReferenceDataConfigurationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTable: "",
      tableData: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  csvHandler = () => {
    alert("csvHandler works");
  };
  deleteHandler = () => {
    alert("deleteHandler works");
  };
  onSelectedTableChange = (item) => {
    console.log(item.target.value);
    this.setState({ selectedTable: item.target.value });
  };
  setTableData = (data) => {
    this.setState({
      tableData: data,
    });
  };

  render() {
    var headers = [];
    if (this.state.tableData.length > 0) {
      Object.keys(this.state.tableData[0]).map((key) => {
        // alert(JSON.stringify(this.state.tableData))
        headers.push({ label: key, key: key });
      });
    }
    return (
      <div className="ref-table-box">
        <UploadBannerHeader heading="Reference Table" />
        <div className="ref-table-in-box">
          <div className="ref-table-container">
            <div className="ref-inner-box">
              <div className="ref-top-section">
                <div className="ref-drowp">
                  <ReferenceTableDropDownContainer
                    onSelectedTableChange={this.onSelectedTableChange}
                  />
                </div>
                <div className="ref-btn-box">
                  {this.state.tableData.length > 0 ? (
                    <button className="download">
                      <CSVLink
                        id="DwonloadCSVLink"
                        data={this.state.tableData}
                        headers={headers}
                        style={{ color: "white" }}
                        filename = {dateFormat(new Date(),'mm/dd/yyyy_hh:MM')+'_Reference Table.csv'}
                      >
                        Download CSV
                      </CSVLink>
                    </button>
                  ) : (
                    <button
                      id="DwonloadCSVBtn"
                      className="download disable"
                      style={{ pointerEvents: "none" }}
                    >
                      Download CSV
                    </button>
                  )}
                  <AddRefrenceDataContainer
                    selectedTable={this.state.selectedTable}
                  />
                  {/* <ReferenceModal selectedTable = {this.state.selectedTable}/> */}
                </div>
              </div>
              <ReferenceDataListContainer
                setTableData={this.setTableData}
                deleteHandler={this.deleteHandler}
                selectedTable={this.state.selectedTable}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReferenceDataConfigurationContainer;
