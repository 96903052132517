
//******************Exchange Rate Server Calls******************;

import {AddNewUserActions} from './AddNewUserConstants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"


export const AddNewUserServer = {
  //  getAddNewUser:getAddNewUser,
  AddNewAdminUser:AddNewAdminUser,
  AddNewRetailerUser:AddNewRetailerUser,
};

//******************Insert AddNewUsers******************;

function AddNewAdminUser(data){
  // alert(JSON.stringify(data))
  const request=fetch(ROOT_URL+'/api/applicationUser/createAdminUser', {
      method: 'Post',
      mode: 'cors',
      headers: { 
        'authorization':getCookie("admin_token"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
      }).then((response)=>{
       response.json().then(response=>{

         if(response.status == "200"){

        store.dispatch({type:AddNewUserActions.AddNewUser_POST.SUCCESS,payload:data, message:response.message});
        return ;
        }else
        {
          store.dispatch({type:AddNewUserActions.AddNewUser_POST.FAILED, error:response.error});
          return ;
          }    
       });
      }).catch((error) => {
      //  alert(error)
       store.dispatch({type:AddNewUserActions.AddNewUser_POST.FAILED, error:failure_messages.unExpectedError});
        })
  return {type:AddNewUserActions.AddNewUser_POST.LOADING}

};


function AddNewRetailerUser(data){
      // alert(JSON.stringify(data))
      const request=fetch(ROOT_URL+'/api/applicationUser/createRetailerProfile', {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             if(response.status == "200"){
             // alert(JSON.stringify(response))
            store.dispatch({type:AddNewUserActions.AddNewUser_POST.SUCCESS,payload:data, message:response.message});
            return ;
            }else
            {
              store.dispatch({type:AddNewUserActions.AddNewUser_POST.FAILED, error:response.error});
              return ;
              }    
           });
          }).catch((error) => {
          //  alert(error)
           store.dispatch({type:AddNewUserActions.AddNewUser_POST.FAILED, error:failure_messages.unExpectedError});
            })
      return {type:AddNewUserActions.AddNewUser_POST.LOADING}

};
  

//******************Get AddNewUsers******************;


//******************Update AddNewUser******************;

  
// function getAddNewUser(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.AddNewUser.length>0)
//              store.dispatch({type:AddNewUserActions.AddNewUser_POST.SUCCESS,payload:response.AddNewUser});
//              else
//              store.dispatch({type:AddNewUserActions.AddNewUser_POST.NEW});
             
//              return ;
//              }else
//              {
//                store.dispatch({type:AddNewUserActions.AddNewUser_POST.FAILED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:AddNewUserActions.AddNewUser_POST.FAILED});
//           })
//        return {type:AddNewUserActions.AddNewUser_POST.LOADING}
 
//     };
  
// function updateAddNewUser(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:AddNewUserActions.AddNewUser_POST.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:AddNewUserActions.AddNewUser_POST.FAILED});
//         return ;
//         }    
//      });
//     }).catch((error) => {
//       store.dispatch({type:AddNewUserActions.AddNewUser_POST.FAILED});
//     })
// return {type:AddNewUserActions.AddNewUser_POST.LOADING}

// };
