
//******************Exchange Rate Server Calls******************;

import {RefrenceDataUpdateActions} from './RefrenceDataUpdateConstants';
import store from '../../../Store/store'
import {ROOT_URL, getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
export const RefrenceDataUpdateServer = {
  //  handleRefrenceDataUpdate:handleRefrenceDataUpdate,
  handleRefrenceDataUpdate:handleRefrenceDataUpdate,
  //  updateRefrenceDataUpdate:updateRefrenceDataUpdate
};

//******************Insert RefrenceDataUpdates******************;

function handleRefrenceDataUpdate(data){
      const request=fetch(ROOT_URL+'/api/configurations/updatedRecordRefTable', {
          method: 'POST',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             if(response.status == "200"){
              toast.success(response.message);
               data.getReferenceDataList()
            store.dispatch({type:RefrenceDataUpdateActions.RefrenceData_Update.SUCCESS,payload:data, message:response.message});
            return ;
            }else
            {
              store.dispatch({type:RefrenceDataUpdateActions.RefrenceData_Update.FAILED, error:response.error });
              return ;
              }    
           })
          }).catch((error) => {
           store.dispatch({type:RefrenceDataUpdateActions.RefrenceData_Update.FAILED, error:failure_messages.unExpectedError});
            })
      return {type:RefrenceDataUpdateActions.RefrenceData_Update.LOADING}

};
  
//******************Get RefrenceDataUpdates******************;


//******************Update RefrenceDataUpdate******************;

  
// function handleRefrenceDataUpdate(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.RefrenceDataUpdate.length>0)
//              store.dispatch({type:RefrenceDataUpdateActions.RefrenceData_Update.SUCCESS,payload:response.RefrenceDataUpdate});
//              else
//              store.dispatch({type:RefrenceDataUpdateActions.RefrenceData_Update.NEW});
             
//              return ;
//              }else
//              {
//                store.dispatch({type:RefrenceDataUpdateActions.RefrenceData_Update.FAILED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:RefrenceDataUpdateActions.RefrenceData_Update.FAILED});
//           })
//        return {type:RefrenceDataUpdateActions.RefrenceData_Update.LOADING}
 
//     };
  
// function updateRefrenceDataUpdate(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:RefrenceDataUpdateActions.RefrenceData_Update.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:RefrenceDataUpdateActions.RefrenceData_Update.FAILED});
//         return ;
//         }    
//      });
//     }).catch((error) => {
//       store.dispatch({type:RefrenceDataUpdateActions.RefrenceData_Update.FAILED});
//     })
// return {type:RefrenceDataUpdateActions.RefrenceData_Update.LOADING}

// };
