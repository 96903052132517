import {
    ViewChangeLogsTableStatus,
    ViewChangeLogsTableActions
  } from "./ViewChangeLogsTableConstants";
  
  const ViewChangeLogsTable_initialState = {
    ViewChangeLogsTable_Status: ViewChangeLogsTableStatus.ViewChangeLogsTable_Get.NEW,
    ViewChangeLogsTable_Error: "",
    ViewChangeLogsTableDetails: []
  };
  export default function(state = ViewChangeLogsTable_initialState, action) {
    switch (action.type) {
      case ViewChangeLogsTableActions.ViewChangeLogsTable_Get.LOADING:
        return {
          ...state,
          ViewChangeLogsTable_Status:
            ViewChangeLogsTableStatus.ViewChangeLogsTable_Get.LOADING
        };
      case ViewChangeLogsTableActions.ViewChangeLogsTable_Get.NEW:
        return {
          ...state,
          ViewChangeLogsTable_Status:
            ViewChangeLogsTableStatus.ViewChangeLogsTable_Get.NEW,
          ViewChangeLogsTableDetails: []
        };
      case ViewChangeLogsTableActions.ViewChangeLogsTable_Get.SUCCESS:
        return {
          ...state,
          ViewChangeLogsTable_Status:
            ViewChangeLogsTableStatus.ViewChangeLogsTable_Get.SUCCESS,
          ViewChangeLogsTableDetails: action.payload
        };
      case ViewChangeLogsTableActions.ViewChangeLogsTable_Get.FAILED:
        return {
          ...state,
          ViewChangeLogsTable_Status:
            ViewChangeLogsTableStatus.ViewChangeLogsTable_Get.FAILED
        };
  
      default:
        return {
          ...state,
          ViewChangeLogsTable_Error: "",
          ViewChangeLogsTable_Status: ViewChangeLogsTableStatus.ViewChangeLogsTable_Get.NEW
        };
    }
  }
  