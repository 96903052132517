
//******************Exchange Rate Server Calls******************;

import {AdministrationLoginActions} from './AdministrationLoginconstants';
import store from '../../Store/store'
import {ROOT_URL} from '../../Config/Config';
//import {failure_messages} from 'Messages'
import {failure_messages} from 'Messages'
export const AdministrationLoginServer = {
  //  AdministrationLogin:AdministrationLogin,
  AdministrationLogin:AdministrationLogin,
  //  updateAdministrationLogin:updateAdministrationLogin
};

//******************Insert AdministrationLogins******************;

function AdministrationLogin(data){
      const request=fetch(ROOT_URL+'/api/applicationUser/login_admin', {
          method: 'Post',
          mode: 'cors',
          headers: {'Content-Type':'application/json'},
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             console.log("login details: ",response)
             if(response.status == "200"){
               delete data.password
            store.dispatch({type:AdministrationLoginActions.AdministrationLogin_GET.SUCCESS,payload:{...response,...data}, message:response.message});
            return ;
            }else
            {
              store.dispatch({type:AdministrationLoginActions.AdministrationLogin_GET.FAILED, error:response.error});
              return ;
            }    
           }).catch((error) => {
            store.dispatch({type:AdministrationLoginActions.AdministrationLogin_GET.FAILED,error:failure_messages.unExpectedError});
             })
             
          }).catch((error) => {
           store.dispatch({type:AdministrationLoginActions.AdministrationLogin_GET.FAILED,error:failure_messages.unExpectedError});
            })
      return {type:AdministrationLoginActions.AdministrationLogin_GET.LOADING}

};
  
//******************Get AdministrationLogins******************;


//******************Update AdministrationLogin******************;

  
// function AdministrationLogin(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.AdministrationLogin.length>0)
//              store.dispatch({type:AdministrationLoginActions.AdministrationLogin_Get.SUCCESS,payload:response.AdministrationLogin});
//              else
//              store.dispatch({type:AdministrationLoginActions.AdministrationLogin_GET.NEW});
             
//              return ;
//              }else
//              {
//                store.dispatch({type:AdministrationLoginActions.AdministrationLogin_Get.FAILED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:AdministrationLoginActions.AdministrationLogin_Get.FAILED});
//           })
//        return {type:AdministrationLoginActions.AdministrationLogin_GET.LOADING}
 
//     };
  
// function updateAdministrationLogin(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:AdministrationLoginActions.AdministrationLogin_GET.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:AdministrationLoginActions.AdministrationLogin_GET.FAILED});
//         return ;
//         }    
//      });
//     }).catch((error) => {
//       store.dispatch({type:AdministrationLoginActions.AdministrationLogin_GET.FAILED});
//     })
// return {type:AdministrationLoginActions.AdministrationLogin_GET.LOADING}

// };
