import {ExchangeRatesDollarToPointsStatus, ExchangeRatesDollarToPointsActions} from "./ExchangeRatesDollarToPointsconstants";

const ExchangeRatesDollarToPoints_initialState = {
  ExchangeRatesDollarToPoints_Status: ExchangeRatesDollarToPointsStatus.ExchangeRatesDollarToPoints_Post.NEW,
  ExchangeRatesDollarToPoints_Message: "",
  ExchangeRatesDollarToPointsDetails: []
};
export default function( state = ExchangeRatesDollarToPoints_initialState,action) {
  switch (action.type) {
    case ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.LOADING:
      return {
        ...state, ExchangeRatesDollarToPoints_Status:ExchangeRatesDollarToPointsStatus.ExchangeRatesDollarToPoints_Post.LOADING
      };
    case ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.NEW:
      return {
        ...state,ExchangeRatesDollarToPoints_Status:ExchangeRatesDollarToPointsStatus.ExchangeRatesDollarToPoints_Post.NEW,
        ExchangeRatesDollarToPoints_Message: ""
      };

    case ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Get.FAILED:
      return { ...state,ExchangeRatesDollarToPoints_Status: ExchangeRatesDollarToPointsStatus.ExchangeRatesDollarToPoints_Get.FAILED,
        ExchangeRatesDollarToPoints_Message:action.error
      };

    case ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Get.SUCCESS:
      return {...state,ExchangeRatesDollarToPoints_Status:ExchangeRatesDollarToPointsStatus.ExchangeRatesDollarToPoints_Get.SUCCESS,
        ExchangeRatesDollarToPointsDetails: action.payload
      };

    case ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.FAILED:
      return {...state, ExchangeRatesDollarToPoints_Status: ExchangeRatesDollarToPointsStatus.ExchangeRatesDollarToPoints_Post.FAILED,
        ExchangeRatesDollarToPoints_Message:action.error
      };
    case ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.SUCCESS:
      //  state.ExchangeRatesDollarToPointsDetails.push({})
      return {
        ...state, ExchangeRatesDollarToPoints_Status: ExchangeRatesDollarToPointsStatus.ExchangeRatesDollarToPoints_Post.SUCCESS,
        ExchangeRatesDollarToPointsDetails: action.payload, ExchangeRatesDollarToPoints_Message:action.message
      };

    default:
      return { ...state
};
  }
}
