import React, { Component, Fragment } from "react";
import "./AdministrationMain.css";
import { Link, withRouter } from "react-router-dom";
// import AdministrationLogoutContainer from "./AdministrationLogout/AdministrationLogoutContainer";
import { ADMIN_END_BRANDING_IMAGE_URL } from "../../Config/Config";


class AdministrationMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  logoutHandler = () => {
    alert("logoutHandler Works");
    // this.props.history.push({
    //   pathname: "/login_page"
    // });
  };
  render() {
    return (
      <Fragment>
        <div className="header-slider">
          <img
            src={ADMIN_END_BRANDING_IMAGE_URL + "admin_njtransit_banner.jpg"}
            alt="not found"
          />
        </div>
        <div className="adm_container">
          <div className="admin-inner-container">
            <h1 className="adm_h1">Administration - Main</h1>         
            
            <div className="main-section-box">
              <div className="main-col1">
                <div className="col-box">
                  <h4 className="link-heading">Exchange Rates</h4>
                  <div className="link-bo">
                    <p className="adm-p-link margin">
                    <span className="dot-star"><img src={ADMIN_END_BRANDING_IMAGE_URL+'1595487026493-Magenta-Star.png'} alt="not found" /></span> <Link className="adm_link1" to="/ExchangeRatesDollarToPoints">
                      Dollars to Points
                      </Link>
                    </p>
                    <p className="adm_link1">
                    <span className="dot-star"><img src={ADMIN_END_BRANDING_IMAGE_URL+'1595487026493-Magenta-Star.png'} alt="not found" /></span> <Link className="admmin_link1" to="/ExchangeRatesPointsToCoupon">
                      Points to Offer Face Value
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              
               <div className="main-col1 margin-third">
              <div className="col-box">
                  <h4 className="link-heading">Configuration & Logs</h4>
                  <div className="link-bo">
                    <p className="adm-p-link margin">
                    <span className="dot-star"><img src={ADMIN_END_BRANDING_IMAGE_URL+'1595487026493-Magenta-Star.png'} alt="not found" /></span> <Link className="adm_link1" to="view_change_logs">
                      View Change Logs
                      </Link>
                    </p>
                    <p className="adm-p-link margin">
                    <span className="dot-star"><img src={ADMIN_END_BRANDING_IMAGE_URL+'1595487026493-Magenta-Star.png'} alt="not found" /></span> <Link className="adm_link1" to="/ReferenceDataConfiguration">
                      Manage Reference Tables
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
     
            </div>
       
    
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AdministrationMain);
