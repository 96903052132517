import React, { Component } from "react";
import "../ExchangeRatesDateRanges/ExchangeRatesDateRanges.css";
import { ExchangeRateServer } from "./server";
// import Loading from '../Utils/Loading'
var dateFormat = require('dateformat');
// const data = [
//   { value: "1/01/2019 - 6/30/2019" },
//   { value: "1/01/2019 - 6/30/2019" }
// ];
class ExchangeRatesDateRanges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listIndex: "",
      DateRanges: [],
      
      isLoading:false
    };
  }
setLoadingStatus = (loading)=>{
  this.setState({
    isLoading:loading
  })
}
  setDateRange = data => {
    //alert(JSON.stringify(data))
    this.setState({
      DateRanges: data
      //  status:""
    });
    this.props.setDateRange(data);
    this.newExchangeRate();
  };
  newExchangeRate = () => {
    this.setState({ listIndex: "" });
    this.props.setDeleteMode(false);
    this.props.setToDateReadOnly(false);
    this.props.setViewMode(false);
    this.props.setEditActiveDate(false);
    const len = this.state.DateRanges.length;

    if (len > 0) {
      this.props.setMinFromDate(this.state.DateRanges[len - 1].to_date);
    }
    this.props.resetReducerState();
  };
  getDateRanges = () => {
    this.setLoadingStatus(true)
    var data = {
      setDateRange: this.setDateRange,
      setLoadingStatus:this.setLoadingStatus,
      setFromDate: this.props.setFromDate
    };
    if (this.props.DollarsToPoints)
      ExchangeRateServer.getExchangeRateDates(data);
    else ExchangeRateServer.getExchangeRateCouponDateRanges(data);
  };
  componentDidMount() {
    this.getDateRanges();
  }
  getExchangeRates = (item, index) => {
    this.setState({ listIndex: index });
    this.props.setDeleteMode(false);
    this.props.setEditActiveDate(false);
    var maxLength = this.state.DateRanges.length;

    if (index == maxLength - 1) {
      this.props.setToDateReadOnly(false);
      if (new Date(item.from_date) < new Date()) {
        this.props.setEditActiveDate(true);
      } else {
        this.props.setDeleteMode(true);
      }
    } else {
      this.props.setToDateReadOnly(true);
    }
    this.props.setViewMode(false);

    this.props.setMinFromDate(item.from_date);

    this.props.getExchangeRatesDollarToPoints(item);
  };
  getExchangeRatesViewOnly = (item, index) => {
    this.setState({ listIndex: index });
    this.props.setEditActiveDate(false);
    this.props.setViewMode(true);
    this.props.setDeleteMode(false);
    this.props.setMinFromDate(item.from_date);

    this.props.getExchangeRatesDollarToPoints(item);
  };
  render() {
    console.log("date ranges:",this.props)

    console.log ("jsdkjkfjf2", this.props.ViewMode)
    console.log ("jsdkjkfjf2", this.props.ExchangeRatesPointsToCoupon)
    console.log ("jsdkjkfjf2", this.state.DateRanges)


    return (
      <div className="ranges-box">
        <div className="ranges-text">
          <span className="ranges-text-p1">
            Exchange rates for previous date ranges.
          </span>
          <button id="newCreateBtn" className="ranges_btn1" onClick={this.newExchangeRate}>
            New
          </button>
        </div>

        <div className="ranges-main-box">
          <div className="bp_list">
            <div className="head-listrange">
              <h5>Dates</h5>
            </div>
            {!this.state.isLoading?
            this.state.DateRanges.map((res, index) =>
          
            
              <div
                className="ranges-mainbox"
                style={{
                  backgroundColor:
                    this.state.listIndex === index ? "#eee" : null
                }}
                key={index}
              >
               {
                 res.to_date && res.to_date !=null && res.to_date !=undefined?
                <p className="bp_p2">{dateFormat(res.from_date,'mm/dd/yyyy') + " - " + dateFormat(res.to_date,'mm/dd/yyyy')}</p>
                :<p className="bp_p2">{dateFormat(res.from_date,'mm/dd/yyyy') + " - " + res.to_date}</p>
               }
                <div className="ranges-btn-edit">
                  {/* {new Date(res.from_date) > new Date() ||
                  index == this.state.DateRanges.length - 1 ? (
                    <span
                      id="editLink"
                      onClick={() => this.getExchangeRates(res, index)}
                      className="ranges-edit"
                    >
                      Edit{" "}
                    </span>
                  ) : (
                    <span className="ranges-edit"></span>
                  )} */}

                  {new Date(res.from_date) > new Date() ||
                  index == this.state.DateRanges.length - 1 ? (
                    <span
                      id={index == this.state.DateRanges.length - 1?"editLinkDelete":"editLink"}
                      onClick={() => this.getExchangeRates(res, index)}
                      className="ranges-edit"
                    >
                      Edit{" "}
                    </span>
                  ) : (
                    <span className="ranges-edit"></span>
                  )}

                  <span
                    id="viewlink"
                    onClick={() => this.getExchangeRatesViewOnly(res, index)}
                    className="ranges-edit right"
                  >
                    View
                  </span>
                </div>
              </div>):
              // <Loading />
              <div className='outer-loader'>
              <div className='inner-loader'  style={{left:"533px", bottom:"9px"}}>
                <span className='input-group-addon'>
                  <i className='fa fa-refresh fa-spin bc' style={{fontSize:"15px", color:"white"}}></i>
                </span>
              </div>
            </div>
              }
          </div>
        </div>
      </div>
    );
  }
}

export default ExchangeRatesDateRanges;
