//******************Exchange Rate Server Calls******************;

import { DeleteReferenceDataActions } from "./DeleteReferenceDataConstants";
import store from "../../../Store/store";
import { ROOT_URL,getCookie } from "../../../Config/Config";
import { failure_messages } from "Messages";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
export const DeleteReferenceDataServer = {
  //  handleDeleteReferenceData:handleDeleteReferenceData,
  handleDeleteReferenceData: handleDeleteReferenceData,
  //  updateDeleteReferenceData:updateDeleteReferenceData
};

//******************Insert DeleteReferenceDatas******************;

function handleDeleteReferenceData(data) {
  const request = fetch(ROOT_URL + "/api/configurations/deleteRecordRefTable", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      response
        .json()
        .then((response) => {
          if (response.status == "200") {
            toast.success(response.message)
            data.getReferenceDataList()
            store.dispatch({
              type:
                DeleteReferenceDataActions.DeleteReferenceData_DELETE.SUCCESS,
              payload: data,
              message: response.message,
            });
            return;
          } else {
            store.dispatch({
              type:
                DeleteReferenceDataActions.DeleteReferenceData_DELETE.FAILED,
              error: response.error,
            });
            return;
          }
        })
        .catch((error) => {
          store.dispatch({
            type: DeleteReferenceDataActions.DeleteReferenceData_DELETE.FAILED,
            error: failure_messages.unExpectedError,
          });
        });
    })
    .catch((error) => {
      store.dispatch({
        type: DeleteReferenceDataActions.DeleteReferenceData_DELETE.FAILED,
        error: failure_messages.unExpectedError,
      });
    });
  return {
    type: DeleteReferenceDataActions.DeleteReferenceData_DELETE.LOADING,
  };
}

//******************Get DeleteReferenceDatas******************;

//******************Update DeleteReferenceData******************;

// function handleDeleteReferenceData(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.DeleteReferenceData.length>0)
//              store.dispatch({type:DeleteReferenceDataActions.DeleteReferenceData_DELETE.SUCCESS,payload:response.DeleteReferenceData});
//              else
//              store.dispatch({type:DeleteReferenceDataActions.DeleteReferenceData_DELETE.NEW});

//              return ;
//              }else
//              {
//                store.dispatch({type:DeleteReferenceDataActions.DeleteReferenceData_DELETE.FAILED});
//                return ;
//                }
//             });
//            }).catch((error) => {
//             store.dispatch({type:DeleteReferenceDataActions.DeleteReferenceData_DELETE.FAILED});
//           })
//        return {type:DeleteReferenceDataActions.DeleteReferenceData_DELETE.LOADING}

//     };

// function updateDeleteReferenceData(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:DeleteReferenceDataActions.DeleteReferenceData_DELETE.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:DeleteReferenceDataActions.DeleteReferenceData_DELETE.FAILED});
//         return ;
//         }
//      });
//     }).catch((error) => {
//       store.dispatch({type:DeleteReferenceDataActions.DeleteReferenceData_DELETE.FAILED});
//     })
// return {type:DeleteReferenceDataActions.DeleteReferenceData_DELETE.LOADING}

// };
