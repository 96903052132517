import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import "../../Cards/UploadBannerCard/UploadBannerCard.css";
import LabelInput from '../../Inputs/LabelInput';

const modalRoot = document.getElementById('send_portal')

class Modal extends React.Component {
  constructor(props) {
    super(props)
  
    this.state = {
      stationId: "",
      stationIdErrorMsg: "Field Required",
      stationIdFlag: false,
      password: "",
      passwordErrorMsg: "Field Required",
      passwordFlag: false,
      stationName: "",
      stationNameErrorMsg: "Field Required",
      stationNameFlag: false,
      stationTown: "",
      stationTownErrorMsg: "Field Required",
      stationTownFlag: false,
      stationAction: "",
      stationActionErrorMsg: "Field Required",
      stationActionFlag: false,
      data:props.data,
      // tmpData:props.data
    }
  }
  // stationIdHandler
  fieldChangeHandler = (e, i) => {
    // alert(i)
    // alert(e.target.value)
    // if(i > 0){
     var data = this.state.data

     data[i].value =e.target.value
    if(e.target.value == "")
    data[i].flag = true
    else
    data[i].flag = false

    //  data[key] = e.target.value
    // //  alert(this.props.data[key])
     this.setState({
       data:data
     })
    // }

    // this.setState({
    //   stationId: e,
    //   stationIdFlag: false,
      
    // });
  };
  // stationNameHandler
  stationNameHandler = e => {
    this.setState({
      stationName: e,
      stationNameFlag: false,
      
    });
  };
  // stationTownHandler
  stationTownHandler = e => {
    this.setState({
      stationTown: e,
      stationTownFlag: false,
      
    });
  };

   // stationActionHandler
   stationActionHandler = e => {
    this.setState({
      stationAction: e,
      stationActionFlag: false,
      
    });
  };
  // done function
  doneHandler = () => {
    var object = {}
let data = this.state.data
   var validFlag = true
    data.forEach((e)=>{
        if(e.value == null || e.value =="" || e.flag){
            e.flag = true
            validFlag = false
            return
        }else{
            object[e.Field] = e.value
           
        }

    })
    if(validFlag){
        this.props.addHandler(object)
        // alert(JSON.stringify(object)
    }else{
        this.setState({
            data:data
        })
    }
    // this.setState()
    // if (this.state.stationId === "") {
    //   this.setState({ stationIdFlag: true });
    // } else if (this.state.stationName === "") {
    //   this.setState({ stationNameFlag: true });
    // } else if (this.state.stationTown === "") {
    //   this.setState({ stationTownFlag: true });
    // } else if (this.state.stationAction === "") {
    //   this.setState({ stationActionFlag: true });
    // }else {
    //   var data = {
    //     stationId: this.state.stationId,
    //     stationName: this.state.stationName,
    //     stationTown: this.state.stationTown,
    //     stationAction: this.state.stationAction,
        
    //   };
    //   this.props.saveUploadCouponImage(data)
    // }
  };
  componentDidMount(){
    document.body.style.overflow = "hidden"
  }
  componentWillUnmount(){
    document.body.style.overflow = "auto"
  }
  render() {

    const {
      data,
      tmpData
    } = this.state;
    // alert(JSON.stringify(data))
    const {
      selectedTable = ""
    } = this.props

    return ReactDOM.createPortal(
      <div
        style={{
          position: 'fixed',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.3)',
          minHeight: '100vh',
          zIndex:'9999999999'
        }}
        // onClick={this.props.onClose}
      >
        <div
          style={{
            padding: 20,
            background: '#fff',
            borderRadius: '2px',
            display: 'inline-block',
            minHeight: '200px',
            margin: '1rem',
            position: 'relative',
            minWidth: '400px',
            boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
            justifySelf: 'center',
          }}
        >
          
          <div className="main-del-box">
                <div className="del-pop-text ref-model">
        {selectedTable}
                </div>
                <div className="ref-input-b">
                  {data.map((item,i)=>{
                    return(
                    <div className="ref-input" key={i}>
                    <LabelInput
                    label = {item.Field}
                    id={item.Field}
                    type="text"
                    name={item.Field}
                    inputValue={item.value}
                    errorMsg={"Field required"}
                    flag={item.flag?item.flag:false}
                   onChange={(e)=>this.fieldChangeHandler( e, i)}
                    />
                    </div>
                    )
                  })

      }
                    {/* <div className="ref-input">
                    <LabelInput
                    label = 'Station_Name'
                    type="text"
                    name="stationName"
                    inputValue={stationName}
                    errorMsg={stationNameErrorMsg}
                    flag={stationNameFlag}
                    onChange={this.stationNameHandler}
                    />
                    </div>
                    <div className="ref-input">
                    <LabelInput
                    label = 'Station_Town'
                    type="text"
                    name="stationTown"
                    inputValue={stationTown}
                    errorMsg={stationTownErrorMsg}
                    flag={stationTownFlag}
                    onChange={this.stationTownHandler}
                    />
                    </div>
                    <div className="ref-input">
                    <LabelInput
                    label = 'Station_Action'
                    type="text"
                    name="stationAction"
                    inputValue={stationAction}
                    errorMsg={stationActionErrorMsg}
                    flag={stationActionFlag}
                    onChange={this.stationActionHandler}
                    />
                    </div> */}
                </div>
            </div>
            <div className="del-pop-bnt refpadd">
                <button onClick={this.props.onClose} className="btn-yes bo">Cancel</button>
                   
                <button className="btn-yes right" onClick={this.doneHandler}>Done</button>
                {/* <button onClick={this.props.onClose} className="btn-close">No</button> */}
            </div>
          {/* <button onClick={this.props.onClose}>Close</button> */}
        </div>
        {this.props.children}
      </div>,
      modalRoot,
    )
  }
}


 class AddRefrenceData extends Component {
  constructor(props) {
    super(props)
    this.state = {showModal: false}
  }

  handleShowMessageClick = () => {
    // if(this.props.RefrenceDataFields && this.props.RefrenceDataFields.length > 0)
  //  { // alert(JSON.stringify(this.props.RefrenceDataFields))
    this.setState({showModal: true})
  //  }
  }
  handleAddDataPopup = (props) =>{
    // alert(JSON.stringify(props))
  }
  handleCloseModal = () => {
    var data = this.props.RefrenceDataFields
    data.forEach((e)=>{
      delete e.value
      delete e.flag
    })
    this.setState({showModal: false})
  }
  render() {
    // alert(JSON.stringify(this.props))
    return (
      <div
        style={{
          height: '100%',
        }}
      >
       
          {this.props.RefrenceDataFields && this.props.RefrenceDataFields.length > 0 > 0 ? (
                    <button id="addDataBtn" onClick={this.handleShowMessageClick} className="download">
                      Add Data
                    </button>
                  ) : (
                    <button
                      id="addDataBtn"
                      className="download disable"
                      style={{ pointerEvents: "none" }}
                    >
                    Add Data
                    </button>
                  )}
        
          {this.state.showModal ? (
            
            <Modal onClose={this.handleCloseModal}
            data = {this.props.RefrenceDataFields}
            selectedTable = {this.props.selectedTable}
            addHandler = {this.props.addHandler}
            // getReferenceDataList ={this.props.getReferenceDataList}
            >
            </Modal>
          ) : null}
       
      </div>
    )
  }
}

export default AddRefrenceData
