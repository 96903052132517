import React, { Component, Fragment } from 'react';
//import './ReferenceTableDropDown.css'
import { ReferenceTableDropDownStatus } from './ReferenceTableDropDown_Constants';
import ReferenceTableDropDown from './ReferenceTableDropDown';
import { withRouter } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar/';
// import Loading from '../../Utils/Loading';

export class ReferenceTableDropDown_View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mount: false,
      filterMsg: '',
    };
    props.resetReducerState();
    
    props.getReferenceTableDropDown();
  }

  closeSnackBar = () => {
    this.setState({
      open: false,
    });
  };

  getScreen(status) {
    // alert(status)
    switch (status) {
      case ReferenceTableDropDownStatus.ReferenceTableDropDown_Get.NEW:
       
        return (
          <ReferenceTableDropDown
            dataTable={this.props.dataTable}
            onSelectedTableChange={this.props.onSelectedTableChange}
          />
        );

      case ReferenceTableDropDownStatus.ReferenceTableDropDown_Get.SUCCESS:
        // this.props.setLogData(this.props.ReferenceTableDropDown)
        return (
          <ReferenceTableDropDown
            dataTable={this.props.dataTable}
            filterMsg={this.state.filterMsg}
            onSelectedTableChange={this.props.onSelectedTableChange}
          />
        );
      case ReferenceTableDropDownStatus.ReferenceTableDropDown_Get.FAILED:
        //  alert(JSON.stringify( this.props.ReferenceTableDropDown))
        return (
          <Fragment>
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              //  TransitionComponent={<Slide {...props} direction="up" />}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id='message-id'>Unable to get Records</span>}
            />
            <ReferenceTableDropDown
              dataTable={this.props.dataTable}
              onSelectedTableChange={this.props.onSelectedTableChange}
            />
          </Fragment>
        );

      case ReferenceTableDropDownStatus.ReferenceTableDropDown_Get.LOADING:
        this.state.open = true;
        return (
          // <div style={{ textAlign: "center",display:"flex", height:"16vh", width:"100%", justifyContent:"center",alignItems:"center" }}>
          //   <Loading/>
          // </div>
          <ReferenceTableDropDown
       
            LoadingIcon={true}
          />
        );
      default:
        return <div />;
    }
  }
  render() {
    // alert(this.props.history)
    console.log('akjshdbkjahsdjas', this.props);
    return this.getScreen(this.props.ReferenceTableDropDown_Get_status);
  }
}

export default withRouter(ReferenceTableDropDown_View);
