export const AddRefrenceDataActions= {
	
	RefrenceData_Add :{
		NEW:"RefrenceData_Add_NEW",
		SUCCESS:"RefrenceData_Add_SUCCESS",
		FAILED:"RefrenceData_Add_FALIURE",
		LOADING:"RefrenceData_Add_LOADING",
	},
	
}


export const AddRefrenceDataStatus ={

	RefrenceData_Add :{
		NEW:"RefrenceData_Add_NEW",
		SUCCESS:"RefrenceData_Add_SUCCESS",
		FAILED:"RefrenceData_Add_FALIURE",
		LOADING:"RefrenceData_Add_LOADING",
	}

}
