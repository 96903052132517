import React, { Component, Fragment } from "react";
//import './ReferenceDataList.css'
import { ReferenceDataListStatus } from "./ReferenceDataList_Constants";
import ReferenceDataList from "./ReferenceDataList";
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar/";
import Loading from "../../Utils/Loading";

export class ReferenceDataList_View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mount: false,
      selectedTable: this.props.selectedTable,
      filterMsg:"Please select filters to view the required records"
    };
    props.resetReducerState()
  }

  closeSnackBar = () => {
    this.setState({
      open: false
    });
  };
  static getDerivedStateFromProps(props, state) {
    console.log(props.selectedTable, state.selectedTable)
    if (JSON.stringify(props.selectedTable) !== JSON.stringify(state.selectedTable)) {
      // alert(JSON.stringify(state.getTableData))
      if(props.selectedTable != "")
      props.getReferenceDataList(props);
      else
      {
        props.setTableData([])
        props.resetReducerState()
      }
      return {
        selectedTable: props.selectedTable
      };
    } else return null;
  }

  getRefData=()=>{
    this.props.getReferenceDataList(this.props);
  }
  getScreen(status) {
    // alert(status)
    switch (status) {
      case ReferenceDataListStatus.ReferenceDataList_Get.NEW:
        //  alert(JSON.stringify( this.props.ReferenceDataList))
        return (
          <ReferenceDataList
            ReferenceDataList={this.props.ReferenceDataList}
            deleteHandler={this.props.deleteHandler} selectedTable={this.props.selectedTable}
            getReferenceDataList = {this.getRefData}
            select ={true}
          />  
        );
        // case ReferenceDataListStatus.ReferenceDataList_Get.ADD_SUCCESS:
        //   //  alert(JSON.stringify( this.props.ReferenceDataList))
        //   this.props.getReferenceDataList(this.props)
        //   return (
        //     <div style={{ textAlign: "center",display:"flex", height:"65vh", width:"100%", justifyContent:"center",alignItems:"center" }}>
        //     <Loading/>
        //   </div>
        //   );
  
      case ReferenceDataListStatus.ReferenceDataList_Get.SUCCESS:
        // alert("test")
        // setTableData = {this.setTableData}
       // this.props.setLogData(this.props.ReferenceDataList)
      //  if(this.props.selectedTable !="")
      //  this.props.setTableData(this.props.ReferenceDataList)
        if(this.props.ReferenceDataList && this.props.ReferenceDataList.length < 1)
        this.state.filterMsg = "No log records found"
        else
        this.state.filterMsg = "Please select filters to view the required records"
        return (
          <ReferenceDataList
            ReferenceDataList={this.props.ReferenceDataList}
            filterMsg = {this.state.filterMsg}
            deleteHandler={this.props.deleteHandler} selectedTable={this.props.selectedTable}
            getReferenceDataList = {this.getRefData}
          />
        );
      case ReferenceDataListStatus.ReferenceDataList_Get.FAILED:
        //  alert(JSON.stringify( this.props.ReferenceDataList))
        return (
          <Fragment>
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              //  TransitionComponent={<Slide {...props} direction="up" />}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={
                <span id="message-id">Unable to get Records</span>
              }
            />
            <ReferenceDataList
              ReferenceDataList={this.props.ReferenceDataList}
              deleteHandler={this.props.deleteHandler} selectedTable={this.props.selectedTable}
              getReferenceDataList = {this.getRefData}
            />
          </Fragment>
        );

      case ReferenceDataListStatus.ReferenceDataList_Get.LOADING:
        this.state.open = true;
        return (
          // <div style={{ textAlign: "center",display:"flex", height:"65vh", width:"100%", justifyContent:"center",alignItems:"center" }}>
          //   <Loading/>
          // </div>

<ReferenceDataList />
        );
      default:
        return <div />;
    }
  }
  render() {
    // alert(this.props.history)
    return this.getScreen(this.props.ReferenceDataList_Get_status);
  }
}

export default withRouter(ReferenceDataList_View);
