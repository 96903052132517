import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
//import { Link } from "react-router-dom";
import "./ExchangeRatesPointsToCoupon.css";
// import SimpleInput from "../Inputs/SimpleInput";
import DatePicker from "../Inputs/DatePicker";
import dateFormat from "dateformat";
import InputDollar from "../Inputs/InputDollar/InputDollar";
import { Modal } from "../Cards/UploadBannerCard/DeleteModal";
import DeleteExchangeRatesContainer from "../ExchangeRatesDollarToPoints/DeleteExchangeRates/DeleteExchangeRatesContainer";
class ExchangeRatesPointsToCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toCouponFaceValue: "",
      toCouponFaceValueErrorMsg: "Only numeric values are allowed",
      toCouponFaceValueFlag: false,
      fromPoint: "",
      fromPointErrorMsg: "Only numeric values are allowed",
      fromPointFlag: false,
      fromDate: "",
      fromDateErrorMsg: "Field Required",
      fromDateFlag: false,
      toDate: "",
      toDateErrorMsg: "Field Required",
      toDateFlag: false,
      open: true,
      fromDateReadonly: false,
      deleteDate: false,
      ToDateRange:"",
      MintoDate: new Date()
    };
  }
  UNSAFE_componentWillMount() {
    if (this.props.ExchangeRatesPointsToCoupon) {
      this.state.toCouponFaceValue = this.props.ExchangeRatesPointsToCoupon[0].to_quantity;
      this.state.fromPoint = this.props.ExchangeRatesPointsToCoupon[0].from_quantity;

      this.state.fromDate = new Date(
        this.props.ExchangeRatesPointsToCoupon[0].from_date
      );
      if (new Date().setHours(0, 0, 0, 0) <= this.state.fromDate)
        this.state.MintoDate = new Date(
          this.props.ExchangeRatesPointsToCoupon[0].from_date
        ).setDate(this.state.fromDate.getDate() + 1);
      else this.state.MintoDate = new Date().setDate(new Date().getDate() + 1);

      //}
      this.state.ToDateRange= this.props.ExchangeRatesPointsToCoupon[0].to_date
      this.state.fromDateReadonly = true;
          if( this.props.ExchangeRatesPointsToCoupon[0].to_date)
     this.state.toDate = new Date(this.props.ExchangeRatesPointsToCoupon[0].to_date);
   

    } else if (this.props.FromDate) {
      this.state.fromDate = new Date(this.props.FromDate);
      this.state.fromDateReadonly = true;
      if (this.state.fromDate > new Date()) {
        this.state.MintoDate = new Date(this.props.FromDate).setDate(
          this.state.fromDate.getDate() + 1
        );
        // this.state.MintoDate.setDate(this.state.fromDate.getDate() +1)
      }
    }
    
  }
  hideModal = () => {
    this.setState({
      deleteDate: false,
    });
  };

  showModal = () => {
    // alert("test")
    if(this.state.toDate && this.state.toDate !="")
    this.setState({
      deleteDate: true,
    });
  };
  closeSnackBar = () => {
    this.setState({
      open: false
    });
  };
  // todo: To (Coupon Face Value) onChange Function ...................

  toCouponFaceValueHandler = e => {
    const reg = /^\d*\.?\d*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ toCouponFaceValueFlag: false });
    } else {
      this.setState({
        toCouponFaceValueFlag: true,
        toCouponFaceValueErrorMsg: "Only numeric values are allowed"
      });
    }

    const regex = /^[0]+\.?[0]*$/;

    const RegexValue = regex.test(e.target.value);
     if(RegexValue){
       this.setState({
        toCouponFaceValueFlag: true,
        toCouponFaceValueErrorMsg: "Zero value not allow"
       });
     }

    this.setState({ toCouponFaceValue: e.target.value });

  };

  // todo: From (Points) onChange Function ...................

  fromPointHandler = e => {
    const reg = /^\d*\.?\d*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ fromPointFlag: false });
    } else {
      this.setState({
        fromPointFlag: true,
        fromPointErrorMsg: "Only numeric values are allowed"
      });
    }

    const regex = /^[0]+\.?[0]*$/;

    const RegexValue = regex.test(e.target.value);
     if(RegexValue){
       this.setState({
        fromPointFlag: true,
        fromPointErrorMsg: "Zero value not allow"
       });
     }

     this.setState({ fromPoint: e.target.value });

  };

  // todo: From Date onChange Function ...................

  // fromDateHandler = e => {
  //   this.setState({ fromDate: e, fromDateFlag: false });
  // };
  fromDateHandler = e => {
    // this.setState({ fromDate: e.target.value, fromDateFlag: false });

    this.setState({
      fromDate: e,
      fromDateFlag: false,
      toDate: "",
      MintoDate: new Date(e).setDate(new Date(e).getDate() + 1)
    });
  };

  // todo: To Date onChange Function ...................

  toDateHandler = e => {
    this.setState({ toDate: e, toDateFlag: false });
  };

  // todo: Save Button onClick Function ...................

  saveHandler = () => {
    if (!this.props.ViewMode)
      if (this.state.fromPointFlag || this.state.fromPoint === "") {
        this.setState({
          fromPointErrorMsg:
            this.state.fromPointFlag && this.state.fromPoint.length > 0
              ? this.state.fromPointErrorMsg
              : "Field Required",
          fromPointFlag: true
        });
      } else if (
        this.state.toCouponFaceValueFlag ||
        this.state.toCouponFaceValue === ""
      ) {
        this.setState({
          toCouponFaceValueErrorMsg:
            this.state.toCouponFaceValueFlag &&
            this.state.toCouponFaceValue.length > 0
              ? this.state.toCouponFaceValueErrorMsg
              : "Field Required",
          toCouponFaceValueFlag: true
        });
      } else if (this.state.fromDate === "") {
        this.setState({ fromDateFlag: true });
      }
      // else if (this.state.toDate === "") {
      //   this.setState({ toDateFlag: true });
      // }
      else {
        var data = {
          // from_unit:2,
          // to_unit:3,
          from_quantity: this.state.fromPoint,
          to_quantity: this.state.toCouponFaceValue,
          from_date: dateFormat(this.state.fromDate, "yyyy-mm-dd"),
           to_date: null
          // mode_of_transportation_id:4,
          // last_updated:new Date()
        };
        if (this.state.toDate !== "") {
          data.to_date = dateFormat(this.state.toDate, "yyyy-mm-dd")
        }
        if (this.props.ExchangeRatesPointsToCoupon) {
          var flag = false
          var prevData = this.props.ExchangeRatesPointsToCoupon
          if(data.from_quantity != prevData[0].from_quantity)
          flag = true
          if(data.to_quantity != prevData[0].to_quantity)
          flag = true
          if(data.to_date != dateFormat(prevData[0].to_date, "yyyy-mm-dd"))
          flag = true
          
          data = {
            ...data,
            exchange_rates_id: prevData[0]
              .exchange_rates_id
          };
          var ids = [
            prevData[0].exchange_rates_id
          ];
          var prevData =this.props.ExchangeRatesPointsToCoupon[0]
          var flag = false
            if(prevData.from_quantity != data.from_quantity)
            flag = true
            if(prevData.to_quantity != data.to_quantity)
            flag = true
            if(prevData.to_date != data.to_date)
            flag = true
          if(flag)
          this.props.saveExchangeRatesPointsToCoupon({
            data: data,
            ids: ids,
            prevData: prevData
          });
        } else this.props.saveExchangeRatesPointsToCoupon(data);
        return true;
      }
  };
  // todo: homeHandler onClick function ..........................

  homeHandler = () => {
    this.props.history.push({
      pathname: "/Admin"
    });
  };
  deleteHandler = () => {
    alert("deleteHandler Works");
  };

  toDateDeleteHandler = () => {
    this.setState({ toDate: "",deleteDate:false });
  };
  // todo: Render ..........................

  render() {
    const date = new Date();
    // const year = date.getFullYear();
    // const month = date.getMonth() + 1;
    // const day = date.getDate();
    // const fromDateMinDate = `${year}-${month}-${day}`;
    // const toDateMinDate = `${year}-${month}-${day + 1}`;
   // alert(JSON.stringify(this.props.DateRanges))
    const {
      toCouponFaceValue,
      fromPoint,
      fromDate,
      toDate,
      toCouponFaceValueErrorMsg,
      toCouponFaceValueFlag,
      fromPointErrorMsg,
      fromPointFlag,
      fromDateErrorMsg,
      fromDateFlag,
      toDateErrorMsg,
      toDateFlag
    } = this.state;

    // const newYear = fromDate.substr(0, 4);
    // const newMonth = fromDate.substring(5, 7);
    // const newDay = parseInt(fromDate.substring(8, 10));
    // const toDateMinDate = `${newYear}-${newMonth}-${newDay + 1}`;
    //alert(this.props.DateRanges.length)
    return (
      <Fragment>
        {/* {this.props.location.state?
        <Snackbar
      open={this.state.open}
      autoHideDuration={3000}
      onClose={this.closeSnackBar}
    //  TransitionComponent={<Slide {...props} direction="up" />}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">New Exchange Rates Changes Successfullly</span>}
    />:null

  }  */}
        <div className="erdp_content_container">
          {/* <ExchangeRatesDateRanges  listData={datacoupon} />
           */}
        </div>
        <div className="erpc_content_container">
          <table>
            <thead>
              <tr>
                <th>From (Points):</th>
                <th></th>
                <th>To (Offer Value):</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <InputDollar
                    labelIcon="star_border"
                    inputValue={fromPoint}
                    name="fromPoint"
                    maxLength="10"
                    onChange={this.fromPointHandler}
                    fromPointHandler
                    errorMsg={fromPointErrorMsg}
                    flag={fromPointFlag}
                    disabled={this.props.ViewMode || this.props.EditActiveDate}
                    id= {this.props.EditActiveDate ? "fromPointEdit": "fromPoint"}
                  />
                </td>
                <td></td>
                <td>
                  <InputDollar
                    classN="input-end-point"
                    classE="input-error-start"
                    inputValue={toCouponFaceValue}
                    maxLength="10"
                    name="toCouponFaceValue"
                    onChange={this.toCouponFaceValueHandler}
                    errorMsg={toCouponFaceValueErrorMsg}
                    flag={toCouponFaceValueFlag}
                    disabled={this.props.ViewMode || this.props.EditActiveDate}
                    id = {this.props.EditActiveDate ? "toCouponFaceValueEdit": "toCouponFaceValue"}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>Start Date:</td>
                <td>
                  <DatePicker
                    // className="dapi_input1"
                    id={this.props.EditActiveDate ? "startDateEdit": "startDate"}
                    inputValue={fromDate}
                    minDate={this.props.minFromDate}
                    disabled={
                      this.props.ViewMode || this.state.fromDateReadonly
                    }
                    errorMsg={fromDateErrorMsg}
                    flag={fromDateFlag}
                    onChange={this.fromDateHandler}
                  />
                </td>
                {/* <td><DatePicker minDate={`${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()+1}`} /></td> */}
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>End Date:</td>
                <td>
                  {fromDate === "" ? (
                    <DatePicker  id={this.props.EditActiveDate ? "endDateEdit": "endDate"} disabled={true} />
                  ) : (
                    <DatePicker
                      id={this.props.EditActiveDate ? "endDateEdit": "endDate"}
                      // className="dapi_input1"
                      inputValue={toDate}
                      minDate={this.state.MintoDate}
                      disabled={
                        this.props.ViewMode || this.props.toDateReadOnly
                      }
                      errorMsg={toDateErrorMsg}
                      flag={toDateFlag}
                      // isClearable={true}
                      onChange={this.toDateHandler}
                    />
                  )}
                </td>
                <td>
                  {!this.props.ViewMode && this.props.DateRanges.length>0 && this.props.ExchangeRatesPointsToCoupon && this.props.ExchangeRatesPointsToCoupon.length>0 &&
                this.props.DateRanges[this.props.DateRanges.length -1].to_date == this.props.ExchangeRatesPointsToCoupon[0].to_date? (
                  <i
                    id="deleteLink"
                    className="material-icons erpc-del-icon"
                    onClick={this.showModal}
                  >
                    delete
                  </i>
                ) :!this.props.ExchangeRatesPointsToCoupon?
                <i
                    id="deleteLink"
                    className="material-icons erpc-del-icon"
                    onClick={this.showModal}
                  >
                    delete
                  </i>:
                 null}
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr className="erpc_btn1-box12">
                <td></td>
                  <td className="erpc_btn1-box121">
                    {/* {toCouponFaceValue === "" ||
                  toCouponFaceValueFlag ||
                  fromPoint === "" ||
                  fromPointFlag ||
                  fromDate === "" ||
                  fromDateFlag ||
                  toDate === "" ||
                  toDateFlag ? (
                    <button className="erpc_btn1" onClick={this.saveHandler}>
                      Save
                    </button>
                  ) : (
                    <Link className="erpc_link" to="/Admin">
                      <button className="erpc_btn1" onClick={this.saveHandler}>
                        Save
                      </button>
                    </Link>
                  )} */}
                    {/* <button className="erpc_btn2 erpc_delete" onClick={this.deleteHandler}>
                        Delete
                  </button> */}
                    {/* <button className="erpc_btn2" onClick={this.saveHandler}>
                    Save
                  </button> */}
                  </td>
                  <td className="button-right">
                  {this.props.DeleteMode ? (
                    <DeleteExchangeRatesContainer
                      className="erpc_btn2 erpc_delete"
                      ExchangeRatesDollarToPoints={
                        this.props.ExchangeRatesPointsToCoupon
                      }
                      ToDateRange = {this.state.ToDateRange}
                      fromDate = {this.state.fromDate}  
                      setRecentlyDeleted={this.props.setRecentlyDeleted}
                      resetReducerState={this.props.resetReducerState}
                    />
                  ) : // <button className="erdp_btn1" onClick={this.deleteHandler}>
                  //   Delete
                  // </button>
                  null}

                  {  this.props.DateRanges.length>0 && this.props.DateRanges[this.props.DateRanges.length -1].to_date || this.props.ExchangeRatesPointsToCoupon? (
                    !this.props.ViewMode?
                    <button id="saveBtn" className="erpc_btn2" onClick={this.saveHandler}>
                      Save
                    </button>:null
                  ) : this.props.DateRanges.length == 0 ?
                  <button id="saveBtn" className="erpc_btn2" onClick={this.saveHandler}>
                      Save
                    </button>
                    :null
                }
                </td>
              </tr>
            </tbody>
          </table>
          {this.state.deleteDate ? (
          <Modal
          message={ "Are you sure you want to delete end date: " +
          dateFormat(new Date(this.state.toDate), "mm/dd/yyyy")+" ?"}
            onClose={this.hideModal}
            onConfirm={this.toDateDeleteHandler}
          />
        ) : null}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(ExchangeRatesPointsToCoupon);
