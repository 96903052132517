import React, { Fragment, useState } from "react";
import "./DatePicker.css";
import DatePicker from "react-datepicker";
function DatePicker1(props) {
  const {
    minDate,
    maxDate,
    inputValue,
    onChange,
    errorMsg,
    flag,
    onClick,
    disabled = false,
    readOnly = false,
    className = "",
    isClearable = false,
    boxClass="box-date-picker",
    placeholderText ="mm/dd/yyyy",
    id="datePicker"
  } = props;
  
  class DatepickerInput extends React.PureComponent {
    render() {
      return (
        <input type="text" {...this.props} readOnly />
      )
    }
  }

  // const DatepickerInput = ({ ...props }) => (
  //   <input type="text" {...props} readOnly />
  // );
  // console.log('minDate:',props.minDate, 'inputValue:', inputValue)
  const [dateFoucus, setDateFocus] = useState(false);
  const dateFocusHandler = () => {
    setDateFocus(true);
  };

  return (
    <Fragment>
      <div onClick = {onClick} className={boxClass}>
        <DatePicker
          id={id}
          className={`dapi_input box-input-date ${className}`}
          minDate={minDate}
          maxDate={maxDate}
          selected={inputValue}
          onChange={onChange}
          placeholderText={placeholderText}
          withPortal
          readOnly={readOnly}
          disabled={disabled}
          customInput={<DatepickerInput />}
          // filterDate={this.isWeekday}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          fixedHeight
          autoFocus={dateFoucus}
          onBlur={() => setDateFocus(false)}
          
          isClearable={isClearable}
          disabledKeyboardNavigation={true}
        />
        <span
          className="material-icons border-rd-right"
          onClick={dateFocusHandler}
        >
          date_range
        </span>
      </div>
      {/* <input
        className="dapi_input"
        type="date"
        value={inputValue}
        min={minDate}
        max={maxDate}
        onChange={onChange}
        disabled={disabled}
      /> */}
      <p
        className="dapi_error_text"
        style={{ display: flag ? "block" : "none" }}
      >
        {errorMsg}
      </p>
    </Fragment>
  );
}

export default DatePicker1;
