import React, { Component, Fragment } from 'react'
//import './ExchangeRatesPointsToCoupon.css'
import { ExchangeRatesPointsToCouponStatus } from './ExchangeRatesPointsToCouponconstants';
import ExchangeRatesPointsToCoupon from "./ExchangeRatesPointsToCouponForm"
import { withRouter } from 'react-router-dom';
//import Snackbar from '@material-ui/core/Snackbar/';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
// import Loading from '../Utils/Loading';
// import RotateLoading from '../Utils/RotateLoading';

export class ExchangeRatesPointsToCouponView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mount: false,
    }

  }
  closeSnackBar = () => {
    this.setState({
      open: false
    })
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.ExchangeRatesPointsToCoupon_post_status === ExchangeRatesPointsToCouponStatus.ExchangeRatesPointsToCoupon_Post
      .SUCCESS){
        toast.success(nextProps.ExchangeRatesPointsToCoupon_Message)
      nextProps.setRecentlySaved()
      nextProps.resetReducerState()
   }
    return null;
 }

  getScreen(status) {
    // alert(status)
    switch (status) {

      case ExchangeRatesPointsToCouponStatus.ExchangeRatesPointsToCoupon_Post.NEW:
        //  alert(JSON.stringify( this.props.ExchangeRatesPointsToCoupon))
        return (
          <ExchangeRatesPointsToCoupon saveExchangeRatesPointsToCoupon={this.props.saveExchangeRatesPointsToCoupon}
            ViewMode={this.props.ViewMode}
            minFromDate={this.props.minFromDate}
            FromDate={this.props.FromDate}
            toDateReadOnly={this.props.toDateReadOnly}
            EditActiveDate={this.props.EditActiveDate}
            setRecentlyDeleted={this.props.setRecentlySaved}
            DeleteMode={this.props.DeleteMode}
            resetReducerState={this.props.resetReducerState}
            DateRanges = {this.props.DateRanges}
          />

        );

      case ExchangeRatesPointsToCouponStatus.ExchangeRatesPointsToCoupon_Get.SUCCESS:
        return (
          <ExchangeRatesPointsToCoupon
            saveExchangeRatesPointsToCoupon={this.props.updateExchangeRatesPointsToCoupon}
            ExchangeRatesPointsToCoupon={this.props.ExchangeRatesPointsToCoupon}
            ViewMode={this.props.ViewMode}
            minFromDate={this.props.minFromDate}
            FromDate={this.props.FromDate}
            toDateReadOnly={this.props.toDateReadOnly}
            EditActiveDate={this.props.EditActiveDate}
            setRecentlyDeleted={this.props.setRecentlySaved}
            DeleteMode={this.props.DeleteMode}
            resetReducerState={this.props.resetReducerState}
            DateRanges = {this.props.DateRanges}
          />
        );
      case ExchangeRatesPointsToCouponStatus.ExchangeRatesPointsToCoupon_Get.FAILED:
        //  alert(JSON.stringify( this.props.ExchangeRatesPointsToCoupon))
        toast.error(this.props.ExchangeRatesPointsToCoupon_Message)
        return (
          <Fragment>
            
            <ExchangeRatesPointsToCoupon
              ViewMode={this.props.ViewMode}
              minFromDate={this.props.minFromDate}
              saveExchangeRatesPointsToCoupon={this.props.saveExchangeRatesPointsToCoupon}
              FromDate={this.props.FromDate}
              toDateReadOnly={this.props.toDateReadOnly}
              EditActiveDate={this.props.EditActiveDate}
              DeleteMode={this.props.DeleteMode}
              DateRanges = {this.props.DateRanges}
            />

          </Fragment>
        );

      case ExchangeRatesPointsToCouponStatus.ExchangeRatesPointsToCoupon_Post.SUCCESS:
        // alert(this.props.history)
        return (
          <Fragment >
            <ExchangeRatesPointsToCoupon
              saveExchangeRatesPointsToCoupon={this.props.updateExchangeRatesPointsToCoupon}
              ExchangeRatesPointsToCoupon={this.props.ExchangeRatesPointsToCoupon}
              ViewMode={this.props.ViewMode}
              minFromDate={this.props.minFromDate}
              FromDate={this.props.FromDate}
              toDateReadOnly={this.props.toDateReadOnly}
              setRecentlySaved={this.props.setRecentlySaved}
              EditActiveDate={this.props.EditActiveDate}
              setRecentlyDeleted={this.props.setRecentlySaved}
              DeleteMode={this.props.DeleteMode}
              DateRanges = {this.props.DateRanges}
            />
          </Fragment>
        );
        break;

      case ExchangeRatesPointsToCouponStatus.ExchangeRatesPointsToCoupon_Post.FAILED:
        //   alert('fail')
        toast.error(this.props.ExchangeRatesPointsToCoupon_Message)
        return (
          <Fragment>
            
            <ExchangeRatesPointsToCoupon
              ViewMode={this.props.ViewMode}
              minFromDate={this.props.minFromDate}
              saveExchangeRatesPointsToCoupon={this.props.saveExchangeRatesPointsToCoupon}
              FromDate={this.props.FromDate}
              toDateReadOnly={this.props.toDateReadOnly}
              EditActiveDate={this.props.EditActiveDate}
              DeleteMode={this.props.DeleteMode}
              DateRanges = {this.props.DateRanges}
            />
          </Fragment>
        );
        break;
      case ExchangeRatesPointsToCouponStatus.ExchangeRatesPointsToCoupon_Post.LOADING:
        this.state.open = true


        return (
          <>
            {/* <div style={{ textAlign: "center",display:"flex", height:"45vh", width:"100%", justifyContent:"center",alignItems:"center" }}>
              <Loading/>
            </div> */}
          <div style={{opacity:"0.3", pointerEvents:"none"}}  >
          <ExchangeRatesPointsToCoupon saveExchangeRatesPointsToCoupon={this.props.saveExchangeRatesPointsToCoupon}
          ViewMode={this.props.ViewMode}
          minFromDate={this.props.minFromDate}
          FromDate={this.props.FromDate}
          toDateReadOnly={this.props.toDateReadOnly}
          EditActiveDate={this.props.EditActiveDate}
          setRecentlyDeleted={this.props.setRecentlySaved}
          DeleteMode={this.props.DeleteMode}
          resetReducerState={this.props.resetReducerState}
          DateRanges = {this.props.DateRanges}
          />
          {/* <RotateLoading LoadingIcon={true} /> */}
          </div>
          </>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
    // alert(this.props.history)
    return (
      this.getScreen(this.props.ExchangeRatesPointsToCoupon_post_status)
    )
  }
}

export default withRouter(ExchangeRatesPointsToCouponView)
