import { ExchangeRatesPointsToCouponStatus, ExchangeRatesPointsToCouponActions } from './ExchangeRatesPointsToCouponconstants';

const ExchangeRatesPointsToCoupon_initialState = {
    ExchangeRatesPointsToCoupon_Status: ExchangeRatesPointsToCouponStatus.ExchangeRatesPointsToCoupon_Post.NEW,
    ExchangeRatesPointsToCoupon_Message: '',
    ExchangeRatesPointsToCouponDetails: []
}
export default function (state = ExchangeRatesPointsToCoupon_initialState, action) {
    switch (action.type) {
        case ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.LOADING:
           // alert('loading')
            return { ...state, ExchangeRatesPointsToCoupon_Status: ExchangeRatesPointsToCouponStatus.ExchangeRatesPointsToCoupon_Post.LOADING }
      
            case ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.NEW:
            return { ...state, ExchangeRatesPointsToCoupon_Status: ExchangeRatesPointsToCouponStatus.ExchangeRatesPointsToCoupon_Post.NEW,
                ExchangeRatesPointsToCoupon_Message: ""
            }

        case ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Get.SUCCESS:
            return { ...state, ExchangeRatesPointsToCoupon_Status: ExchangeRatesPointsToCouponStatus.ExchangeRatesPointsToCoupon_Get.SUCCESS, ExchangeRatesPointsToCouponDetails: action.payload }
        case ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Get.FAILED:
            return { ...state, ExchangeRatesPointsToCoupon_Status: ExchangeRatesPointsToCouponStatus.ExchangeRatesPointsToCoupon_Get.FAILED, ExchangeRatesPointsToCoupon_Message:action.error}

        case ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.FAILED:
           // alert('test')
            return { ...state, ExchangeRatesPointsToCoupon_Status: ExchangeRatesPointsToCouponStatus.ExchangeRatesPointsToCoupon_Post.FAILED, ExchangeRatesPointsToCoupon_Message:action.error }
        case ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.SUCCESS:
            //alert(JSON.stringify(action.payload))
            //  state.ExchangeRatesPointsToCouponDetails.push({})
            return { ...state, ExchangeRatesPointsToCoupon_Status: ExchangeRatesPointsToCouponStatus.ExchangeRatesPointsToCoupon_Post.SUCCESS, ExchangeRatesPointsToCouponDetails: [action.payload], ExchangeRatesPointsToCoupon_Message:action.message }

        default:
            return { ...state,
 }
    }
}
