export const ExchangeRate_Actions= {
	
	
	ExchangeRate_Get :{
		NEW:"ExchangeRate_Get_NEW",
		SUCCESS:"ExchangeRate_Get_SUCCESS",
		FAILED:"ExchangeRate_Get_FALIURE",
		LOADING:"ExchangeRate_Get_LOADING",
		NOT_AUTHORIZED: "ExchangeRate_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
	,
	ExchangeRate_Post :{
		NEW:"ExchangeRate_Post_NEW",
		SUCCESS:"ExchangeRate_Post_SUCCESS",
		FAILED:"ExchangeRate_Post_FALIURE",
		LOADING:"ExchangeRate_Post_LOADING",
		NOT_AUTHORIZED: "ExchangeRate_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const ExchangeRate_Status ={

	ExchangeRate_Get :{
		NEW:"ExchangeRate_Get_NEW",
		SUCCESS:"ExchangeRate_Get_SUCCESS",
		FAILED:"ExchangeRate_Get_FALIURE",
		LOADING:"ExchangeRate_Get_LOADING",
		NOT_AUTHORIZED: "ExchangeRate_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	ExchangeRate_Post :{
		NEW:"ExchangeRate_Post_NEW",
		SUCCESS:"ExchangeRate_Post_SUCCESS",
		FAILED:"ExchangeRate_Post_FALIURE",
		LOADING:"ExchangeRate_Post_LOADING",
		NOT_AUTHORIZED: "ExchangeRate_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
