import React, { Component, Fragment } from 'react'
//import './DeleteExchangeRates.css'
import { DeleteExchangeRatesStatus } from './DeleteExchangeRatesConstants';
import DeleteExchangeRates from "./DeleteExchangeRates"
import { withRouter } from 'react-router-dom';
//import Fragment from "../ConfirmationContract/ConfirmationContract"
//import Snackbar from '@material-ui/core/Snackbar/';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from '../../Utils/Loading';

export class DeleteExchangeRatesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    }
 // props.resetReducerState()
  }
  closeSnackBar = () => {
    // this.setState({
    //   open: false
    // })
    this.props.resetReducerState()
    this.props.setRecentlyDeleted()
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.DeleteExchangeRates_Status === DeleteExchangeRatesStatus.DeleteExchangeRates_DELETE.SUCCESS){
      toast.success(nextProps.DeleteExchangeRates_Message)
      nextProps.resetReducerState()
      nextProps.setRecentlyDeleted()
   }
    return null;
 }

  getScreen(status) {
    switch (status) {
      case DeleteExchangeRatesStatus.DeleteExchangeRates_DELETE.NEW:
       // setRecentlyDeleted={this.props.setRecentlyDeleted}
               
        return (
          <Fragment >
            <DeleteExchangeRates 
              className={this.props.className}
              ExchangeRatesDollarToPoints={this.props.ExchangeRatesDollarToPoints}
              handleDeleteExchangeRates={this.props.handleDeleteExchangeRates}
              ToDateRange = {this.props.ToDateRange}
              fromDate = {this.props.fromDate}

            />
          </Fragment>
        );


      case DeleteExchangeRatesStatus.DeleteExchangeRates_DELETE.SUCCESS:
        // alert(JSON.stringify(this.props.DeleteExchangeRates))
      //  alert(this.props.setRecentlySaved)

       return (
          <Fragment >
            <DeleteExchangeRates 
              className={this.props.className}
              ExchangeRatesDollarToPoints={this.props.ExchangeRatesDollarToPoints}
              handleDeleteExchangeRates={this.props.handleDeleteExchangeRates}
              ToDateRange = {this.props.ToDateRange}
              fromDate = {this.props.fromDate}

            />
          </Fragment>
        );
        break;
      case DeleteExchangeRatesStatus.DeleteExchangeRates_DELETE.FAILED:
        toast.error(this.props.DeleteExchangeRates_Message)
        return (
          <Fragment>
            
            <DeleteExchangeRates 
              className={this.props.className}
              ExchangeRatesDollarToPoints={this.props.ExchangeRatesDollarToPoints}
              handleDeleteExchangeRates={this.props.handleDeleteExchangeRates}
              ToDateRange = {this.props.ToDateRange}
              fromDate = {this.props.fromDate}

            />

          </Fragment>
        );

      case DeleteExchangeRatesStatus.DeleteExchangeRates_DELETE.LOADING:
        this.state.open = true
        return (
          <div style={{ textAlign: "center",display:"flex", height:"65vh", width:"100%", justifyContent:"center",alignItems:"center" }}>
            <Loading/>
          </div>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
    // alert(this.props.DeleteExchangeRates_Status)
    return (
      this.getScreen(this.props.DeleteExchangeRates_Status)
    )
  }
}

export default withRouter(DeleteExchangeRatesView)
