import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../UserManagement/UserManagement.css";
import CustomLabelDropDownInput from "../Inputs/CustomLabelDropDownInput/CustomLabelDropDownInput";
// import SelectSearch from '../Inputs/SelectSearch/SelectSearchInput';
import SimpleInput from "../Inputs/SimpleInput";
import UsersInventoryContainer from "./UsersInventory/UsersInventoryContainer";
import { Api_Pagination } from "RefRecordsConstants";
const Data = [
  { value: "user_name", name: "username" },
  { value: "email_address", name: "email" },
  { value: "role_name", name: "role" },
];

class UserManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchBy: '{ "value": "user_name", "name": "username" }',
      searchText: "",
      usersList: [],
      NoOfPages: 0,
      activePage: 1,
      isLoading: false,
      // sortBy:'application_user_id',
      // sortType:'desc',
      filter: {
        searchBy: "user_name",
        searchText: "",
        sortBy:'application_user_id',
        sortType:'asc',
      },
      sortEnabled: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleSearchDropDown = (e) => {

    this.setState({
      searchBy: e.target.value,
    });
  };
  handleSearch = (e) => {
    // alert(this.state.searchText)
    this.setState({
      searchText: e.target.value,
    });
  };
  applySortByBusinessName = ()=>{
    let sortType = this.state.filter.sortType==='desc'?'asc':'desc'
    this.setState({
      filter: {
        searchBy: JSON.parse(this.state.searchBy).value,
        searchText: this.state.searchText,
        sortBy:"business_name",
        sortType:sortType
      },
      activePage: 1,
      sortEnabled: true
    });
  }
  applySearch = () => {
    // alert(JSON.stringify(this.state.filter))
    let sortType = this.state.filter.sortType
    let sortBy = this.state.filter.sortBy
    this.setState({
      filter: {
        searchBy: JSON.parse(this.state.searchBy).value,
        searchText: this.state.searchText,
        sortType:sortType,
        sortBy:sortBy
      },
      activePage: 1,
    });
  };

  setLoading = (loading) => {
    //  alert(loading)
    this.state.isLoading = loading;
  };

  setItemList = (ListLength) => {
    if (ListLength - 1 > 0)
      this.setState({
        NoOfPages:
          Math.trunc(
            (ListLength - 1) / Api_Pagination.NotificationHistoryLimit
          ) + 1,
      });
      else{
        this.setState({
          NoOfPages:1
        })
      }
  };

  ChangePage = (page) => {
    // alert(this.state.isLoading)
    console.log("Loading page:", this.state.isLoading);
    if (this.state.activePage != page && !this.state.isLoading) {
      this.setState({
        activePage: page,
      });
      //   this.setLoading(true)
    }
  };
  handleNext = () => {
    //   alert(this.state.isLoading)
    if (this.state.activePage < this.state.NoOfPages && !this.state.isLoading) {
      this.setState({
        activePage: this.state.activePage + 1,
      });
      // this.setLoading(true)
    }
  };
  handlePrev = () => {
    // alert(this.state.isLoading)
    if (this.state.activePage > 1 && !this.state.isLoading) {
      this.setState({
        activePage: this.state.activePage - 1,
      });
      // this.setLoading(true)
    }
  };

  render() {
    const items = [];
    items.push(
      <Link
        to="#"
        onClick={() => this.ChangePage(1)}
        className={this.state.activePage == 1 ? "active-page" : null}
      >
        {" "}
        1{" "}
      </Link>
    );
    let i = 2;
    let offset = 6;
    if (this.state.activePage > 3 && this.state.NoOfPages > 5) {
      offset = this.state.activePage + 3;
      i = this.state.activePage - 1;
      items.push(<Link to="#">{"..."}</Link>);
    }
    if (this.state.activePage + 3 > this.state.NoOfPages && i > 2)
      i = this.state.NoOfPages - 3;
    for (
      let index = 0 + i;
      index < this.state.NoOfPages && index < offset - 1;
      index++
    ) {
      items.push(
        <Link
          to="#"
          key={index}
          onClick={() => this.ChangePage(index)}
          className={i == this.state.activePage ? "active-page" : null}
        >
          {" "}
          {i}{" "}
        </Link>
      );
      i++;
    }
    if (this.state.NoOfPages > this.state.activePage + 2)
      if (this.state.NoOfPages > 5) items.push(<Link to="#">{"..."}</Link>);
    items.push(
      <Link
        to="#"
        onClick={() => this.ChangePage(this.state.NoOfPages)}
        className={
          this.state.activePage == this.state.NoOfPages ? "active-page" : null
        }
      >
        {this.state.NoOfPages}{" "}
      </Link>
    );

    return (
      <div className="usr-m-box">
        <div className="usr-m-conatiner">
          <div className="user-m-heading">
            <h5>User Management</h5>
          </div>
          <div className="usr-m-drowp-box">
            <div className="usr-search">
              <div className="label-searc">Search Users</div>
              <SimpleInput
                label="search"
                id="searchInput"
                onChange={this.handleSearch}
                inputValue={this.state.searchText}
                placeholder="Search"
                maxLength="300"
                containerStyle={{ height: 38, width: "90%" }}
              />
            </div>
            <div className="usr-search-2">
              <CustomLabelDropDownInput
                id="searchBy"
                label="Search By"
                name="name"
                value=""
                firstName="Select"
                inputValue={this.state.searchBy}
                hideFirst={false}
                onChange={this.handleSearchDropDown}
                data={Data}
              />
            </div>
            <div className="search-btn">
              <button id="searchBtn" onClick={this.applySearch} className="filter-btn">
                Search
              </button>
            </div>
            <div className="cont1 add-user b">
              <Link id="addUserLink" to="/AddNewUser" >
                Add User
              </Link>
            </div>
          </div>
          <div class="fiter-search-box">
            <div className="pricing-table">
              <UsersInventoryContainer
              applySortByBusinessName={this.applySortByBusinessName}
                cookies={this.props.cookies}
                filter={this.state.filter}
                activePage={this.state.activePage}
                setItemList={this.setItemList}
                setLoading={this.setLoading}
                sortBy={this.state.sortBy}
                sortType={this.state.filter.sortType}
                sortEnabled={this.state.sortEnabled}
              />
            </div>
          </div>
        </div>

        {this.state.NoOfPages > 1 ? (
          <div className="pagination-box marg-usr">
            <div className="pagination-inner-box">
              <div className="box-pagination">
                <div className="next-btn">
                  <div className="cont1">
                    <Link id="previousLink" to="#" onClick={this.handlePrev}>
                      prev
                    </Link>
                  </div>
                  <div style={{ minWidth: 300 }} className="count-btn">
                    {items}
                  </div>
                  <div className="cont1">
                    <Link id="nextLink" to="#" onClick={this.handleNext}>
                      next
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
    
      </div>
    );
  }
}

export default UserManagement;
