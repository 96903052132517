import {
    FilterFieldStatus,
    FilterFieldActions
  } from "./FilterFieldConstants";
  
  const FilterField_initialState = {
    FilterField_Status: FilterFieldStatus.FilterField_Get.NEW,
    FilterField_Error: "",
    FilterFieldDetails: []
  };
  export default function(state = FilterField_initialState, action) {
    switch (action.type) {
      case FilterFieldActions.FilterField_Get.LOADING:
        return {
          ...state,
          FilterField_Status:
            FilterFieldStatus.FilterField_Get.LOADING
        };
      case FilterFieldActions.FilterField_Get.NEW:
        return {
          ...state,
          FilterField_Status:
            FilterFieldStatus.FilterField_Get.NEW,
          FilterFieldDetails: []
        };
      case FilterFieldActions.FilterField_Get.SUCCESS:
        return {
          ...state,
          FilterField_Status:
            FilterFieldStatus.FilterField_Get.SUCCESS,
          FilterFieldDetails: action.payload
        };
      case FilterFieldActions.FilterField_Get.FAILED:
        return {
          ...state,
          FilterField_Status:
            FilterFieldStatus.FilterField_Get.FAILED
        };
  
      default:
        return {
          ...state,
          FilterField_Error: "",
          FilterField_Status: FilterFieldStatus.FilterField_Get.NEW
        };
    }
  }
  