import React, { Component, Fragment } from "react";
import {Modal} from "../../Cards/UploadBannerCard/DeleteModal"
class DeleteReferenceData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModel:false
        };
    }
    handleShowMessageClick = () => this.setState({showModal: true})
    handleCloseModal = () => this.setState({showModal: false})

    render() {
        const {
            data = {},
            selectedTable="",
        } = this.props
        return (
            <Fragment>
            <a id="deleteLink" onClick={this.handleShowMessageClick} style={{cursor:'pointer'}} className="table-link">Delete</a>
            {this.state.showModal ? (
            <Modal onClose={this.handleCloseModal}
            onConfirm = {()=>{
                this.props.delete_Handler();
                this.handleCloseModal()
            }}
            message = {"Are you sure you want to delete id: "+data[Object.keys(data)[0]]+" of table: "+selectedTable+"?"}
            // banner = {this.props.banner}
            >
            </Modal>
          ) : null}
            </Fragment>
        );
    }
}

export default DeleteReferenceData;
