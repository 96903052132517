import React, { Component, Fragment, PureComponent } from 'react'
//import './RetailerList.css'
import { RetailerListStatus } from './RetailerListConstants';

import Loading from '../Utils/Loading';

export class RetailerListView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    }
    if(props.getRetailerBusinessOnly)
    props.getRetailers()
    else
    props.getRetailerList()
  }
  // UNSAFE_componentWillMount() {
  //   this.props.resetReducerState()
  //   this.props.getRetailerList()
  // }
  closeSnackBar = () => {
    this.setState({
      open: false
    })

  }
  closePostSnackBar = () => {
    this.setState({
      open: false
    })
    this.props.getRetailerList()
  }
  getScreen(status) {
    switch (status) {
      case RetailerListStatus.RetailerList_GET.NEW:
        return (
          <Fragment >
            {/* <div style={{ position: "absolute", display: "flex", alignItems: "center", width: "20%", justifyContent: "center", paddingTop: 10}}>
             <Loading/>
           </div> */}
          </Fragment>
        );


      case RetailerListStatus.RetailerList_GET.SUCCESS:
        const RetailerList = this.props.RetailerList
        if(this.props.setLoading)
        {
          this.props.setLoading(false)
        }
          if(this.props.showAll){
            RetailerList.unshift({name:"",value:"show_all"})
          }
          console.log("RetailerList:  ", RetailerList)
          // alert(JSON.stringify())
          this.props.setRetailerList(RetailerList)
        return (
          <Fragment >
            
          </Fragment>
        );
        break;
      case RetailerListStatus.RetailerList_GET.FAILED:
        if(this.props.setLoading)
        {
          this.props.setLoading(false)
        }
       this.props.setRetailerList([])
        return (
          <Fragment>
           <p>Unable to get list of partners</p>
          </Fragment>
        );

      case RetailerListStatus.RetailerList_GET.LOADING:
        this.state.open = true
        if(this.props.setLoading)
        {
          this.props.setLoading(true)
        }
        return (
          <Fragment >
          {/* <div style={{ position: "absolute", display: "flex", alignItems: "center", width: "20%", justifyContent: "center", paddingTop: 10}}>
           <Loading/>
         </div> */}
        </Fragment>
          // <div style={{ textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center", width:"100%" }}>
          //   <Loading/>
          // </div>
        );
        break;
      default:
        return <div />
    }

  }
  render() {

    return (
      this.getScreen(this.props.RetailerList_GET_status)
    )
  }
}

export default (RetailerListView)
