export const ExchangeRatesDollarToPointsActions= {
	
	
	ExchangeRatesDollarToPoints_Get :{
		NEW:"ExchangeRatesDollarToPoints_Get_NEW",
		SUCCESS:"ExchangeRatesDollarToPoints_Get_SUCCESS",
		FAILED:"ExchangeRatesDollarToPoints_Get_FALIURE",
		LOADING:"ExchangeRatesDollarToPoints_Get_LOADING",
		NOT_AUTHORIZED: "ExchangeRatesDollarToPoints_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	ExchangeRatesDollarToPoints_Post :{
		NEW:"ExchangeRatesDollarToPoints_Post_NEW",
		SUCCESS:"ExchangeRatesDollarToPoints_Post_SUCCESS",
		FAILED:"ExchangeRatesDollarToPoints_Post_FALIURE",
		LOADING:"ExchangeRatesDollarToPoints_Post_LOADING",
		NOT_AUTHORIZED: "ExchangeRatesDollarToPoints_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const ExchangeRatesDollarToPointsStatus ={

	ExchangeRatesDollarToPoints_Get :{
		NEW:"ExchangeRatesDollarToPoints_Get_NEW",
		SUCCESS:"ExchangeRatesDollarToPoints_Get_SUCCESS",
		FAILED:"ExchangeRatesDollarToPoints_Get_FALIURE",
		LOADING:"ExchangeRatesDollarToPoints_Get_LOADING",
		NOT_AUTHORIZED: "ExchangeRatesDollarToPoints_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	ExchangeRatesDollarToPoints_Post :{
		NEW:"ExchangeRatesDollarToPoints_Post_NEW",
		SUCCESS:"ExchangeRatesDollarToPoints_Post_SUCCESS",
		FAILED:"ExchangeRatesDollarToPoints_Post_FALIURE",
		LOADING:"ExchangeRatesDollarToPoints_Post_LOADING",
		NOT_AUTHORIZED: "ExchangeRatesDollarToPoints_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
