export const DeleteExchangeRatesActions= {
	
	DeleteExchangeRates_DELETE :{
		NEW:"DeleteExchangeRates_DELETE_NEW",
		SUCCESS:"DeleteExchangeRates_DELETE_SUCCESS",
		FAILED:"DeleteExchangeRates_DELETE_FALIURE",
		LOADING:"DeleteExchangeRates_DELETE_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const DeleteExchangeRatesStatus ={

	DeleteExchangeRates_DELETE :{
		NEW:"DeleteExchangeRates_DELETE_NEW",
		SUCCESS:"DeleteExchangeRates_DELETE_SUCCESS",
		FAILED:"DeleteExchangeRates_DELETE_FALIURE",
		LOADING:"DeleteExchangeRates_DELETE_LOADING",
	}

}
