import React, { Component, Fragment } from 'react'
import '../ReferenceDataConfiguration/ReferenceData.css'

class ReferenceTableEmptyAll extends Component {
    render() {
        return (
            <Fragment>
                {this.props.select?
            <div className="empty-reference-msge">
                <h3 className="error-ref-msge">Please select table to view the data</h3>
            </div>:
            <div className="empty-reference-msge">
                <h3 className="error-ref-msge">No Data Available
               <span>
                <div className='outer-loader'>
              <div className='inner-loader'  style={{left:"180px", bottom:"-1px"}}>
                <span className='input-group-addon'>
                  <i className='fa fa-refresh fa-spin bc'></i>
                </span>
              </div>
            </div>
                </span>
                </h3>
            </div>
        }
 
            </Fragment>
        )
    }
}

export default ReferenceTableEmptyAll
