import { connect } from "react-redux";
import ReferenceTableDropDown_View from "./ReferenceTableDropDown_View";
import { ReferenceTableDropDownServer } from "./server";
import { ReferenceTableDropDownActions } from "./ReferenceTableDropDown_Constants";
const mapStateToProps = state => {
  console.log(state);
  return {
    ReferenceTableDropDown_Get_status:
      state.ReferenceTableDropDownReducer.ReferenceTableDropDown_Status,
    error:state.ReferenceTableDropDownReducer.error,
    dataTable:
      state.ReferenceTableDropDownReducer.dataTable
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getReferenceTableDropDown: () => {
      dispatch(ReferenceTableDropDownServer.getReferenceTableDropDown());
    },
    resetReducerState:()=>{
      dispatch({type:ReferenceTableDropDownActions.ReferenceTableDropDown_Get.NEW})
    }
  };
};
const ReferenceTableDropDownContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferenceTableDropDown_View);

export default ReferenceTableDropDownContainer;
