//******************Exchange Rate Server Calls******************;

import { ReferenceTableDropDownActions } from "./ReferenceTableDropDown_Constants";
import store from "../../../Store/store";
import { ROOT_URL , getCookie} from "../../../Config/Config";

export const ReferenceTableDropDownServer = {
  getReferenceTableDropDown: getReferenceTableDropDown,
};

//******************Get ReferenceTableDropDowns******************;

function getReferenceTableDropDown(data) {
  const res = fetch(ROOT_URL + "/api/configurations/getRefTables", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ tableName: data }),
  })
    .then((res) => {
      res
        .json()
        .then((response) => {
          console.log("ReferenceTableDropDown:", response);
          store.dispatch({
            type:
              ReferenceTableDropDownActions.ReferenceTableDropDown_Get.SUCCESS,
            payload: response
          });
        })
        .catch((err) => {
          console.log("ReferenceTableDropDownError:", err);
          store.dispatch({
            type:
              ReferenceTableDropDownActions.ReferenceTableDropDown_Get.FAILED,
            payload: err,
          });
        });
    })
    .catch((err) => {
      console.log("ReferenceTableDropDownError:", err);
      store.dispatch({
        type: ReferenceTableDropDownActions.ReferenceTableDropDown_Get.FAILED,
        payload: err,
      });
    });

  return {
    type: ReferenceTableDropDownActions.ReferenceTableDropDown_Get.LOADING,
  };
}
