import { AddRefrenceDataStatus, AddRefrenceDataActions } from './AddRefrenceDataConstants';

const AddRefrenceData_initialState = {
    AddRefrenceData_Status: AddRefrenceDataStatus.RefrenceData_Add.NEW,
    AddRefrenceData_Message: '',
    AddRefrenceDataDetails: [],

}
export default  function  (state = AddRefrenceData_initialState, action) {
    switch (action.type) {
        case AddRefrenceDataActions.RefrenceData_Add.LOADING:
            return { ...state, AddRefrenceData_Status: AddRefrenceDataStatus.RefrenceData_Add.LOADING }
            case AddRefrenceDataActions.RefrenceData_Add.NEW:
                return { ...state, AddRefrenceData_Status: AddRefrenceDataStatus.RefrenceData_Add.NEW, AddRefrenceData_Message:"",RefrenceDataFields: [] }
    
        case AddRefrenceDataActions.RefrenceData_Add.FAILED:
            return { ...state,  AddRefrenceData_Status: AddRefrenceDataStatus.RefrenceData_Add.FAILED, AddRefrenceData_Message:action.error}
        case AddRefrenceDataActions.RefrenceData_Add.SUCCESS:
          
          return { ...state,  AddRefrenceData_Status: AddRefrenceDataStatus.RefrenceData_Add.SUCCESS, AddRefrenceDataDetails:action.payload, AddRefrenceData_Message:action.message}
         
        default:
            return { ...state,
            }
    }
}
