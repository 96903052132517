export const ReferenceTableDropDownActions= {
	
	
	ReferenceTableDropDown_Get :{
		NEW:"ReferenceTableDropDown_Get_NEW",
		SUCCESS:"ReferenceTableDropDown_Get_SUCCESS",
		FAILED:"ReferenceTableDropDown_Get_FALIURE",
		LOADING:"ReferenceTableDropDown_Get_LOADING",
		NOT_AUTHORIZED: "ReferenceTableDropDown_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	ReferenceTableDropDown_Post :{
		NEW:"ReferenceTableDropDown_Post_NEW",
		SUCCESS:"ReferenceTableDropDown_Post_SUCCESS",
		FAILED:"ReferenceTableDropDown_Post_FALIURE",
		LOADING:"ReferenceTableDropDown_Post_LOADING",
		NOT_AUTHORIZED: "ReferenceTableDropDown_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const ReferenceTableDropDownStatus ={

	ReferenceTableDropDown_Get :{
		NEW:"ReferenceTableDropDown_Get_NEW",
		SUCCESS:"ReferenceTableDropDown_Get_SUCCESS",
		FAILED:"ReferenceTableDropDown_Get_FALIURE",
		LOADING:"ReferenceTableDropDown_Get_LOADING",
		NOT_AUTHORIZED: "ReferenceTableDropDown_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	ReferenceTableDropDown_Post :{
		NEW:"ReferenceTableDropDown_Post_NEW",
		SUCCESS:"ReferenceTableDropDown_Post_SUCCESS",
		FAILED:"ReferenceTableDropDown_Post_FALIURE",
		LOADING:"ReferenceTableDropDown_Post_LOADING",
		NOT_AUTHORIZED: "ReferenceTableDropDown_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
