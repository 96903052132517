import { connect } from "react-redux";
import DeleteReferenceDataView from "./DeleteReferenceData_View";
import {DeleteReferenceDataServer} from "./server"
import {DeleteReferenceDataActions} from './DeleteReferenceDataConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        DeleteReferenceData_Status:state.DeleteReferenceDataReducer.DeleteReferenceData_Status,
        DeleteReferenceData:state.DeleteReferenceDataReducer.DeleteReferenceDataDetails,
        DeleteReferenceData_Message:state.DeleteReferenceDataReducer.DeleteReferenceData_Message
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        handleDeleteReferenceData: (data) => {

           dispatch( DeleteReferenceDataServer.handleDeleteReferenceData(data) )
        },
        resetReducerState:async ()=>{
            
             dispatch({type:DeleteReferenceDataActions.DeleteReferenceData_DELETE.NEW})
    }
    }
};
const DeleteReferenceDataContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteReferenceDataView)

export default DeleteReferenceDataContainer;