import {ExchangeRate_Actions, ExchangeRate_Status } from './constants';
const get_initialState = {
  exchangeRate_get_status: ExchangeRate_Status.ExchangeRate_Get.NEW,
  exchangeRates: [],
  uploadSuccess:'',
  recordSaved:""
};

//******************Get Reducer******************;

export const NJT_ExchangeRate_Get_Reducer = (state = get_initialState, action) => {
  //console.log(action.type);
  switch (action.type) {

    case ExchangeRate_Actions.ExchangeRate_Get.NEW:
     // console.log("I am from Reduce NEW..");
      return { ...state, exchangeRate_get_status: ExchangeRate_Status.ExchangeRate_Get.NEW, recordSaved: action.status };

    case ExchangeRate_Actions.ExchangeRate_Get.SUCCESS:
     console.log("I am from Reduce SUCCESS..",action.status);
     if(action.status == undefined)
     {
       console.log("here")
     return { ...state, exchangeRate_get_status: ExchangeRate_Status.ExchangeRate_Get.SUCCESS, exchangeRates: action.payload };
     } 
      else
      return { ...state, exchangeRate_get_status: ExchangeRate_Status.ExchangeRate_Get.SUCCESS, recordSaved: action.status };

      console.log(state);
    case ExchangeRate_Actions.ExchangeRate_Get.OFFLINE:
    //  console.log("I am from Reduce NEW..");
      return { ...state, exchangeRate_get_status: ExchangeRate_Status.ExchangeRate_Get.OFFLINE};

     case ExchangeRate_Actions.ExchangeRate_Get.LOADING:
     //  console.log("I am from Reduce NEW..");
       return { ...state, exchangeRate_get_status: ExchangeRate_Status.ExchangeRate_Get.LOADING };
      
    case ExchangeRate_Actions.ExchangeRate_Get.FAILURE:
    //  console.log("I am from Reduce NEW..");
        return { ...state, exchangeRate_get_status: ExchangeRate_Status.ExchangeRate_Get.FAILURE };
            
    default:
      return { ...state }
  }
};


//******************Post Reducer******************;

const post_initialState = {
  exchangeRate_post_status: ExchangeRate_Status.ExchangeRate_Post.NEW,
  exchangeRates: [],
};

export const NJT_ExchangeRate_Post_Reducer = (state = post_initialState, action) => {
  //console.log(action.type);
  switch (action.type) {

    case ExchangeRate_Actions.ExchangeRate_Post.NEW:
     // console.log("I am from Reduce NEW..");
      return { ...state, exchangeRate_Post_status: ExchangeRate_Status.ExchangeRate_Post.NEW };

    case ExchangeRate_Actions.ExchangeRate_Post.SUCCESS:
     console.log("I am from Reduce SUCCESS..", action.payload,action);

      return { ...state, exchangeRate_post_status: ExchangeRate_Status.ExchangeRate_Post.SUCCESS, exchangeRates: action.payload, recordSaved: action.status};

    case ExchangeRate_Actions.ExchangeRate_Post.OFFLINE:
    //  console.log("I am from Reduce NEW..");
      return { ...state, exchangeRate_post_status: ExchangeRate_Status.ExchangeRate_Post.OFFLINE, exchangeRates: action.payload };

     case ExchangeRate_Actions.ExchangeRate_Post.LOADING:
     //  console.log("I am from Reduce NEW..");
       return { ...state, exchangeRate_post_status: ExchangeRate_Status.ExchangeRate_Post.LOADING, exchangeRates: action.payload };
      
    case ExchangeRate_Actions.ExchangeRate_Post.FAILURE:
    //  console.log("I am from Reduce NEW..");
        return { ...state, exchangeRate_post_status: ExchangeRate_Status.ExchangeRate_Post.FAILURE, exchangeRates: action.payload };
            
    default:
      return { ...state }
  }
};



