import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../UploadBannerCard/UploadBannerCard.css';
// import Loading from '../../Utils/Loading';

const modalRoot = document.getElementById('message_portal');

export class Modal extends React.Component {
  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }
  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }
  render() {
    const {
      onClose,
      message = 'Are you sure you want to delete this record',
      onConfirm,
    } = this.props;
    return ReactDOM.createPortal(
      <div
        style={{
          position: 'fixed',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.3)',
          minHeight: '100vh',
          zIndex: '9999999999',
        }}
        // onClick={onClose}
      >
        <div
          style={{
            padding: 20,
            background: '#fff',
            borderRadius: '2px',
            display: 'inline-block',
            minHeight: '200px',
            margin: '1rem',
            position: 'relative',
            minWidth: '300px',
            boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
            justifySelf: 'center',
          }}
        >
          {this.props.children}
          <div className='main-del-box'>
            <div className='del-pop-text'>{message}</div>
          </div>
       
          <div className='del-pop-bnt'>
            <button id="yesBtn" onClick={onConfirm} className='btn-yes'>
              Yes
            </button>
            <button id="noBtn" onClick={onClose} className='btn-clos'>
              No
            </button>
            {this.props.loading ? (
              <>
                 <div className='outer-loader'>
              <div className='inner-loader' style={{left:"25px", bottom:"4px"}}>
                <span className='input-group-addon'>
                  <i class='fa fa-refresh fa-spin bc'></i>
                </span>
              </div>
            </div>
              </>
            ) : (
              <></>
            )}

          </div>
        </div>
      </div>,
      modalRoot
    );
  }
}

class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleShowMessageClick = () => this.setState({ showModal: true });
  handleCloseModal = () => this.setState({ showModal: false });
  render() {
    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className='del-pop-btn'>
          <button
            id="deleteBtn"
            onClick={this.props.handleShowMessageClick}
            className='card-icon'
          >
            <span className='material-icons' title='Delete'>
              delete_forever
            </span>
          </button>
          {this.props.showModal ? (
            <Modal
              onClose={this.props.handleCloseModal}
              onConfirm={this.props.handleConfirm}
              // banner = {this.props.banner}
              loading={this.props.loading}
              message={
                'Are you sure you want to delete ' +
                this.props.banner.name +
                '?'
              }
            ></Modal>
          ) : null}
        </div>
      </div>
    );
  }
}

export default DeleteModal;
