//******************Exchange Rate Server Calls******************;

import { ReferenceDataListActions } from "./ReferenceDataList_Constants";
import store from "../../../Store/store";
import { ROOT_URL,getCookie } from "../../../Config/Config";

export const ReferenceDataListServer = {
  getReferenceDataList: getReferenceDataList
};

//******************Get ReferenceDataLists******************;

function getReferenceDataList(data) {
  // alert(JSON.stringify(data))
  const res = fetch(ROOT_URL + "/api/configurations/getRecordRefTable", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body:JSON.stringify({tableName:data.selectedTable})
  })
    .then(res => {
      res
        .json()
        .then(response => {
          // alert(JSON.stringify(response))
          if(data.selectedTable !="")
          data.setTableData(response.data.data)
          store.dispatch({
            type: ReferenceDataListActions.ReferenceDataList_Get.SUCCESS,
            payload: response.data
          });
        })
        .catch(err => {
          console.log("ReferenceDataListError:", err);
          store.dispatch({
            type: ReferenceDataListActions.ReferenceDataList_Get.FAILED, payload:err
          });
        });
    })
    .catch(err => {
      console.log("ReferenceDataListError:", err);
      store.dispatch({
        type: ReferenceDataListActions.ReferenceDataList_Get.FAILED, payload:err
      });
    });

  return { type: ReferenceDataListActions.ReferenceDataList_Get.LOADING };
}
