import React, { Component, Fragment } from "react";
import "./AdministrationMainTwo.css";
import { Link, withRouter } from "react-router-dom";
// import AdministrationLogoutContainer from "./AdministrationLogout/AdministrationLogoutContainer";
import { ADMIN_END_BRANDING_IMAGE_URL,STATIC_IMAGES_URL } from "../../Config/Config";


class AdministrationMainTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  logoutHandler = () => {
    alert("logoutHandler Works");
    // this.props.history.push({
    //   pathname: "/login_page"
    // });
  };
  render() {
    return (
      <Fragment>
        <div className="header-slider">
          <img
            src={ADMIN_END_BRANDING_IMAGE_URL + "admin_njtransit_banner.jpg"}
            alt="not found"
          />
        </div>
        <div className="adm_container-am">
          <div className="admin-inner-container-am">

            <div className="container">

              <div className='row'>
                <div className=' col-sm-12 col-md-12 col-lg-12'>
                  <div className='brand-icon-am'>
                    <img
                      src={STATIC_IMAGES_URL + "admin-icon.svg"}
                      alt='not found'
                    />
                    <h1 className='adm_h1-am'>Admin</h1>
                  </div>
                </div>
              </div>
              <div className="main-section-box">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="main-col1-am">
                      <div className="col-box">
                        <h4 className="link-heading-am">Exchange Rates</h4>
                        <div className="link-bo am">
                          <p className="adm-p-link margin am">

                            <Link id="dollarsPointsLink" className="adm_link1 amt" to="/ExchangeRatesDollarToPoints">
                              Dollars to Points
                            </Link>
                          </p>
                          <p className="adm_link1">

                            <Link id="pointsOfferValueLink" className="admmin_link1 amt" to="/ExchangeRatesPointsToCoupon">
                              Points to Offer Value
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>


                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="main-col1-am margin-third">
                      <div className="col-box">
                        <h4 className="link-heading-am">Configuration & Logs</h4>
                        <div className="link-bo am">
                          <p className="adm-p-link margin am">

                            <Link id="viewChangeLogsLink" className="adm_link1 amt" to="view_change_logs">
                              View Change Logs
                            </Link>
                          </p>
                          <p className="adm-p-link margin am">

                            <Link id="manageReferenceLink" className="adm_link1 amt" to="/ReferenceDataConfiguration">
                              Manage Reference Tables
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div></div>
                </div>
                <div className="row mt-4">
                  <div className='col-sm-12 col-md-6 col-lg-6'>
                    <div className='main-col1-am margin-third'>
                      <div className='col-box'>
                        <h4 className='link-heading-am'>User Management</h4>
                        <div className='link-bo am'>

                          <p className="adm-p-link margin am">

                            <Link id="manageUsersLink" className="adm_link1 amt" to="/UserManagement">
                              Manage Users
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AdministrationMainTwo);
