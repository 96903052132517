export const ViewChangeLogsTableActions= {
	
	
	ViewChangeLogsTable_Get :{
		NEW:"ViewChangeLogsTable_Get_NEW",
		SUCCESS:"ViewChangeLogsTable_Get_SUCCESS",
		FAILED:"ViewChangeLogsTable_Get_FALIURE",
		LOADING:"ViewChangeLogsTable_Get_LOADING",
		NOT_AUTHORIZED: "ViewChangeLogsTable_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	ViewChangeLogsTable_Post :{
		NEW:"ViewChangeLogsTable_Post_NEW",
		SUCCESS:"ViewChangeLogsTable_Post_SUCCESS",
		FAILED:"ViewChangeLogsTable_Post_FALIURE",
		LOADING:"ViewChangeLogsTable_Post_LOADING",
		NOT_AUTHORIZED: "ViewChangeLogsTable_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const ViewChangeLogsTableStatus ={

	ViewChangeLogsTable_Get :{
		NEW:"ViewChangeLogsTable_Get_NEW",
		SUCCESS:"ViewChangeLogsTable_Get_SUCCESS",
		FAILED:"ViewChangeLogsTable_Get_FALIURE",
		LOADING:"ViewChangeLogsTable_Get_LOADING",
		NOT_AUTHORIZED: "ViewChangeLogsTable_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	ViewChangeLogsTable_Post :{
		NEW:"ViewChangeLogsTable_Post_NEW",
		SUCCESS:"ViewChangeLogsTable_Post_SUCCESS",
		FAILED:"ViewChangeLogsTable_Post_FALIURE",
		LOADING:"ViewChangeLogsTable_Post_LOADING",
		NOT_AUTHORIZED: "ViewChangeLogsTable_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
