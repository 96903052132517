import React, { Component, Fragment } from "react";
//import './RefrenceDataUpdate.css'
import { RefrenceDataUpdateStatus } from "./RefrenceDataUpdateConstants";
import RefrenceDataUpdate from "./RefrenceDataUpdate";
import { withRouter } from "react-router-dom";
import dateFormat from "dateformat";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Loading from "../../Utils/Loading";
import {ref_globals} from "RefRecordsConstants"
export class RefrenceDataUpdateView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
    props.resetReducerState()
  }

  updateHandler = () => {
    // alert(JSON.stringify(this.props.prevData));
    // if(JSON.stringify(this.props.data) != JSON.stringify(this.props.prevData)){
    //   alert("test")
    // }
    var flag = false
    Object.keys(this.props.data).forEach((key,i)=>{
      if(/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(this.props.data[key])
    || /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(this.props.data[key])
      )
      try{
        this.props.data[key] = dateFormat(this.props.data[key],"yyyy-mm-dd")
        // alert(this.props.data[key])
      }catch{

      }

      if(this.props.data[key] != this.props.prevData[key] && this.props.data[key] !=""){
        flag = true
      }
    })
    if(flag){
     const data = {
        tableName:this.props.selectedTable,
        data: JSON.stringify(this.props.data),
        isRef_Global:this.props.selectedTable == ref_globals.ref_globals,
        prevVersion:this.props.prevVersion,
        getReferenceDataList:this.props.getReferenceDataList
      }
    
      // if(data.tableName == ref_globals.ref_globals)
      
      // alert(JSON.stringify(data))
      this.props.handleRefrenceDataUpdate(data)
    }
    // alert(flag)
  };
  getScreen(status) {
    switch (status) {
      case RefrenceDataUpdateStatus.RefrenceData_Update.NEW:
        // setRecentlyDeleted={this.props.setRecentlyDeleted}

        return (
          <Fragment>
            <RefrenceDataUpdate
              updateHandler={this.updateHandler}
              // className={this.props.className}
              // ExchangeRatesDollarToPoints={this.props.ExchangeRatesDollarToPoints}
              // handleRefrenceDataUpdate={this.props.handleRefrenceDataUpdate}
            />
          </Fragment>
        );

      case RefrenceDataUpdateStatus.RefrenceData_Update.SUCCESS:
        // toast.success(this.props.RefrenceDataUpdate_Message);
        // this.props.getReferenceDataList(this.props)
        return (
          <Fragment>
            <RefrenceDataUpdate
              updateHandler={this.updateHandler}
              // className={this.props.className}
              // ExchangeRatesDollarToPoints={this.props.ExchangeRatesDollarToPoints}
              // handleRefrenceDataUpdate={this.props.handleRefrenceDataUpdate}
            />
          </Fragment>
        );
        break;
      case RefrenceDataUpdateStatus.RefrenceData_Update.FAILED:
        toast.error(this.props.RefrenceDataUpdate_Message);
        this.props.resetReducerState()
        return (
          <Fragment>
            <RefrenceDataUpdate
              updateHandler={this.updateHandler}
              // className={this.props.className}
              // ExchangeRatesDollarToPoints={this.props.ExchangeRatesDollarToPoints}
              // handleRefrenceDataUpdate={this.props.handleRefrenceDataUpdate}
            />
          </Fragment>
        );

      case RefrenceDataUpdateStatus.RefrenceData_Update.LOADING:
        this.state.open = true;
        return (
          <div
            style={{
              textAlign: "flex-end",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Loading />
          </div>
        );
        break;
      default:
        return <div />;
    }
  }
  render() {
    // alert(this.props.RefrenceDataUpdate_Status)
    return this.getScreen(this.props.RefrenceDataUpdate_Status);
  }
}

export default withRouter(RefrenceDataUpdateView);
