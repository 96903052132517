import React, { Suspense, Component } from 'react';
import { Route } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import './App.css';

import { VERSION_NO } from './Config/Config';
import NJTExchangeRateTableContainer from './Components/ExchangeRateAdmin/NJTExchangeRateTableContainer';
import AdministrationMain from './Components/AdministrationMain/AdministrationMain';
import ExchangeRatesDollarToPointsContainer from './Components/ExchangeRatesDollarToPoints/ExchangeRatesDollarToPointsContainer';
import ExchangeRatesPointsToCoupon from './Components/ExchangeRatesPointsToCoupon/ExchangeRatesPointsToCouponContainer';
import AdministrationLoginPage from './Components/AdministrationLoginPage/AdministrationLoginContainer';
import Header from './Components/HeaderSection/Header';
import InnerHeader from './Components/HeaderSection/InnerHeader';
import ViewChangeLogs from './Components/ViewChangeLogs/ViewChangeLogs';
import ReferenceDataListContainer from './Components/ReferenceDataConfiguration/ReferenceDataConfigurationContainer';
import InnerHeaderBranding from "./Components/HeaderSection/InnerHeaderBranding"
import HeaderInner from "./Components/HeaderSection/HeaderBranding"
import AdministrationMainTwo from './Components/AdministrationMain/AdministrationMainTwo';
import UserManagement from './Components/UserManagement/UserManagement';
import AddNewUser from './Components/UserManagement/AddNewUser/AddNewUserContainer';
import ChangePassword from './Components/UserManagement/ChangePassword/ChangePasswordContainer';
import EditEmail from './Components/UserManagement/ChangeEmail/ChangeEmailContainer';

class App extends Component {
  componentWillMount() {}

  render() {
    // console.log(this.props);
    // console.log('TEST :', this.props);
    return (
      <div>
        {/* <Header cookies={this.props.cookies} /> */}
        <HeaderInner cookies={this.props.cookies} />
        {/* <InnerHeader cookies={this.props.cookies} /> */}
        <InnerHeaderBranding cookies={this.props.cookies} />

        <Suspense fallback={<div style={{ height: '100vh' }}> Loading...</div>}>
          <Route
            exact
            path='/exchangeRate'
            render={() => <NJTExchangeRateTableContainer />}
          />

          <Route
            exact
            path='/'
            render={() => (
              <AdministrationLoginPage cookies={this.props.cookies} />
            )}
          />
          <Route
            exact
            path='/Admin'
            // render={() => <AdministrationMain cookies={this.props.cookies} />}
            render={() => <AdministrationMainTwo cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/ExchangeRatesDollarToPoints'
            render={() => <ExchangeRatesDollarToPointsContainer />}
          />
          <Route
            exact
            path='/ExchangeRatesPointsToCoupon'
            render={() => <ExchangeRatesPointsToCoupon />}
          />

          <Route
            exact
            path='/view_change_logs'
            render={() => <ViewChangeLogs cookies={this.props.cookies} />}
          />

          <Route
            exact
            path='/ReferenceDataConfiguration'
            render={() => (
              <ReferenceDataListContainer cookies={this.props.cookies} />
            )}
          />
           <Route
            exact
            path='/UserManagement'
            render={() => <UserManagement cookies={this.props.cookies} />}
          />
           <Route
            exact
            path='/ChangePassword'
            render={() => <ChangePassword cookies={this.props.cookies} />}
          />

          <Route
            exact
            path='/EditEmail'
            render={() => <EditEmail cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/AddNewUser'
            render={() => <AddNewUser cookies={this.props.cookies} />}
          />
        </Suspense>
        <div className='version-get-b'>
          <div className='version'>{VERSION_NO}</div>
        </div>
      </div>
    );
  }
}

export default withCookies(App);
