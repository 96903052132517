import React, { Component } from "react";
import { withRouter } from "react-router-dom";
//import { Link } from "react-router-dom";
import "./ExchangeRatesPointsToCoupon.css";
import EXCHANGERATESPOINTSTOCOUPON_VIEW from "./ExchangeRatesPointsToCoupon_View"
import ExchangeRatesDateRanges from "../ExchangeRatesDateRanges/ExchangeRatesDateRanges";

class ExchangeRatesDollarToPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ViewMode:false,
      minFromDate:new Date(),
      DeleteMode:false,
      FromDate:"",
      toDateReadOnly:false,
      RecentlySaved: false,
      EditActiveDate: false,// this is used to make last active exchange rate editable
      DateRanges:[]
    };
    props.resetReducerState()
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  setFromDate = (date)=>{
    var nextDate=new Date(date)
    this.setState({
      FromDate:nextDate.setDate(nextDate.getDate() +1)
    })
  }
  setDateRange = data => {
    this.state.DateRanges = data
  };
  
  setEditActiveDate = (edit) => {
    this.setState({
      EditActiveDate: edit
    })
  }

  setToDateReadOnly = (mode) =>{
   // alert(mode)
    this.setState({
      toDateReadOnly:mode
    })
  }
  setRecentlySaved = async() => {

    await this.setState({
       RecentlySaved: !this.state.RecentlySaved
     })
      this.setState({
       RecentlySaved: !this.state.RecentlySaved
     })
   }
  setDeleteMode = (mode) =>{
    this.setState({
      DeleteMode:mode
    })
  }
  setMinFromDate = (date) =>{
    var nextDate=new Date(date)
    this.setState({
      minFromDate:nextDate.setDate(nextDate.getDate() +1)
    })
    
  }

  homeHandler = () => {
    this.props.history.push({
      pathname: "/Admin"
    });
  };
 
setViewMode = (view) =>{
  this.setState({
    ViewMode:view
  })
}
  // todo: Render ..........................

  render() {
    

    return (
        <div id="exchange_rate" className="erpc_container">
          <div className="erpc_header">
            <h1>Exchange Rates - Points to Offer Value</h1>
          </div>
        <div className="erdp_content_container">
          { !this.state.RecentlySaved?
          <ExchangeRatesDateRanges 
          getExchangeRatesDollarToPoints={this.props.getCouponExchangeRates}
          resetReducerState={this.props.resetReducerState}
          setMinFromDate={this.setMinFromDate}
          setViewMode={this.setViewMode}
          setDeleteMode={this.setDeleteMode}
          setFromDate={this.setFromDate}
          setToDateReadOnly={this.setToDateReadOnly}
          setEditActiveDate={this.setEditActiveDate}
          setDateRange={this.setDateRange}
          />: <h1>Loading</h1>  }
        </div>
        <div style={this.state.FromDate ==""?{opacity:0.3, pointerEvents:"none"}:{}}>

        
      <EXCHANGERATESPOINTSTOCOUPON_VIEW 
      ExchangeRatesPointsToCoupon_post_status={this.props.ExchangeRatesPointsToCoupon_post_status} 
      ViewMode={this.state.ViewMode}
      saveExchangeRatesPointsToCoupon={this.props.saveExchangeRatesPointsToCoupon}
      updateExchangeRatesPointsToCoupon={this.props.updateExchangeRatesPointsToCoupon}
      ExchangeRatesPointsToCoupon={this.props.ExchangeRatesPointsToCoupon}
      resetReducerState={this.props.resetReducerState}
      minFromDate={this.state.minFromDate}
      FromDate={this.state.FromDate}
      setRecentlySaved={this.setRecentlySaved}
      toDateReadOnly={this.state.toDateReadOnly}
      EditActiveDate={this.state.EditActiveDate}
      DeleteMode={this.state.DeleteMode}
      DateRanges = {this.state.DateRanges}
      ExchangeRatesPointsToCoupon_Message = {this.props.ExchangeRatesPointsToCoupon_Message}
      />
      </div>
        </div>
    );
  }
}

export default withRouter(ExchangeRatesDollarToPoints);
