import React, {  Fragment, PureComponent } from 'react'
//import './UserRoleList.css'
import { UserRoleListStatus } from './UserRoleListConstants';
import CustomLabelDropDownInput from "../../../Inputs/CustomLabelDropDownInput/CustomLabelDropDownInput";
import { toast } from 'react-toastify';
import Loading from '../../../Utils/Loading';
import MultiSelectDropdownCheckbox from '../../../Inputs/MultiSelectDropdown/MultiSelectDropdownCheckbox';

let RolesData = [
  {name:"Admin",value:1},
  {name:"Partner Support",value:2},
  {name:"Customer Support",value:3}
]

export class UserRoleListView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    }
    this.props.getUserRoleList()
  }
  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.AddNewUser_POST_status === UserRoleListStatus.UserRoleList_GET.SUCCESS){
      nextProps.setLoading(false)
    }
    else if(nextProps.AddNewUser_POST_status === UserRoleListStatus.UserRoleList_GET.FAILED){
      alert(nextProps.UserRoleList_Error)
      toast.error(nextProps.UserRoleList_Error)
     
      nextProps.setLoading(false)
    }
    else if(nextProps.AddNewUser_POST_status === UserRoleListStatus.UserRoleList_GET.LOADING){
      // if(this.props.setLoading)
      nextProps.setLoading(true)
    }
    
    return null
  }
  getScreen(status) {
    switch (status) {
      case UserRoleListStatus.UserRoleList_GET.NEW:
        
        return (
          <Fragment >
            <MultiSelectDropdownCheckbox
            label="Role"
            name="name"
            value=""
            placeHolder="Choose a Role"
            inputValue={this.props.inputValue}
            hideFirst={true}
            onSelect={this.props.onChange}
            data={RolesData}
            flag={this.props.roleFlag}
            errorMsg={this.props.roleErrorMsg}
            />
            {/* <CustomLabelDropDownInput
                label="Role"
                name="name"
                value=""
                firstName="Choose a  Role"
                inputValue={this.props.inputValue}
                hideFirst={true}
                onChange={this.props.onChange}
                data={RolesData}
                //disabled={this.state.disableAllFields}
            /> */}
          </Fragment>
        );


      case UserRoleListStatus.UserRoleList_GET.SUCCESS:
          if(this.props.setLoading)
          this.props.setLoading(false)

          RolesData = this.props.UserRoleList.map((role)=>{
            return {name:role.role_name, value: role.role_id}
          })

          // alert(JSON.stringify(RolesData))
          
          // alert("user role success")

          //console.log("user role list", RolesData)
          // alert(JSON.stringify(this.props.inputValue))
        return (
          <Fragment >

            <MultiSelectDropdownCheckbox
            label="Role"
            name="name"
            value=""
            placeHolder="Choose a Role"
            inputValue={this.props.inputValue}
            hideFirst={true}
            onSelect={this.props.onChange}
            data={RolesData}
            flag={this.props.roleFlag}
            errorMsg={this.props.roleErrorMsg}
            />
            {/* <CustomLabelDropDownInput
                label="Role"
                name="name"
                value=""
                firstName="Choose a  Role"
                inputValue={this.props.inputValue}
                hideFirst={true}
                onChange={this.props.onChange}
                data={RolesData}
                //disabled={this.state.disableAllFields}
              /> */}
          </Fragment>
        );
        break;
      case UserRoleListStatus.UserRoleList_GET.FAILED:
       
        return (
          <Fragment>
            <MultiSelectDropdownCheckbox
            label="Role"
            name="name"
            value=""
            placeHolder="Choose a Role"
            inputValue={this.props.inputValue}
            hideFirst={true}
            onSelect={this.props.onChange}
            data={RolesData}
            flag={this.props.roleFlag}
            errorMsg={this.props.roleErrorMsg}
            />

           {/* <CustomLabelDropDownInput
                label="Role"
                name="name"
                value=""
                firstName="Choose a  Role"
                inputValue={this.props.inputValue}
                hideFirst={true}
                onChange={this.props.onChange}
                data={RolesData}
                //disabled={this.state.disableAllFields}
              /> */}
          </Fragment>
        );
      case UserRoleListStatus.UserRoleList_GET.LOADING:
      
        if(this.props.stopLoading)
        return <div></div>
        else
        return (
          <div style={{ textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center", width:"100%" }}>
            <Loading/>
          </div>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
   // alert(this.props.UserRoleList_GET_status)
    return (
      this.getScreen(this.props.UserRoleList_GET_status)
    )
  }
}

export default (UserRoleListView)
