import React, { Component } from "react";
import "../../Cards/UploadBannerCard/UploadBannerCard.css"
class RefrenceDataUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    doneHandler = () => {
        //alert(JSON.stringify(this.props.ExchangeRatesDollarToPoints))
      //  this.props.handleRefrenceDataUpdate(this.props.ExchangeRatesDollarToPoints)

    }

    render() {
        return (
            <button id="doneBtn" className="btn-yes right" onClick={this.props.updateHandler}>Done</button>
              
        );
    }
}

export default RefrenceDataUpdate;
