
//******************Exchange Rate Server Calls******************;

import {ROOT_URL, getCookie} from '../../Config/Config';
import store from '../../Store/store'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {failure_messages} from 'Messages'
export const ExchangeRateServer = {
    getExchangeRateDates:getExchangeRateDates,
    getExchangeRateCouponDateRanges:getExchangeRateCouponDateRanges
};

//******************Insert ExchangeRatesDollarToPointss******************;

  
//******************Get ExchangeRatesDollarToPointss******************;


//******************Update ExchangeRatesDollarToPoints******************;

  
function getExchangeRateCouponDateRanges(data){
  const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRateCouponDateRanges/', {
      method: 'POST',
      mode: 'cors',
      headers: { 
        'authorization':getCookie("admin_token"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      }).then((response)=>{
        response.json().then(response=>{
          data.setLoadingStatus(false)
          if(response.status == "200"){
            if(response.exchangeRates.length>0)
         {

            data.setDateRange(response.exchangeRates)
            if(response.exchangeRates[response.exchangeRates.length - 1].to_date)
            data.setFromDate(response.exchangeRates[response.exchangeRates.length - 1].to_date)
            else
            data.setFromDate('') 
         }else
         {
           data.setDateRange([])
           data.setFromDate('')
         }
         return ;
         }else
         {
           toast.error(response.error)
           return ;
           }    
        }).catch(error =>{
          data.setLoadingStatus(false)
        toast.error(failure_messages.ExchangeRateGetError)
        }
        )
       }).catch((error) => {
        data.setLoadingStatus(false)
        toast.error(failure_messages.ExchangeRateGetError)
           return;
     
    })

};

  
function getExchangeRateDates(data){
      const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRateDateRanges/', {
          method: 'POST',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          }).then((response)=>{
            response.json().then(response=>{
              data.setLoadingStatus(false)
              if(response.status == "200"){
            
                if(response.exchangeRates.length>0)
             {
             //  alert("test")
                data.setDateRange(response.exchangeRates)
                if(response.exchangeRates[response.exchangeRates.length - 1].to_date)
                data.setFromDate(response.exchangeRates[response.exchangeRates.length - 1].to_date)
                else
                data.setFromDate('')
               
             }else
             {
               data.setDateRange([])
               data.setFromDate('')
             }
             return;
             }else
             {
                store.dispatch({error:response.error})
              toast.error(response.error)
               return;
              }    
            }).catch((error) => {
              data.setLoadingStatus(false)
              toast.error(failure_messages.ExchangeRateGetError)
          })

           }).catch((error) => {
            data.setLoadingStatus(false)
            toast.error(failure_messages.ExchangeRateGetError)
            return;
         
        })
 
    };
