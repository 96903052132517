import { connect } from "react-redux";
import ViewChangeLogsTable_View from "./ViewChangeLogsTable_View";
import { ViewChangeLogsTableServer } from "./server";
import { ViewChangeLogsTableActions } from "./ViewChangeLogsTableConstants";
const mapStateToProps = state => {
  console.log(state);
  return {
    ViewChangeLogsTable_Get_status:
      state.ViewChangeLogsTableReducer.ViewChangeLogsTable_Status,
    // ViewChangeLogsTable_post_status:state.NJT_ViewChangeLogsTable_Reducer.ViewChangeLogsTable_Status,
    ViewChangeLogsTable:
      state.ViewChangeLogsTableReducer.ViewChangeLogsTableDetails
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getViewChangeLogsTable: (data) => {
      dispatch(ViewChangeLogsTableServer.getViewChangeLogsTable(data));
    },
    getViewPromotionalLogsTable: (data) => {
      dispatch(ViewChangeLogsTableServer.getViewPromotionalLogsTable(data));
    },
    resetReducerState:()=>{
      dispatch({type:ViewChangeLogsTableActions.ViewChangeLogsTable_Get.NEW})
    }
  };
};
const ViewChangeLogsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewChangeLogsTable_View);

export default ViewChangeLogsTableContainer;
