import React, {  PureComponent } from "react";

import "./LabelDropDownInput.css";
import {coupon_transaction_subtype_id} from "RefRecordsConstants"
const Data =[]
// const Data = [
//   { value: "selectState", name: "Select State" },
//   { value: "AL", name: "AL" },
//   { value: "AK", name: "AK" },
//   { value: "AZ", name: "AZ" },
//   { value: "AR", name: "AR" },
//   { value: "CA", name: "CA" },
//   { value: "CO", name: "CO" },
//   { value: "CT", name: "CT" },
//   { value: "DE", name: "DE" },
//   { value: "FL", name: "FL" },
//   { value: "GA", name: "GA" },
//   { value: "HI", name: "HI" },
//   { value: "ID", name: "ID" },
//   { value: "IL", name: "IL" },
//   { value: "IN", name: "IN" },
//   { value: "IA", name: "IA" },
//   { value: "KS", name: "KS" },
//   { value: "KY", name: "KY" },
//   { value: "LA", name: "LA" },
//   { value: "ME", name: "ME" },
//   { value: "MD", name: "MD" },
//   { value: "MA", name: "MA" },
//   { value: "MI", name: "MI" },
//   { value: "MN", name: "MN" },
//   { value: "MS", name: "MS" },
//   { value: "MO", name: "MO" },
//   { value: "MT", name: "MT" },
//   { value: "NE", name: "NE" },
//   { value: "NV", name: "NV" },
//   { value: "NH", name: "NH" },
//   { value: "NJ", name: "NJ" },
//   { value: "NM", name: "NM" },
//   { value: "NY", name: "NY" },
//   { value: "NC", name: "NC" },
//   { value: "ND", name: "ND" },
//   { value: "OH", name: "OH" },
//   { value: "OK", name: "OK" },
//   { value: "OR", name: "OR" },
//   { value: "PA", name: "PA" },
//   { value: "RI", name: "RI" },
//   { value: "SC", name: "SC" },
//   { value: "SD", name: "SD" },
//   { value: "TN", name: "TN" },
//   { value: "TX", name: "TX" },
//   { value: "UT", name: "UT" },
//   { value: "VT", name: "VT" },
//   { value: "VA", name: "VA" },
//   { value: "WA", name: "WA" },
//   { value: "WV", name: "WV" },
//   { value: "WI", name: "WI" },
//   { value: "WY", name: "WY" },
// ];

export class LabelDropDownInput extends PureComponent {
  constructor(props) {
    super(props);

  }
  componentDidMount(){
    if(this.props.selectValue){
      this.props.data.forEach(element => {
        if(element.name == this.props.inputValue.name)
        this.props.onChange({target:{value:JSON.stringify(element)}})
      });

    }
  }
  render() {
    const {
      data = Data,
      onChange,
      containerStyle,
      loaderStyle,
      inputValue,
      label = "label",
      errorMsg = "errorMsg",
      flag = false,
      disabled,
      elipse,
      showDollar
    } = this.props;
    return (
      <div className="lddi_input_container" style={containerStyle}>
        <label htmlFor="" className="lddi_label">
          {label}
        </label>
        <div className="lddi_div1">
          <div className="lddi_container">
            <select
              id="dropDown"
              className="lddi_input"
              value={inputValue}
              onChange={onChange}
            >
              {!disabled ? data.map((item, index) => {
                return (
                  <option value={this.props.isJSON?JSON.stringify(item):item.value}  key={index}>
                    {showDollar && index>0?this.props.coupon_transaction_subtype_id == coupon_transaction_subtype_id.PercentageType?"":"$":""}{elipse &&item.name.length > 25 ? item.name.substring(0,25)+"...":item.name}{showDollar && index>0?this.props.coupon_transaction_subtype_id == coupon_transaction_subtype_id.PercentageType?"%":"":""}
                  </option>
                );
              }):<option value={inputValue} disabled selected key={1}>
              {inputValue}
            </option>}
            </select>
            <i className="material-icons lddi_icon">expand_more</i>
          </div>
          <p
            className="lddi_error_text"
            style={{ display: flag ? "block" : "none" }}
          >
            {errorMsg}
          </p>
        </div>
        {this.props.LoadingIcon && (
            <div className='outer-loader'>
              <div className='inner-loader' style={loaderStyle}>
                <span className='input-group-addon'>
                  <i class='fa fa-refresh fa-spin bc'></i>
                </span>
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default LabelDropDownInput;
