import React, { Component, Fragment } from "react";
import { Modal } from "../../Cards/UploadBannerCard/DeleteModal";
import dateFormat from "dateformat";

class DeleteExchangeRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModel: false,
    };
  }
  deleteHandler = () => {
    //alert(JSON.stringify(this.props.ExchangeRatesDollarToPoints))
    this.props.handleDeleteExchangeRates(
      this.props.ExchangeRatesDollarToPoints
    );
  };
  setshowModel = () => {
    this.setState({
      showModel: true,
    });
  };
  hideModel = () => {
    this.setState({
      showModel: false,
    });
  };
  render() {
    //   alert(this.props.ToDateRange)
      var todate = this.props.ToDateRange
      if(todate && todate!=null)
      todate = dateFormat(new Date(this.props.ToDateRange), "mm/dd/yyyy")
    return (
      <Fragment>
        <button id="deleteBtn" className={this.props.className} onClick={this.setshowModel}>
          Delete
        </button>
        {this.state.showModel ? (
          <Modal
            message={ "Are you sure you want to delete date range: " +
                dateFormat(new Date(this.props.fromDate), "mm/dd/yyyy") +" - "+todate +" ?"}
            onConfirm={this.deleteHandler}
            onClose={this.hideModel}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default DeleteExchangeRates;
