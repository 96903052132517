//******************Exchange Rate Server Calls******************;

import { FilterFieldActions } from "./FilterFieldConstants";
import store from "../../../Store/store";
import { ROOT_URL,getCookie } from "../../../Config/Config";

export const FilterFieldServer = {
  getFilterField: getFilterField
};

//******************Get FilterFields******************;

function getFilterField(data) {
  const res = fetch(ROOT_URL + "/api/table/getAllFieldsOfTable", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body:JSON.stringify(data)
  })
    .then(res => {
      // console.log("FilterField:", res);
      res
        .json()
        .then(response => {
          console.log("FilterField:", response);
          store.dispatch({
            type: FilterFieldActions.FilterField_Get.SUCCESS,
            payload: response.data
          });
        })
        .catch(err => {
          console.log("FilterFieldError:", err);
          store.dispatch({
            type: FilterFieldActions.FilterField_Get.FAILED
          });
        });
    })
    .catch(err => {
      console.log("FilterFieldError:", err);
      store.dispatch({
        type: FilterFieldActions.FilterField_Get.FAILED
      });
    });

  return { type: FilterFieldActions.FilterField_Get.LOADING };
}
