import React, { Component } from "react";
import "./ViewChangeLogs.css";
import DropDownInput from "../Inputs/DropDownInput";
import FilterTable from "./FilterTable/FilterTable";
import FilterFieldContainer from "./FilterField/FilterFieldContainer";
import ViewChangeLogsTableContainer from "./ViewChangeLogsTable/ViewChangeLogsTableContainer";
import dateFormat from "dateformat"
import DatePicker from "../Inputs/DatePicker";
import { CSVLink } from "react-csv";
import {ref_table} from 'RefRecordsConstants'

const actionData = [
  { id: "0", value: "0", name: "Select" },
  { id: "1", value: "1", name: "Insert" },
  { id: "2", value: "2", name: "Update" },
  { id: "3", value: "3", name: "Delete" }
];

const limitData = [
  { id: "1", value: 50, name: "50" },
  { id: "2", value: 100, name: "100" },
  { id: "3", value: 250, name: "250" }
];

const headers = [
  { label: "Table Name", key: "table_name" },
  { label: "Field Name", key: "field_name" },
  { label: "Action", key: "transaction_type_name" },
  { label: "Original Value", key: "original_value" },
  { label: "New Value", key: "new_value" },
  { label: "Date & Time of Change", key: "transaction_datetime" }
];
const headers2 = [
  { label: "Table Name", key: "table_name" },
  { label: "Field Name", key: "field_name" },
  { label: "Action", key: "transaction_type_name" },
  { label: "Original Value", key: "original_value" },
  { label: "New Value", key: "new_value" },
  { label: "Points", key: "number_of_points" },
  { label: "Date & Time of Change", key: "transaction_datetime" }
];
export class ViewChangeLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: "",
      tableID: "",
      tableFlag: "",
      field: "",
      fieldFlag: "",
      action: "",
      fromDate:"",
      fromDateErrorMsg: "Field Required",
      fromDateFlag: false,
      toDate:  "",
      MintoDate: "",
      toDateErrorMsg: "Field Required",
      toDateFlag: false,
      actionFlag: "",
      getTableData: {},
      Log_Data:[],
      LogLimit:50
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  tableHandler = tableData => {
    try {
      const res = JSON.parse(tableData);
      this.setState({
        table: res.table_name,
        tableID: res.table_id,
        tableFlag: false,
        field: ""
      });
    } catch (e) {
      this.setState({
        table: "",
        tableID: "",
        field: ""
      });
    }
  };
  fieldHandler = fieldData => {
    // console.log("field:", fieldData);
    try {
      const res = JSON.parse(fieldData);
      this.setState({ field: res.COLUMN_NAME, fieldFlag: false });
    } catch (e) {
      this.setState({ field: "" });
    }
  };
  actionHandler = event => {
//    alert(action.value)
    console.log('action value', event.target.value)
    this.setState({ action: event.target.value, actionFlag: false });
  };
  applyHandler = () => {
    if (this.state.table === "") {
      this.setState({ tableFlag: true });
    } else if (this.state.field === "") {
      this.setState({ fieldFlag: true });
    } else if (this.state.action === "") {
      this.setState({ actionFlag: true });
    }else if (this.state.fromDate === "") {
      this.setState({ fromDateFlag: true });
    }else if (this.state.toDate === "") {
      this.setState({ toDateFlag: true });
    } 
     else {
      const data = {
        table_id: this.state.tableID,
        field_name: this.state.field,
        transaction_type_id: this.state.action,
        startDate: dateFormat(this.state.fromDate, 'yyyy-mm-dd')+'T00:00:00.000Z',
        endDate: dateFormat(this.state.toDate, 'yyyy-mm-dd')+'T23:59:59.000Z',
        LogLimit: parseInt(this.state.LogLimit) 
      };
      this.setState({ getTableData: data });
      console.log("applyHandler Works:", data);
    }
  };
setLogData = data =>{
  this.setState({Log_Data:data})
}
  fromDateHandler = e => {
    
      this.setState({
        fromDate: e,
        fromDateFlag: false,
        toDate: "",
        toDateFlag: false,
        fromDateErrorMsg: "Field Required",
        MintoDate: new Date(e).setDate(new Date(e).getDate() + 1)
      });
  };

  toDateHandler = e => {
    
      this.setState({
        toDate: e,
        toDateFlag: false,
        toDateErrorMsg: "Field Required"
      });
  };
  handleLogLimit = (event) => {
    
    this.setState({
      LogLimit: event.target.value});
  };

  
  render() {
    const {
      table,
      tableID,
      tableFlag,
      field,
      fieldFlag,
      action,
      actionFlag,
      fromDate,
      fromDateErrorMsg,
      fromDateFlag,
      toDate,
      toDateErrorMsg,
      toDateFlag,
    } = this.state;
    console.log("table:", table);
    console.log("tableID:", tableID);
    console.log("field:", field);
    console.log("action:", action);
    return (
      <div className="vcl_conatiner">
                      <div className="erpc_header changelog-head">
                         <h1>View Change Logs</h1>
                      </div>
        <div className="vcl_div_container">
          <FilterTable
            inputValue={table}
            flag={tableFlag}
            errorMsg="Field Required"
            onChangeEvent={this.tableHandler}
          />
          <FilterFieldContainer
            table={this.state.table}
            inputValue={field}
            flag={fieldFlag}
            errorMsg="Field Required"
            onChangeEvent={this.fieldHandler}
            disabled={table === "" ? true : false}
          />
          <div className="vcl_div vcl_div4">
            <p className="vcl_p1">
              Filter: <span className="vcl_sp1">Action</span>
            </p>
            <DropDownInput
              id="idAction"
              data={actionData}
              FirstName="Select"
              inputValue={action}
              flag={actionFlag}
              errorMsg="Field Required"
              onChange={this.actionHandler}
            />
          </div>
          <div className="vcl_div vcl_div2">
            <p className="vcl_p1">
              Filter: <span className="vcl_sp1">Start Date</span>
            </p>
            <DatePicker
                id="filterStartDate"
                inputValue={fromDate}
                errorMsg={fromDateErrorMsg}
                flag={fromDateFlag}
                maxDate={new Date()}
                onChange={this.fromDateHandler}
                disabled={
                this.state.ContractEndDate == "" ||
                this.props.UpdateBannerData != undefined
                }
            />
          </div>
          <div className="vcl_div vcl_div2">
            <p className="vcl_p1">
              Filter: <span className="vcl_sp1">End Date</span>
            </p>
            <DatePicker
                         id="filterEndDate"
                        inputValue={toDate}
                        errorMsg={toDateErrorMsg}
                        flag={toDateFlag}
                        minDate={this.state.MintoDate}
                        maxDate={new Date()}
                        onChange={this.toDateHandler}
                        disabled={this.state.ContractEndDate == ""}
                      />
          </div>
          <div className="vcl_div vcl_div4" style={{width:'7%'}}>
          <p className="vcl_p1">
              Filter: <span className="vcl_sp1">Limit</span>
            </p>
            <DropDownInput
              id="filterLimit"
              onChange={this.handleLogLimit}
              data={limitData}
              inputValue={this.state.LogLimit}
              FirstValue={50}
            />
      {/* <button className="vcl_btn1" style={{margin:0}} aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
      {this.state.LogLimit}
      </button>
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchorEl}
       // keepMounted
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleClose}
      >
        <MenuItem onClick={this.handleClose}>50</MenuItem>
        <MenuItem onClick={this.handleClose}>100</MenuItem>
        <MenuItem onClick={this.handleClose}>150</MenuItem>
      </Menu> */}
    </div>
          <button id="applyBtn" className="vcl_btn1" onClick={this.applyHandler}>
            Apply
          </button>
          <div className="download">
          <button id="csvDownloadBtn" className="vcl_btn2" style={{margin:0}}>
         {this.state.Log_Data.length > 0 ? <CSVLink data={this.state.Log_Data} headers={this.state.tableID == ref_table.tbl_promotional_points_log?headers2:headers} 
         filename = {dateFormat(new Date(),'mm/dd/yyyy_hh:MM')+'_View Change Logs.csv'}
         style={{ color: "white" }}>
            <i className="material-icons vcl_icon">get_app</i>
          </CSVLink>:  <i className="material-icons vcl_icon">get_app</i>}
        </button>
        </div>
          {/* <button
            style={{ marginLeft: "20px" }}
            className="vcl_btn1"
            onClick={this.clearFilterHandler}
          >
            Clear
          </button> */}
        </div>
        <ViewChangeLogsTableContainer getTableData={this.state.getTableData} setLogData = {this.setLogData} tableID={this.state.tableID}/>
       
      </div>
    );
  }
}

export default ViewChangeLogs;
