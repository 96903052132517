import { connect } from "react-redux";
import ExchangeRatesPointsToCoupon from "./ExchangeRatesPointsToCoupon";
import {ExchangeRatesPointsToCouponServer} from "./server"
import {ExchangeRatesPointsToCouponActions} from './ExchangeRatesPointsToCouponconstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        ExchangeRatesPointsToCoupon_post_status:state.ExchangeRatesPointsToCoupon_Post_Reducer.ExchangeRatesPointsToCoupon_Status,
       // ExchangeRatesPointsToCoupon_post_status:state.NJT_ExchangeRatesPointsToCoupon_Reducer.ExchangeRatesPointsToCoupon_Status,
        ExchangeRatesPointsToCoupon:state.ExchangeRatesPointsToCoupon_Post_Reducer.ExchangeRatesPointsToCouponDetails,
        ExchangeRatesPointsToCoupon_Message:state.ExchangeRatesPointsToCoupon_Post_Reducer.ExchangeRatesPointsToCoupon_Message
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        saveExchangeRatesPointsToCoupon: (data) => {

           dispatch( ExchangeRatesPointsToCouponServer.saveExchangeRatesPointsToCoupon(data) )
        },
        getCouponExchangeRates: (data) => {

            dispatch( ExchangeRatesPointsToCouponServer.getCouponExchangeRates(data) )
         },

         updateExchangeRatesPointsToCoupon: (data) => {

            dispatch( ExchangeRatesPointsToCouponServer.updateExchangeRatesPointsToCoupon(data) )
         },
         
        resetReducerState:async()=>{
          await dispatch({type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.LOADING})
        
          await  dispatch({type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.NEW})
        
        }
    }
};
const ExchangeRatesPointsToCouponContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExchangeRatesPointsToCoupon)

export default ExchangeRatesPointsToCouponContainer;