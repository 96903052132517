import React, { Component } from "react";
import CustomDropDownInput from "../../Inputs/CustomDropDownInput/CustomDropDownInput";
// import {ref_table} from 'RefRecordsConstants'
import {ref_table} from 'RefRecordsConstants'

const data =[
  {table_id:ref_table.tbl_businesses, table_name:"tbl_businesses"},
  {table_id:ref_table.tbl_exchange_rates, table_name:"tbl_exchange_rates"},
  {table_id:ref_table.tbl_coupon_contracts, table_name:"tbl_coupon_contracts"},
  {table_id:ref_table.tbl_coupon_quotas, table_name:"tbl_coupon_quotas"},
  {table_id:ref_table.tbl_promotional_points_log, table_name:"tbl_promotional_points_log"}
  // {table_id:ref_table.tbl_points, table_name:"tbl_points"}
]
class FilterTable extends Component {
  render() {
    const {
      inputValue,
      flag,
      errorMsg,
      onChangeEvent,
      FilterTable
    } = this.props;
    console.log('Filter Table:',FilterTable )
    return (
      <div className="vcl_div vcl_div2">
        <p className="vcl_p1">
          Filter: <span className="vcl_sp1">Table</span>
        </p>
        <CustomDropDownInput
          FirstName="Select"
          id="table_name"
          data={data}
          value="table_name"
          name="table_name"
          inputValue={inputValue}
          flag={flag}
          errorMsg={errorMsg}
          onChangeEvent={onChangeEvent}
        />
      </div>
    );
  }
}

export default FilterTable;
