export const FilterTableActions= {
	
	
	FilterTable_Get :{
		NEW:"FilterTable_Get_NEW",
		SUCCESS:"FilterTable_Get_SUCCESS",
		FAILED:"FilterTable_Get_FALIURE",
		LOADING:"FilterTable_Get_LOADING",
		NOT_AUTHORIZED: "FilterTable_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
	,
	FilterTable_Post :{
		NEW:"FilterTable_Post_NEW",
		SUCCESS:"FilterTable_Post_SUCCESS",
		FAILED:"FilterTable_Post_FALIURE",
		LOADING:"FilterTable_Post_LOADING",
		NOT_AUTHORIZED: "FilterTable_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const FilterTableStatus ={

	FilterTable_Get :{
		NEW:"FilterTable_Get_NEW",
		SUCCESS:"FilterTable_Get_SUCCESS",
		FAILED:"FilterTable_Get_FALIURE",
		LOADING:"FilterTable_Get_LOADING",
		NOT_AUTHORIZED: "FilterTable_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	FilterTable_Post :{
		NEW:"FilterTable_Post_NEW",
		SUCCESS:"FilterTable_Post_SUCCESS",
		FAILED:"FilterTable_Post_FALIURE",
		LOADING:"FilterTable_Post_LOADING",
		NOT_AUTHORIZED: "FilterTable_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
