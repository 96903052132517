import { connect } from "react-redux";
import ReferenceDataList_View from "./ReferenceDataList_View";
import { ReferenceDataListServer } from "./server";
import { ReferenceDataListActions } from "./ReferenceDataList_Constants";
const mapStateToProps = state => {
  console.log(state);
  return {
    ReferenceDataList_Get_status:
      state.ReferenceDataListReducer.ReferenceDataList_Status,
    error:state.ReferenceDataListReducer.error,
    ReferenceDataList:state.ReferenceDataListReducer.ReferenceDataListDetails
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getReferenceDataList: (data) => {
      dispatch(ReferenceDataListServer.getReferenceDataList(data));
    },
    resetReducerState:()=>{
      dispatch({type:ReferenceDataListActions.ReferenceDataList_Get.NEW})
    }
  };
};
const ReferenceDataListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferenceDataList_View);

export default ReferenceDataListContainer;
