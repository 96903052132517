//******************Exchange Rate Server Calls******************;

import { ViewChangeLogsTableActions } from "./ViewChangeLogsTableConstants";
import store from "../../../Store/store";
import { ROOT_URL,getCookie } from "../../../Config/Config";

export const ViewChangeLogsTableServer = {
  getViewChangeLogsTable: getViewChangeLogsTable,
  getViewPromotionalLogsTable: getViewPromotionalLogsTable
};

//******************Get ViewChangeLogsTables******************;

function getViewChangeLogsTable(data) {
  const res = fetch(ROOT_URL + "/api/changeLog/getChangeLogOfField", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body:JSON.stringify(data.getTableData)
  })
    .then(res => {
      res
        .json()
        .then(response => {
          console.log("ViewChangeLogsTable:", response);
          data.setLogData(response.data)
          store.dispatch({
            type: ViewChangeLogsTableActions.ViewChangeLogsTable_Get.SUCCESS,
            payload: response.data
          });
        })
        .catch(err => {
          console.log("ViewChangeLogsTableError:", err);
          store.dispatch({
            type: ViewChangeLogsTableActions.ViewChangeLogsTable_Get.FAILED
          });
        });
    })
    .catch(err => {
      console.log("ViewChangeLogsTableError:", err);
      store.dispatch({
        type: ViewChangeLogsTableActions.ViewChangeLogsTable_Get.FAILED
      });
    });

  return { type: ViewChangeLogsTableActions.ViewChangeLogsTable_Get.LOADING };
}


function getViewPromotionalLogsTable(data) {
  const res = fetch(ROOT_URL + "/api/changeLog/getPromotionalLogField", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body:JSON.stringify(data.getTableData)
  })
    .then(res => {
      res
        .json()
        .then(response => {
          console.log("ViewChangeLogsTable:", response.data);
          data.setLogData(response.data)
          store.dispatch({
            type: ViewChangeLogsTableActions.ViewChangeLogsTable_Get.SUCCESS,
            payload: response.data
          });
        })
        .catch(err => {
          console.log("ViewChangeLogsTableError:", err);
          store.dispatch({
            type: ViewChangeLogsTableActions.ViewChangeLogsTable_Get.FAILED
          });
        });
    })
    .catch(err => {
      console.log("ViewChangeLogsTableError:", err);
      store.dispatch({
        type: ViewChangeLogsTableActions.ViewChangeLogsTable_Get.FAILED
      });
    });

  return { type: ViewChangeLogsTableActions.ViewChangeLogsTable_Get.LOADING };
}
