import React, { Component } from "react";
import { withRouter } from "react-router-dom";
//import { Link } from "react-router-dom";
import "./ExchangeRatesDollarToPoints.css";
import EXCHANGERATESDOLLARTOPOINTS_VIEW from "./ExchangeRatesDollarToPoints_View"
import ExchangeRatesDateRanges from "../ExchangeRatesDateRanges/ExchangeRatesDateRanges";

//import Dateformate from 'datefor'
class ExchangeRatesDollarToPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ViewMode: false, // this state is used to make all the fields non-editable
      minFromDate: new Date(), 
      DeleteMode: false,// used to show the delete button when selecting last exchange rate
      FromDate: "",
      EditActiveDate: false,// this is used to make last active exchange rate editable
      RecentlySaved: false,
      toDateReadOnly:false,
      DateRanges:[]
    };
    props.resetReducerState()
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  setToDateReadOnly = (mode) =>{
    this.setState({
      toDateReadOnly:mode
    })
  }

  setDateRange = data => {
    this.state.DateRanges = data
  };
  
  setRecentlySaved = async() => {

   await this.setState({
      RecentlySaved: !this.state.RecentlySaved
    })
     this.setState({
      RecentlySaved: !this.state.RecentlySaved
    })
  }

  setEditActiveDate = (edit) => {
    this.setState({
      EditActiveDate: edit
    })
  }
  setFromDate = (date) => {
    var nextDate = new Date(date)
    this.setState({
      FromDate: nextDate.setDate(nextDate.getDate() + 1)
    })
  }
  setDeleteMode = (mode) => {
    this.setState({
      DeleteMode: mode
    })
  }
  setMinFromDate = (date) => {
    var nextDate = new Date(date)
    this.setState({
      minFromDate: nextDate.setDate(nextDate.getDate() + 1)
    })
  }

  homeHandler = () => {
    this.props.history.push({
      pathname: "/Admin"
    });
  };

  setViewMode = (view) => {
    this.setState({
      ViewMode: view
    })
  }
  // todo: Render ..........................

  render() {
    return (
      <div className="erdp_container">
        <div className="erdp_header">
          <h1>Exchange Rates - Dollar to Points</h1>
        </div>
        <div className="erdp_content_container">
          {!this.state.RecentlySaved ?
            <ExchangeRatesDateRanges
              getExchangeRatesDollarToPoints={this.props.getExchangeRatesDollarToPoints}
              resetReducerState={this.props.resetReducerState}
              setMinFromDate={this.setMinFromDate}
              setViewMode={this.setViewMode}
              DollarsToPoints={true}
              setDeleteMode={this.setDeleteMode}
              setFromDate={this.setFromDate}
              setEditActiveDate={this.setEditActiveDate}
              setToDateReadOnly={this.setToDateReadOnly}
              setActiveReadOnly={this.setActiveReadOnly}
              setDateRange={this.setDateRange}
            /> : <h1>Loading</h1>
          }
        </div>
        <div style={this.state.FromDate ==""?{opacity:0.3, pointerEvents:"none"}:{}}>

        <EXCHANGERATESDOLLARTOPOINTS_VIEW ExchangeRatesDollarToPoints_post_status={this.props.ExchangeRatesDollarToPoints_post_status}
          ViewMode={this.state.ViewMode}
          saveExchangeRatesDollarToPoints={this.props.saveExchangeRatesDollarToPoints}
          updateExchangeRatesDollarToPoints={this.props.updateExchangeRatesDollarToPoints}
          ExchangeRatesDollarToPoints={this.props.ExchangeRatesDollarToPoints}
          resetReducerState={this.props.resetReducerState}
          minFromDate={this.state.minFromDate}
          DeleteMode={this.state.DeleteMode}
          FromDate={this.state.FromDate}
          setRecentlySaved={this.setRecentlySaved}
          toDateReadOnly={this.state.toDateReadOnly}
          EditActiveDate={this.state.EditActiveDate}
          DateRanges = {this.state.DateRanges}
          ExchangeRatesDollarToPoints_Message={this.props.ExchangeRatesDollarToPoints_Message}
        />
        </div>
      </div>

    );
  }
}

export default withRouter(ExchangeRatesDollarToPoints);
