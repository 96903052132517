import {
    ReferenceDataListStatus,
    ReferenceDataListActions
  } from "./ReferenceDataList_Constants";
  
  const ReferenceDataList_initialState = {
    ReferenceDataList_Status: ReferenceDataListStatus.ReferenceDataList_Get.NEW,
    error: "",
    message:"",
    ReferenceDataListDetails: [],
    RefrenceDataFields: [],
  };
  export default function(state = ReferenceDataList_initialState, action) {
    switch (action.type) {
      case ReferenceDataListActions.ReferenceDataList_Get.LOADING:
        return {
          ...state,
          ReferenceDataList_Status:
            ReferenceDataListStatus.ReferenceDataList_Get.LOADING
        };
      case ReferenceDataListActions.ReferenceDataList_Get.NEW:
        return {
          ...state,
          ReferenceDataList_Status:
            ReferenceDataListStatus.ReferenceDataList_Get.NEW,
          ReferenceDataListDetails: [],
          RefrenceDataFields: [],
        };
      case ReferenceDataListActions.ReferenceDataList_Get.SUCCESS:
        return {
          ...state,
          ReferenceDataList_Status:
            ReferenceDataListStatus.ReferenceDataList_Get.SUCCESS,
          ReferenceDataListDetails: action.payload.data, 
          RefrenceDataFields:action.payload.FieldsData
        };
        case ReferenceDataListActions.ReferenceDataList_Get.ADD_SUCCESS:
          // alert(JSON.stringify(action.payload))
          // state.ReferenceDataListDetails  state.ReferenceDataListDetails.push(action.payload)
        return {
          ...state,ReferenceDataListDetails:[...state.ReferenceDataListDetails, action.payload],
          // ReferenceDataList_Status: ReferenceDataListStatus.ReferenceDataList_Get.SUCCESS,
        };
      case ReferenceDataListActions.ReferenceDataList_Get.FAILED:
        return {
          ...state,
          ReferenceDataList_Status:
            ReferenceDataListStatus.ReferenceDataList_Get.FAILED, error:action.payload
        };
  
      default:
        return {
          ...state,
          
        };
    }
  }
  