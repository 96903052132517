
//******************Exchange Rate Server Calls******************;

import {ExchangeRate_Actions} from './constants';
import store from '../../Store/store'
import {ROOT_URL,getCookie } from '../../Config/Config';


export const ExchangeRateServer = {
    getExchangeRates:getExchangeRates,
    saveExchangeRate:saveExchangeRate,
  updateRecord:updateRecord
};

//******************Insert ExchangeRates******************;

function saveExchangeRate(data){
      const request=fetch(ROOT_URL+'/api/exchangeRate/postExchangeRate', {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(data=>{
             if(response.status == "200"){
            store.dispatch({type:ExchangeRate_Actions.ExchangeRate_Get.SUCCESS,payload:data.data,status:"here"});
            return ;
            }else
            {
              store.dispatch({type:ExchangeRate_Actions.ExchangeRate_Get.FAILED,error:response.error});
              return ;
            }
           });
          }).catch((error) => {
           store.dispatch({type:ExchangeRate_Actions.ExchangeRate_Get.OFFLINE});
            })
      return {type:ExchangeRate_Actions.ExchangeRate_Get.NEW}
};
  
//******************Get ExchangeRates******************;

function getExchangeRates(){
    const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate', {
        method: 'GET',
        headers: {'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8', 'authorization':getCookie("admin_token")},
        mode: 'cors',
        }).then((response)=>{
         response.json().then(data=>{
          console.log("data:......" , data.exchangeRates)
           if(response.status == "200"){
          store.dispatch({type:ExchangeRate_Actions.ExchangeRate_Get.SUCCESS,payload:data.exchangeRates});
          return ;
          }else
         {
            store.dispatch({type:ExchangeRate_Actions.ExchangeRate_Get.FAILED,error:response.error});
            return ;
            }    
         });
        }).catch((error) => {
         store.dispatch({type:ExchangeRate_Actions.ExchangeRate_Get.OFFLINE});
          })
    return {type:ExchangeRate_Actions.ExchangeRate_Get.NEW}
};



//******************Update ExchangeRate******************;

  
function updateRecord(data){
  console.log("dfgdfgdfgdsgdf",data);
      const request=fetch(ROOT_URL+'/api/updateRecord/'+data._id, {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
          console.log('********'+response);
           response.json().then(data=>{
            console.log("data:......" , data.saveMedicineStatus)
             if(data.saveMedicineStatus == "SUCCESS"){
            store.dispatch({type:ExchangeRate_Actions.ExchangeRate_Get.SUCCESS,payload:data.allMedicineList,payload3:data.saveMedicineMessage});
            return ;
            }else
            if(data.saveMedicineStatus=='FAILIURE'){
              store.dispatch({type:ExchangeRate_Actions.ExchangeRate_Get.FAILED});
              return ;
              }    
           });
          }).catch((error) => {
           store.dispatch({type:ExchangeRate_Actions.ExchangeRate_Get.OFFLINE});
            })
      return {type:ExchangeRate_Actions.ExchangeRate_Get.NEW}
};
  