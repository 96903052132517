import { connect } from "react-redux";
import ExchangeRatesDollarToPointsView from "./ExchangeRatesDollarToPoints";
import {ExchangeRatesDollarToPointsServer} from "./server"
import {ExchangeRatesDollarToPointsActions} from './ExchangeRatesDollarToPointsconstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        ExchangeRatesDollarToPoints_post_status:state.ExchangeRatesDollarToPoints_Post_Reducer.ExchangeRatesDollarToPoints_Status,
        ExchangeRatesDollarToPoints:state.ExchangeRatesDollarToPoints_Post_Reducer.ExchangeRatesDollarToPointsDetails,
        //RecentlySaved:state.ExchangeRatesDollarToPoints_Post_Reducer.RecentlySaved
        ExchangeRatesDollarToPoints_Message:state.ExchangeRatesDollarToPoints_Post_Reducer.ExchangeRatesDollarToPoints_Message
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        saveExchangeRatesDollarToPoints: (data) => {

           dispatch( ExchangeRatesDollarToPointsServer.saveExchangeRatesDollarToPoints(data) )
        },
        getExchangeRatesDollarToPoints: (data) => {

            dispatch( ExchangeRatesDollarToPointsServer.getExchangeRatesDollarToPoints(data) )
         },

         updateExchangeRatesDollarToPoints: (data) => {

            dispatch( ExchangeRatesDollarToPointsServer.updateExchangeRatesDollarToPoints(data) )
         },
         
        resetReducerState:async ()=>{
                await dispatch({type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.LOADING})
                 dispatch({type:ExchangeRatesDollarToPointsActions.ExchangeRatesDollarToPoints_Post.NEW})
        }
    }
};
const ExchangeRatesDollarToPointsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExchangeRatesDollarToPointsView)

export default ExchangeRatesDollarToPointsContainer;