import React, { Component, Fragment } from "react";

import "./DropDownInput.css";

const data1 = [
  { value: "", name: "" },
  { value: "value", name: "name" },
  { value: "sar_connor", name: "john_connor sasdsdsd" },
  { value: "john_connor", name: "john_connor" },
  { value: "john_connor", name: "john_connor" }
];
export class DropDownInput extends Component {
  constructor() {
    super();
    this.state = { selectedValue: "",
  defaultValue:"salman" };

    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    // this.props.onChangeEvent(event.target.value);
  }
  render() {
    const {
      data = data1,
      errorMsg = "errorMsg",
      flag = false,
      disabled = false,
      id,
      onChange,
      FirstValue="",
      FirstName = FirstValue,
    } = this.props;
    return (
      <Fragment>
        <div className="drop_down_input_container">
          <select
            id={id}
            className="drop_down_input"
            disabled={disabled}
            onChange={onChange}
          >
            <option value={FirstValue}  disabled hidden>{FirstName}</option>
            {/* <option value={FirstValue} disabled selected hidden>{FirstName}</option> */}
            {data.map((item,index) => {
              return (
                <option value={item.value} key={item[id] ? item[id]: index} >
                  {item.name}
                </option>
              );
            })}
          </select>
          <i className="material-icons drop_down_icon">expand_more</i>
        </div>
        <p
          className="drop_down_input_error_text"
          style={{ visibility: flag ? "visible" : "hidden" }}
        >
          {errorMsg}
        </p>
      </Fragment>
    );
  }
}

export default DropDownInput;
