import React, { Component } from "react";
import "./ExchangeRatesDollarToPoints.css";
import DatePicker from "../Inputs/DatePicker";
import dateFormat from "dateformat";

import InputDollar from "../Inputs/InputDollar/InputDollar";
import DeleteExchangeRatesContainer from "./DeleteExchangeRates/DeleteExchangeRatesContainer";
import { Modal } from "../Cards/UploadBannerCard/DeleteModal";

class ExChangeRatesDollarToPointsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      busDollarValue: "",
      busDollarValueErrorMsg: "Only numeric values are allowed",
      busDollarValueFlag: false,
      busPointValue: "",
      busPointValueErrorMsg: "Only numeric values are allowed",
      busPointValueFlag: false,
      lightRailDollarValue: "",
      lightRailDollarValueErrorMsg: "Only numeric values are allowed",
      lightRailDollarValueFlag: false,
      lightRailPointValue: "",
      lightRailPointValueErrorMsg: "Only numeric values are allowed",
      lightRailPointValueFlag: false,
      trainDollarValue: "",
      trainDollarValueErrorMsg: "Only numeric values are allowed",
      trainDollarValueFlag: false,
      trainPointValue: "",
      trainPointValueErrorMsg: "Only numeric values are allowed",
      trainPointValueFlag: false,
      fromDate: "",
      fromDateErrorMsg: "Field Required",
      fromDateFlag: false,
      EditMode: false,
      CreateMode: true,
      toDate: "",
      deleteDate: false,
      toDateErrorMsg: "Field Required",
      toDateFlag: false,
      fromDateReadonly: false,
      ToDateRange:"",
      MintoDate: new Date(),

      AccessLinkDollarValue: "",
      AccessLinkDollarValueErrorMsg: "Only numeric values are allowed",
      AccessLinkDollarValueFlag: false,
      AccessLinkPointValue: "",
      AccessLinkPointValueErrorMsg: "Only numeric values are allowed",
      AccessLinkPointValueFlag: false,

    };
  }
  hideModal = () => {
    this.setState({
      deleteDate: false,
    });
  };

  showModal = () => {
    // alert("test")
    if(this.state.toDate && this.state.toDate !="")
    this.setState({
      deleteDate: true,
    });
  };
  //   simpleInputHandler = e => {
  //     this.setState({ [e.target.name]: e.target.value });
  //   };

  // todo: Bus From (Dollar) onChange Function ...................
  UNSAFE_componentWillMount() {
    // alert(this.props.DateRanges.length)
    //DateRanges = {this.props.DateRanges}
    if (this.props.setRecentlySaved) {
      //  this.props.setRecentlySaved();
    }

    if (this.props.ExchangeRatesDollarToPoints) {
      this.state.busDollarValue = this.props.ExchangeRatesDollarToPoints[0].from_quantity;
      this.state.busPointValue = this.props.ExchangeRatesDollarToPoints[0].to_quantity;
      this.state.lightRailDollarValue = this.props.ExchangeRatesDollarToPoints[1].from_quantity;
      this.state.lightRailPointValue = this.props.ExchangeRatesDollarToPoints[1].to_quantity;
      this.state.trainDollarValue = this.props.ExchangeRatesDollarToPoints[2].from_quantity;
      this.state.trainPointValue = this.props.ExchangeRatesDollarToPoints[2].to_quantity;
      this.state.AccessLinkDollarValue = this.props.ExchangeRatesDollarToPoints[3].from_quantity;
      this.state.AccessLinkPointValue = this.props.ExchangeRatesDollarToPoints[3].to_quantity;
      this.state.fromDate = new Date(
        this.props.ExchangeRatesDollarToPoints[0].from_date
      );

      if (new Date().setHours(0, 0, 0, 0) <= this.state.fromDate)
        this.state.MintoDate = new Date(
          this.props.ExchangeRatesDollarToPoints[0].from_date
        ).setDate(this.state.fromDate.getDate() + 1);
      else this.state.MintoDate = new Date().setDate(new Date().getDate() + 1);
          this.state.ToDateRange=this.props.ExchangeRatesDollarToPoints[0].to_date
      this.state.fromDateReadonly = true;
      if (this.props.ExchangeRatesDollarToPoints[0].to_date)
        this.state.toDate = new Date(
          this.props.ExchangeRatesDollarToPoints[0].to_date
        );
    } else if (this.props.FromDate) {
      this.state.fromDate = new Date(this.props.FromDate);
      this.state.fromDateReadonly = true;
      if (this.state.fromDate > new Date()) {
        //  alert("test")
        this.state.MintoDate = new Date(this.props.FromDate).setDate(
          this.state.fromDate.getDate() + 1
        );
        // this.state.MintoDate.setDate(this.state.fromDate.getDate() +1)
      } else this.state.MintoDate.setDate(this.state.MintoDate.getDate() + 1);
    }
  }
  busDollarValueHandler = (e) => {
    //  alert(e.target.value)
    const reg = /^\d*\.?\d*$/;
    // const reg = /^(?=.*[1-9])\d*(?:\.\d{2})?$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ busDollarValueFlag: false });
    } else {
      this.setState({
        busDollarValueFlag: true,
        busDollarValueErrorMsg: "Only numeric values are allowed",
      });
    }

    const regex = /^[0]+\.?[0]*$/;

    const RegexValue = regex.test(e.target.value);
    if (RegexValue) {
      this.setState({
        busDollarValueFlag: true,
        busDollarValueErrorMsg: "Zero value not allow",
      });
    }

    this.setState({ busDollarValue: e.target.value });
  };
  // todo: Bus To (Points) onChange Function ...................

  busPointValueHandler = (e) => {
    const reg = /^\d*\.?\d*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ busPointValueFlag: false });
    } else {
      this.setState({
        busPointValueFlag: true,
        busPointValueErrorMsg: "Only numeric values are allowed",
      });
    }

    const regex = /^[0]+\.?[0]*$/;

    const RegexValue = regex.test(e.target.value);
    if (RegexValue) {
      this.setState({
        busPointValueFlag: true,
        busPointValueErrorMsg: "Zero value not allow",
      });
    }

    this.setState({ busPointValue: e.target.value });
  };
  // todo: Light Rail From (Dollar) onChange Function ...................

  lightRailDollarValueHandler = (e) => {
    const reg = /^\d*\.?\d*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ lightRailDollarValueFlag: false });
    } else {
      this.setState({
        lightRailDollarValueFlag: true,
        lightRailDollarValueErrorMsg: "Only numeric values are allowed",
      });
    }

    const regex = /^[0]+\.?[0]*$/;

    const RegexValue = regex.test(e.target.value);
    if (RegexValue) {
      this.setState({
        lightRailDollarValueFlag: true,
        lightRailDollarValueErrorMsg: "Zero value not allow",
      });
    }

    this.setState({ lightRailDollarValue: e.target.value });
  };
  // todo: Light Rail To (Points) onChange Function ...................

  lightRailPointValueHandler = (e) => {
    const reg = /^\d*\.?\d*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ lightRailPointValueFlag: false });
    } else {
      this.setState({
        lightRailPointValueFlag: true,
        lightRailPointValueErrorMsg: "Only numeric values are allowed",
      });
    }

    const regex = /^[0]+\.?[0]*$/;

    const RegexValue = regex.test(e.target.value);
    if (RegexValue) {
      this.setState({
        lightRailPointValueFlag: true,
        lightRailPointValueErrorMsg: "Zero value not allow",
      });
    }

    this.setState({ lightRailPointValue: e.target.value });
  };
  // todo: Train From (Dollar) onChange Function ...................

  trainDollarValueHandler = (e) => {
    const reg = /^\d*\.?\d*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ trainDollarValueFlag: false });
    } else {
      this.setState({
        trainDollarValueFlag: true,
        trainDollarValueErrorMsg: "Only numeric values are allowed",
      });
    }

    const regex = /^[0]+\.?[0]*$/;

    const RegexValue = regex.test(e.target.value);
    if (RegexValue) {
      this.setState({
        trainDollarValueFlag: true,
        trainDollarValueErrorMsg: "Zero value not allow",
      });
    }

    this.setState({ trainDollarValue: e.target.value });
  };
  // todo: Train To (Points) onChange Function ...................

  trainPointValueHandler = (e) => {
    const reg = /^\d*\.?\d*$/;

    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ trainPointValueFlag: false });
    } else {
      this.setState({
        trainPointValueFlag: true,
        trainPointValueErrorMsg: "Only numeric values are allowed",
      });
    }

    const regex = /^[0]+\.?[0]*$/;

    const RegexValue = regex.test(e.target.value);
    if (RegexValue) {
      this.setState({
        trainPointValueFlag: true,
        trainPointValueErrorMsg: "Zero value not allow",
      });
    }

    this.setState({ trainPointValue: e.target.value });
  };

  //////////////////
  // todo: AccessLink From (Dollar) onChange Function ...................

  AccessLinkDollarValueHandler = (e) => {
    const reg = /^\d*\.?\d*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ AccessLinkDollarValueFlag: false });
    } else {
      this.setState({
        AccessLinkDollarValueFlag: true,
        AccessLinkDollarValueErrorMsg: "Only numeric values are allowed",
      });
    }

    const regex = /^[0]+\.?[0]*$/;

    const RegexValue = regex.test(e.target.value);
    if (RegexValue) {
      this.setState({
        AccessLinkDollarValueFlag: true,
        AccessLinkDollarValueErrorMsg: "Zero value not allow",
      });
    }

    this.setState({ AccessLinkDollarValue: e.target.value });
  };
  // todo: Train To (Points) onChange Function ...................

  AccessLinkPointValueHandler = (e) => {
    const reg = /^\d*\.?\d*$/;

    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ AccessLinkPointValueFlag: false });
    } else {
      this.setState({
        AccessLinkPointValueFlag: true,
        AccessLinkPointValueErrorMsg: "Only numeric values are allowed",
      });
    }

    const regex = /^[0]+\.?[0]*$/;

    const RegexValue = regex.test(e.target.value);
    if (RegexValue) {
      this.setState({
        AccessLinkPointValueFlag: true,
        AccessLinkPointValueErrorMsg: "Zero value not allow",
      });
    }

    this.setState({ AccessLinkPointValue: e.target.value });
  };
  /////////////////

  // todo: From Date onChange Function ...................

  fromDateHandler = (e) => {
    // this.setState({ fromDate: e.target.value, fromDateFlag: false });
    // alert(dateFormat(new Date(e).setDate(new Date(e).getDate() + 1),'yyyy-mm-dd'))
    this.setState({
      fromDate: e,
      fromDateFlag: false,
      toDate: "",
      MintoDate: new Date(e).setDate(new Date(e).getDate() + 1),
    });
  };

  // todo: To Date onChange Function ...................

  toDateHandler = (e) => {
    //alert(dateFormat(e, 'yyyy-mm-dd'))
    this.setState({ toDate: e, toDateFlag: false });
    // this.setState({ toDate: e.target.value, toDateFlag: false });
  };

  // todo: Save Button onClick Function ...................

  saveHandler = () => {
    //   alert('its working')
    if (!this.props.ViewMode)
      if (this.state.busDollarValueFlag || this.state.busDollarValue === "") {
        this.setState(
          {
            busDollarValueErrorMsg:
              this.state.busDollarValueFlag &&
              this.state.busDollarValue.length > 0
                ? this.state.busDollarValueErrorMsg
                : "Field Required",
            busDollarValueFlag: true,
          },
          () => {
            console.log(
              "busDollarValue",
              this.state.busDollarValue,
              "busDollarValueFlag",
              this.state.busDollarValueFlag
            );
          }
        );
      } else if (
        this.state.busPointValueFlag ||
        this.state.busPointValue === ""
      ) {
        this.setState({
          busPointValueErrorMsg:
            this.state.busPointValueFlag && this.state.busPointValue.length > 0
              ? this.state.busPointValueErrorMsg
              : "Field Required",
          busPointValueFlag: true,
        });
      } else if (
        this.state.lightRailDollarValueFlag ||
        this.state.lightRailDollarValue === ""
      ) {
        this.setState({
          lightRailDollarValueErrorMsg:
            this.state.lightRailDollarValueFlag &&
            this.state.lightRailDollarValue.length > 0
              ? this.state.lightRailDollarValueErrorMsg
              : "Field Required",
          lightRailDollarValueFlag: true,
        });
      } else if (
        this.state.lightRailPointValueFlag ||
        this.state.lightRailPointValue === ""
      ) {
        this.setState({
          lightRailPointValueErrorMsg:
            this.state.lightRailPointValueFlag &&
            this.state.lightRailPointValue.length > 0
              ? this.state.lightRailPointValueErrorMsg
              : "Field Required",
          lightRailPointValueFlag: true,
        });
      } else if (
        this.state.trainDollarValueFlag ||
        this.state.trainDollarValue === ""
      ) {
        this.setState({
          trainDollarValueErrorMsg:
            this.state.trainDollarValueFlag &&
            this.state.trainDollarValue.length > 0
              ? this.state.trainDollarValueErrorMsg
              : "Field Required",
          trainDollarValueFlag: true,
        });
      } else if (
        this.state.trainPointValueFlag ||
        this.state.trainPointValue === ""
      ) {
        this.setState({
          trainPointValueErrorMsg:
            this.state.trainPointValueFlag &&
            this.state.trainPointValue.length > 0
              ? this.state.trainPointValueErrorMsg
              : "Field Required",
          trainPointValueFlag: true,
        });
      } else if (
        this.state.AccessLinkDollarValueFlag ||
        this.state.AccessLinkDollarValue === ""
      ) {
        this.setState({
          AccessLinkDollarValueErrorMsg:
            this.state.AccessLinkDollarValueFlag &&
            this.state.AccessLinkDollarValue.length > 0
              ? this.state.AccessLinkDollarValueErrorMsg
              : "Field Required",
              AccessLinkDollarValueFlag: true,
        });
      }else if (
        this.state.AccessLinkPointValueFlag ||
        this.state.AccessLinkPointValue === ""
      ) {
        this.setState({
          AccessLinkPointValueErrorMsg:
            this.state.AccessLinkPointValueFlag &&
            this.state.AccessLinkPointValue.length > 0
              ? this.state.AccessLinkPointValueErrorMsg
              : "Field Required",
              AccessLinkPointValueFlag: true,
        });
      } else if (this.state.fromDate === "") {
        this.setState({ fromDateFlag: true });
      }
      // else if (this.state.toDate === "") {
      //   this.setState({ toDateFlag: true });
      // }
      else {
        var data = [
          {
            // from_unit:1,
            // to_unit:2,
            //exchange_rates_id: this.props.ExchangeRatesDollarToPoints[0].exchange_rates_id,
            from_quantity: parseFloat(this.state.busDollarValue),
            to_quantity: parseFloat(this.state.busPointValue),
            from_date: dateFormat(this.state.fromDate, "yyyy-mm-dd"),
            to_date: null,
            // to_date: dateFormat(this.state.toDate, "yyyy-mm-dd")
            // mode_of_transportation_id:1,
            // last_updated:new Date()
          },
          {
            // from_unit:1,
            // to_unit:2,
            //exchange_rates_id: this.props.ExchangeRatesDollarToPoints[1].exchange_rates_id,
            from_quantity: parseFloat(this.state.lightRailDollarValue),
            to_quantity: parseFloat(this.state.lightRailPointValue),
            from_date: dateFormat(this.state.fromDate, "yyyy-mm-dd"),
            to_date: null,
            // to_date: dateFormat(this.state.toDate, "yyyy-mm-dd")
            // mode_of_transportation_id:2,
            //last_updated:new Date()
          },
          {
            // from_unit:1,
            // to_unit:2,
            // exchange_rates_id: this.props.ExchangeRatesDollarToPoints[2].exchange_rates_id,
            from_quantity: parseFloat(this.state.trainDollarValue),
            to_quantity: parseFloat(this.state.trainPointValue),
            from_date: dateFormat(this.state.fromDate, "yyyy-mm-dd"),
            to_date: null,
            // mode_of_transportation_id:3,
            //  last_updated:new Date()
          },
          {
            // from_unit:1,
            // to_unit:2,
            // exchange_rates_id: this.props.ExchangeRatesDollarToPoints[2].exchange_rates_id,
            from_quantity: parseFloat(this.state.AccessLinkDollarValue),
            to_quantity: parseFloat(this.state.AccessLinkPointValue),
            from_date: dateFormat(this.state.fromDate, "yyyy-mm-dd"),
            to_date: null,
            // mode_of_transportation_id:3,
            //  last_updated:new Date()
          },
        ];
        if (this.state.toDate !== "") {
          data[0].to_date = dateFormat(this.state.toDate, "yyyy-mm-dd");
          data[1].to_date = dateFormat(this.state.toDate, "yyyy-mm-dd");
          data[2].to_date = dateFormat(this.state.toDate, "yyyy-mm-dd");
          data[3].to_date = dateFormat(this.state.toDate, "yyyy-mm-dd");
        }
        if (this.props.ExchangeRatesDollarToPoints) {
          var flag = false;
          var prevData = this.props.ExchangeRatesDollarToPoints;
          data[0] = {
            ...data[0],
            exchange_rates_id: prevData[0].exchange_rates_id,
          };
          data[1] = {
            ...data[1],
            exchange_rates_id: prevData[1].exchange_rates_id,
          };
          data[2] = {
            ...data[2],
            exchange_rates_id: prevData[2].exchange_rates_id,
          };
          data[3] = {
            ...data[3],
            exchange_rates_id: prevData[3].exchange_rates_id,
          };
          var ids = [
            prevData[0].exchange_rates_id,
            prevData[1].exchange_rates_id,
            prevData[2].exchange_rates_id,
            prevData[3].exchange_rates_id,
          ];
          if (prevData[0].from_quantity != data[0].from_quantity) flag = true;
          if (prevData[0].to_quantity != data[0].to_quantity) flag = true;
          if (dateFormat(prevData[0].to_date, "yyyy-mm-dd") != data[0].to_date)
            flag = true;
          if (prevData[1].from_quantity != data[1].from_quantity) flag = true;
          if (prevData[1].to_quantity != data[1].to_quantity) flag = true;
          if (prevData[2].from_quantity != data[2].from_quantity) flag = true;
          if (prevData[2].to_quantity != data[2].to_quantity) flag = true;
          if (prevData[3].from_quantity != data[3].from_quantity) flag = true;
          if (prevData[3].to_quantity != data[3].to_quantity) flag = true;
          if (flag)
            this.props.saveExchangeRatesDollarToPoints({
              data: data,
              ids: ids,
              prevData: prevData,
            });
        } else this.props.saveExchangeRatesDollarToPoints(data);
        return true;
      }
  };
  // todo: homeHandler onClick function ..........................

  homeHandler = () => {
    this.props.history.push({
      pathname: "/Admin",
    });
  };
  deleteHandler = () => {
    alert("deleteHandler Works");
  };
  setCreateMode = (mode) => {
    this.setState({
      CreateMode: mode,
    });
  };

  toDateDeleteHandler = () => {
    this.setState({ toDate: "", deleteDate: false });
  };
  // todo: Render ..........................

  render() {
    const date = new Date();
    // const year = date.getFullYear();
    // const month = date.getMonth() + 1;
    // const day = date.getDate();
    // const fromDateMinDate = `${year}-${month}-${day}`;
    // const toDateMinDate = `${year}-${month}-${day + 1}`;
    const {
      busDollarValue,
      busDollarValueErrorMsg,
      busDollarValueFlag,
      busPointValue,
      busPointValueErrorMsg,
      busPointValueFlag,
      lightRailDollarValue,
      lightRailDollarValueErrorMsg,
      lightRailDollarValueFlag,
      lightRailPointValue,
      lightRailPointValueErrorMsg,
      lightRailPointValueFlag,
      trainDollarValue,
      trainDollarValueErrorMsg,
      trainDollarValueFlag,
      trainPointValue,
      trainPointValueErrorMsg,
      trainPointValueFlag,
      fromDate,
      fromDateErrorMsg,
      fromDateFlag,
      toDate,
      toDateErrorMsg,
      toDateFlag,

      AccessLinkDollarValue,
      AccessLinkDollarValueErrorMsg,
      AccessLinkDollarValueFlag,

      AccessLinkPointValue,
      AccessLinkPointValueErrorMsg,
      AccessLinkPointValueFlag
    } = this.state;

    // const newYear = fromDate.substr(0, 4);
    // const newMonth = fromDate.substring(5, 7);
    // const newDay = parseInt(fromDate.substring(8, 10));
    // const toDateMinDate = `${newYear}-${newMonth}-${newDay + 1}`;
    // alert(JSON.stringify(this.props.DateRanges))
    console.log(this.props, "jhsbdjhabsdjha")
    return (
      <div className="erdp_content_container">
        <table>
          <thead>
            <tr>
              <th>Mode of Transportation</th>
              <th>From (Dollars):</th>
              <th>To (Points):</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Bus:</td>
              <td>
                <InputDollar
                  inputValue={busDollarValue}
                  name="busDollarValue"
                  id={this.props.EditActiveDate ? "busDollarValueEdit": "busDollarValue"}
                  errorMsg={busDollarValueErrorMsg}
                  flag={busDollarValueFlag}
                  onChange={this.busDollarValueHandler}
                  disabled={this.props.ViewMode || this.props.EditActiveDate}
                  maxLength="10"
                />
              </td>
              <td>
                <InputDollar
                  labelIcon="star_border"
                  inputValue={busPointValue}
                  // inputValue= {parseFloat(busPointValue).toLocaleString(undefined, {maximumFractionDigits:0}).replace("NaN","")} 
                  name="busPointValue"
                  id={this.props.EditActiveDate ? "busPointValueEdit": "busPointValue"}
                  errorMsg={busPointValueErrorMsg}
                  flag={busPointValueFlag}
                  onChange={this.busPointValueHandler}
                  disabled={this.props.ViewMode || this.props.EditActiveDate}
                  maxLength="10"
                />
              </td>
            </tr>
            <tr>
              <td>Light Rail:</td>
              <td>
                <InputDollar
                  inputValue={lightRailDollarValue}
                  name="lightRailDollarValue"
                  id={this.props.EditActiveDate ? "lightRailDollarValueEdit": "lightRailDollarValue"}
                  errorMsg={lightRailDollarValueErrorMsg}
                  flag={lightRailDollarValueFlag}
                  onChange={this.lightRailDollarValueHandler}
                  disabled={this.props.ViewMode || this.props.EditActiveDate}
                  maxLength="10"
                />
              </td>
              <td>
                <InputDollar
                  labelIcon="star_border"
                  inputValue={lightRailPointValue}
                  // inputValue={parseFloat(lightRailPointValue).toLocaleString(undefined, {maximumFractionDigits:0}).replace("NaN","")}
                  name="lightRailPointValue"
                  id={this.props.EditActiveDate ? "lightRailPointValueEdit": "lightRailPointValue"}
                  errorMsg={lightRailPointValueErrorMsg}
                  flag={lightRailPointValueFlag}
                  onChange={this.lightRailPointValueHandler}
                  disabled={this.props.ViewMode || this.props.EditActiveDate}
                  maxLength="10"
                />
              </td>
            </tr>
            <tr>
              <td>Train:</td>
              <td>
                <InputDollar
                  inputValue={trainDollarValue}
                  name="trainDollarValue"
                  id={this.props.EditActiveDate ? "trainDollarValueEdit": "trainDollarValue"}
                  errorMsg={trainDollarValueErrorMsg}
                  flag={trainDollarValueFlag}
                  onChange={this.trainDollarValueHandler}
                  disabled={this.props.ViewMode || this.props.EditActiveDate}
                  maxLength="10"
                />
              </td>
              <td>
                <InputDollar
                  labelIcon="star_border"
                  inputValue={trainPointValue}
                  // inputValue={parseFloat(trainPointValue).toLocaleString(undefined, {maximumFractionDigits:0}).replace("NaN","")} 
                  name="trainPointValue"
                  id={this.props.EditActiveDate ? "trainPointValueEdit": "trainPointValue"}
                  errorMsg={trainPointValueErrorMsg}
                  flag={trainPointValueFlag}
                  onChange={this.trainPointValueHandler}
                  disabled={this.props.ViewMode || this.props.EditActiveDate}
                  maxLength="10"
                />
              </td>
            </tr>
            <tr>
              <td>Access Link:</td>
              <td>
                <InputDollar
                  inputValue={AccessLinkDollarValue}
                  name="AccessLinkDollarValue"
                  id={this.props.EditActiveDate ? "AccessLinkDollarValueEdit": "AccessLinkDollarValue"}
                  errorMsg={AccessLinkDollarValueErrorMsg}
                  flag={AccessLinkDollarValueFlag}
                  onChange={this.AccessLinkDollarValueHandler}
                  disabled={this.props.ViewMode || this.props.EditActiveDate}
                  maxLength="10"
                />
              </td>
              <td>
                <InputDollar
                  labelIcon="star_border"
                  inputValue={AccessLinkPointValue}
                  // inputValue={parseFloat(trainPointValue).toLocaleString(undefined, {maximumFractionDigits:0}).replace("NaN","")} 
                  name="accessLinkPointValue"
                  id={this.props.EditActiveDate ? "AccessLinkPointsValueTwoEdit": "AccessLinkPointsValueTwo"}
                  errorMsg={AccessLinkPointValueErrorMsg}
                  flag={AccessLinkPointValueFlag}
                  onChange={this.AccessLinkPointValueHandler}
                  disabled={this.props.ViewMode || this.props.EditActiveDate}
                  maxLength="10"
                />
              </td>
            </tr>
            <tr>
              <td>Start Date:</td>
              <td>
                <DatePicker
                  inputValue={fromDate}
                  errorMsg={fromDateErrorMsg}
                  flag={fromDateFlag}
                  disabled={this.props.ViewMode || this.state.fromDateReadonly}
                  minDate={this.props.minFromDate}
                  onChange={this.fromDateHandler}
                  boxClass="box-date-picker si-date-picker"
                  id={this.props.EditActiveDate ? "startDateEdit": "startDate"}
                />
              </td>
              {/* <td><DatePicker minDate={`${date.getFullYear()}-${date.getMonth()}-${date.getDate()+1}`} /></td> */}
            </tr>
            <tr>
              <td>End Date:</td>
              <td>
                {fromDate === "" ? (
                  <DatePicker
                    boxClass="box-date-picker si-date-picker"
                    disabled={true}
                    id={this.props.EditActiveDate ? "endDateEdit": "endDate"}
                  />
                ) : (
                  <DatePicker
                    inputValue={toDate}
                    errorMsg={toDateErrorMsg}
                    flag={toDateFlag}
                    disabled={this.props.ViewMode || this.props.toDateReadOnly}
                    // isClearable={true}
                    minDate={this.state.MintoDate}
                    onChange={this.toDateHandler}
                    boxClass="box-date-picker si-date-picker"
                    id={this.props.EditActiveDate ? "endDateEdit": "endDate"}
                  />
                )}
              </td>
              <td>
                {!this.props.ViewMode &&
                this.props.DateRanges.length > 0 &&
                this.props.ExchangeRatesDollarToPoints &&
                this.props.ExchangeRatesDollarToPoints.length > 0 &&
                this.props.DateRanges[this.props.DateRanges.length - 1]
                  .to_date ==
                  this.props.ExchangeRatesDollarToPoints[0].to_date ? (
                  <i
                    id="deleteLink"
                    className="material-icons erdp-del-icon"
                    onClick={this.showModal}
                  >
                    delete
                  </i>
                ) : !this.props.ExchangeRatesDollarToPoints ? (
                  <i
                    id="deleteLink"
                    className="material-icons erdp-del-icon"
                    onClick={this.showModal}
                  >
                    delete
                  </i>
                ) : null}
              </td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>
                {this.props.DeleteMode ? (
                  <DeleteExchangeRatesContainer
                    className="erdp_btn1 erdp_delete"
                    ExchangeRatesDollarToPoints={
                      this.props.ExchangeRatesDollarToPoints
                    }
                    ToDateRange = {this.state.ToDateRange}
                    fromDate = {this.state.fromDate}

                    setRecentlyDeleted={this.props.setRecentlyDeleted}
                    resetReducerState={this.props.resetReducerState}
                  />
                ) : // <button className="erdp_btn1" onClick={this.deleteHandler}>
                //   Delete
                // </button>
                null}
                {(this.props.DateRanges.length > 0 &&
                  this.props.DateRanges[this.props.DateRanges.length - 1]
                    .to_date) ||
                this.props.ExchangeRatesDollarToPoints ? (
                  !this.props.ViewMode ? (
                    <button id="saveBtn" className="erdp_btn1" onClick={this.saveHandler}>
                      Save
                    </button>
                  ) : null
                ) : this.props.DateRanges.length == 0 ? (
                  <button id="saveBtn" className="erdp_btn1" onClick={this.saveHandler}>
                    Save
                  </button>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
        {this.state.deleteDate ? (
          <Modal
          message={ "Are you sure you want to delete end date: " +
          dateFormat(new Date(this.state.toDate), "mm/dd/yyyy")+" ?"}
            onClose={this.hideModal}
            onConfirm={this.toDateDeleteHandler}
          />
        ) : null}
      </div>
    );
  }
}

export default ExChangeRatesDollarToPointsForm;
