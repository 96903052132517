import React, { Component, Fragment } from "react";
//import './ViewChangeLogsTable.css'
import { ViewChangeLogsTableStatus } from "./ViewChangeLogsTableConstants";
import ViewChangeLogsTable from "./ViewChangeLogsTable";
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar/";
// import Loading from "../../Utils/Loading";
import {ref_table} from 'RefRecordsConstants'
// import RotateLoading from "../../Utils/RotateLoading";

export class ViewChangeLogsTableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mount: false,
      getTableData: {},
      filterMsg:"Please select filters to view the required records"
    };
    props.resetReducerState()
  }

  closeSnackBar = () => {
    this.setState({
      open: false
    });
  };
  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.getTableData) !== JSON.stringify(state.getTableData)) {
      // var data = {
      //   table_name: props.table
      // };

     // alert(JSON.stringify(props.getTableData))
      if (props.tableID == ref_table.tbl_promotional_points_log)
        props.getViewPromotionalLogsTable(props);

      else
        props.getViewChangeLogsTable(props);

      return {
        getTableData: props.getTableData
      };
    } else return null;
  }
  getScreen(status) {
    // alert(status)
    switch (status) {
      case ViewChangeLogsTableStatus.ViewChangeLogsTable_Get.NEW:
        //  alert(JSON.stringify( this.props.ViewChangeLogsTable))
        return (
          <ViewChangeLogsTable
            tableID={this.props.tableID}
            ViewChangeLogsTable={this.props.ViewChangeLogsTable}
          />
        );

      case ViewChangeLogsTableStatus.ViewChangeLogsTable_Get.SUCCESS:
        console.log("ViewChangeLogsTable: ",this.props.ViewChangeLogsTable)
        // this.props.setLogData(this.props.ViewChangeLogsTable)
        if(this.props.ViewChangeLogsTable && this.props.ViewChangeLogsTable.length < 1)
        this.state.filterMsg = "No log records found"
        else
        this.state.filterMsg = "Please select filters to view the required records"
        return (
          <ViewChangeLogsTable
            tableID={this.props.tableID}
            ViewChangeLogsTable={this.props.ViewChangeLogsTable}
            filterMsg = {this.state.filterMsg}
          />
        );
      case ViewChangeLogsTableStatus.ViewChangeLogsTable_Get.FAILED:
        //  alert(JSON.stringify( this.props.ViewChangeLogsTable))
        return (
          <Fragment>
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              //  TransitionComponent={<Slide {...props} direction="up" />}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={
                <span id="message-id">Unable to get change log records</span>
              }
            />
            <ViewChangeLogsTable
              tableID={this.props.tableID}
              ViewChangeLogsTable={this.props.ViewChangeLogsTable}
            />
          </Fragment>
        );

      case ViewChangeLogsTableStatus.ViewChangeLogsTable_Get.LOADING:
        this.state.open = true;
        return (
          // <div style={{ textAlign: "center",display:"flex", height:"65vh", width:"100%", justifyContent:"center",alignItems:"center" }}>
          //   <Loading/>
          // </div>
          <> 
   
            <ViewChangeLogsTable
            tableID={this.props.tableID}
            ViewChangeLogsTable={this.props.ViewChangeLogsTable}
            showLoader={true}
          />
          </>
        //   <ViewChangeLogsTable
        //   LoadingIcon={true}
        //   hideText={true}
         
        // />
        );
      default:
        return <div />;
    }
  }
  render() {
    // alert(this.props.history)
    return this.getScreen(this.props.ViewChangeLogsTable_Get_status);
  }
}

export default withRouter(ViewChangeLogsTableView);
