import { connect } from "react-redux";
import AddRefrenceDataView from "./AddRefrenceData_View";
import {AddRefrenceDataServer} from "./server"
import {AddRefrenceDataActions} from './AddRefrenceDataConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        AddRefrenceData_Status:state.AddRefrenceDataReducer.AddRefrenceData_Status,
        AddRefrenceData:state.AddRefrenceDataReducer.AddRefrenceDataDetails,
        AddRefrenceData_Message:state.AddRefrenceDataReducer.AddRefrenceData_Message,
        // RefrenceDataFields:state.AddRefrenceDataReducer.RefrenceDataFields
        RefrenceDataFields:state.ReferenceDataListReducer.RefrenceDataFields
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        handleAddRefrenceData: (data) => {

           dispatch( AddRefrenceDataServer.handleAddRefrenceData(data) )
        },
        
        resetReducerState:async ()=>{
           // await dispatch({type:AddRefrenceDataActions.RefrenceData_Add.LOADING})
             dispatch({type:AddRefrenceDataActions.RefrenceData_Add.NEW})
    }
    }
};
const AddRefrenceDataContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddRefrenceDataView)

export default AddRefrenceDataContainer;