import React, { Component } from "react";
 import {ExchangeRate_Status} from "./constants"
import NJTAdminHeader from "./NJTAdminHeader";
import NJTExchangeRateTable from "./NJTExchangeRateTable";
//import Loading from '../../utils/Loading';
import {Unitserver} from "./UnitSelector/server"


class NJTAdminView extends Component {
  constructor(props){
    super(props)
    this.state ={
      recordSaved:'',
      units:{}
    }
  }
  async componentWillMount()
  {
    this.state.units =  await Unitserver.getUnitsData()
    this.props.getExchageRates()

  }
 
  componentWillReceiveProps(nextProps)
  {
     this.setState({recordSaved:this.props.recordSaved})
  }
  
  getScreen(status) {
    switch (status) {
   
        case ExchangeRate_Status.ExchangeRate_Get.NEW:
            return (
              <div>         
                   <NJTAdminHeader/>
                   Loading...
               </div>

            );
            case ExchangeRate_Status.ExchangeRate_Get.SUCCESS:
            return (
              <div>
                 <NJTAdminHeader/>
                 <NJTExchangeRateTable exchangeRate_get_status={this.props.exchangeRate_get_status} updateRecord={this.props.updateRecord} recordSaved={this.state.recordSaved} saveExchangeRate={this.props.saveExchangeRate} exchangeRates={this.props.exchangeRates} units={this.state.units} recordSaved={this.props.recordSaved}/>
              </div>
            );
            break;
            case ExchangeRate_Status.ExchangeRate_Get.FAILURE:
            return (
              <div>
              <h1> Something went wrong ,please try again</h1>
              </div>
            );
            break;
            case ExchangeRate_Status.ExchangeRate_Get.LOADING:
            return (
              <div style={{textAlign:"center"}}>
                <h1> Loading </h1>
              </div>
            );
            break;
              default:
              break;
    }
}
  render() {
    return (
      <div>
        {this.getScreen(this.props.exchangeRate_get_status)}
      </div>
    );
  }
}

export default NJTAdminView;
