
//******************Exchange Rate Server Calls******************;

import {ChangeEmailActions} from './ChangeEmailConstants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"


export const ChangeEmailServer = {
  //  getChangeEmail:getChangeEmail,
  ChangeEmail:ChangeEmail,
};

//******************Insert ChangeEmails******************;

function ChangeEmail(data){
      // alert(JSON.stringify(data))
      const request=fetch(ROOT_URL+'/api/applicationUser/updateNJTUserEmail', {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             if(response.status == "200"){
             // alert(JSON.stringify(response))
            store.dispatch({type:ChangeEmailActions.ChangeEmail_POST.SUCCESS,payload:data, message:response.message});
            return ;
            }else
            {
              store.dispatch({type:ChangeEmailActions.ChangeEmail_POST.FAILED, error:response.error});
              return ;
              }    
           });
          }).catch((error) => {
          //  alert(error)
           store.dispatch({type:ChangeEmailActions.ChangeEmail_POST.FAILED, error:failure_messages.unExpectedError});
            })
      return {type:ChangeEmailActions.ChangeEmail_POST.LOADING}

};
  

//******************Get ChangeEmails******************;


//******************Update ChangeEmail******************;

  
// function getChangeEmail(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.ChangeEmail.length>0)
//              store.dispatch({type:ChangeEmailActions.ChangeEmail_POST.SUCCESS,payload:response.ChangeEmail});
//              else
//              store.dispatch({type:ChangeEmailActions.ChangeEmail_POST.NEW});
             
//              return ;
//              }else
//              {
//                store.dispatch({type:ChangeEmailActions.ChangeEmail_POST.FAILED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:ChangeEmailActions.ChangeEmail_POST.FAILED});
//           })
//        return {type:ChangeEmailActions.ChangeEmail_POST.LOADING}
 
//     };
  
// function updateChangeEmail(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:ChangeEmailActions.ChangeEmail_POST.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:ChangeEmailActions.ChangeEmail_POST.FAILED});
//         return ;
//         }    
//      });
//     }).catch((error) => {
//       store.dispatch({type:ChangeEmailActions.ChangeEmail_POST.FAILED});
//     })
// return {type:ChangeEmailActions.ChangeEmail_POST.LOADING}

// };
