export const ReferenceDataListActions= {
	
	
	ReferenceDataList_Get :{
		NEW:"ReferenceDataList_Get_NEW",
		SUCCESS:"ReferenceDataList_Get_SUCCESS",
		FAILED:"ReferenceDataList_Get_FALIURE",
		LOADING:"ReferenceDataList_Get_LOADING",
		NOT_AUTHORIZED: "ReferenceDataList_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET",
		ADD_SUCCESS:"RefrenceData_Add_SUCCESS",
	},
	ReferenceDataList_Post :{
		NEW:"ReferenceDataList_Post_NEW",
		SUCCESS:"ReferenceDataList_Post_SUCCESS",
		FAILED:"ReferenceDataList_Post_FALIURE",
		LOADING:"ReferenceDataList_Post_LOADING",
		NOT_AUTHORIZED: "ReferenceDataList_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const ReferenceDataListStatus ={

	ReferenceDataList_Get :{
		NEW:"ReferenceDataList_Get_NEW",
		SUCCESS:"ReferenceDataList_Get_SUCCESS",
		FAILED:"ReferenceDataList_Get_FALIURE",
		LOADING:"ReferenceDataList_Get_LOADING",
		NOT_AUTHORIZED: "ReferenceDataList_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET",
		ADD_SUCCESS:"RefrenceData_Add_SUCCESS",
	},
	ReferenceDataList_Post :{
		NEW:"ReferenceDataList_Post_NEW",
		SUCCESS:"ReferenceDataList_Post_SUCCESS",
		FAILED:"ReferenceDataList_Post_FALIURE",
		LOADING:"ReferenceDataList_Post_LOADING",
		NOT_AUTHORIZED: "ReferenceDataList_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
