import React, { Component } from "react";
import {  withRouter } from "react-router-dom";
import "../UserManagement/UserManagement.css";
import LabelInput from "../Inputs/LabelInput";
// import CustomLabelDropDownInput from "../Inputs/CustomLabelDropDownInput/CustomLabelDropDownInput";
import PasswordShowHide from "../Inputs/PasswordShowHide/PasswordShowHide";

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
          password: "",
          passwordErrorMsg: "Field Required",
          passwordFlag: "",
          username:"",
          email:"",
          role:"",
          user_id:0,
          // passType:"password"
        };
        // alert(JSON.stringify(this.props.cookies.get('NJTUsers')))
        if(this.props.cookies.get('NJTUsers')){
          const NJTUser = this.props.cookies.get('NJTUsers')
          this.state.username = NJTUser.user_name
          this.state.email = NJTUser.email_address
          this.state.role = NJTUser.role_name
        }
        // this.props.cookies.set("NJTUsers"
      }
      componentDidMount() {
        window.scrollTo(0, 0);
      }
      passwordHandler = e => {
        // const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#_?&])[A-Za-z\d@$!%*_#?&]{8,}$/;
        const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.\<>?;:"'`~!@#$%^&*()\[\]{}_+=|\-\/,])[A-Z\a-z\d.\\<>?;:"'`~!@#$%^&*()\[\]{}_+=|\-\/,]{8,}$/;
        const validReg = reg.test(e.target.value);
        if (validReg) {
          this.setState({ passwordFlag: false });
        } else {
          this.setState({
            passwordFlag: true,
            passwordErrorMsg: "Password should be more than 7 characters, at least 1 letter, 1 number and 1 symbol"
          });
        }
        this.setState({ password: e.target.value});
      };
//********************Generate Password */
sendHandler = () =>{
  if (this.state.passwordFlag || this.state.password === "") {
      this.setState({
        passwordErrorMsg:
          this.state.passwordFlag && this.state.password.length > 0
            ? "Password should be more than 7 characters, at least 1 letter, 1 number and 1 symbol"
            : "Field Required",
        passwordFlag: true
      });
    } else if(this.props.cookies.get('NJTUsers')) {
      // alert(JSON.stringify(this.props.cookies.get('NJTUsers')))
    const  data = {
        password_digest:this.state.password,
        application_user_id:this.props.cookies.get('NJTUsers').application_user_id,
        role_id:this.props.cookies.get('NJTUsers').role_id,
        email_address : this.state.email,
        user_name:this.state.username,
        phone :this.props.cookies.get('NJTUsers').phone
      }
      // alert(JSON.stringify(data))
     this.props.ChangePassword(data)
      // alert(JSON.stringify(data))
    }
}

passwordGenerateHandler = () => {
  var pLength = 9
  var keyListAlpha="abcdefghijklmnopqrstuvwxyz",
  keyListInt="123456789",
  keyListSpec="!@#$%*?&_",
  password='';
var len = Math.ceil(pLength/2);
len = len - 1;
var lenSpec = pLength-2*len;

for (var i=0;i<len;i++) {
  password+=keyListAlpha.charAt(Math.floor(Math.random()*keyListAlpha.length));
  password+=keyListInt.charAt(Math.floor(Math.random()*keyListInt.length));
}

for (var i=0;i<lenSpec;i++)
  password+=keyListSpec.charAt(Math.floor(Math.random()*keyListSpec.length));

password=password.split('').sort(function(){return 0.5-Math.random()}).join('');
  // alert(password)
  // password = password.substr(0,len)
  this.setState({ password: password, passwordFlag:false});
  
        //  if (this.state.passwordFlag || this.state.password === "") {
        //   this.setState({
        //     passwordErrorMsg:
        //       this.state.passwordFlag && this.state.password.length > 0
        //         ? "Password should be alphanumeric and more than 7 digits and atleast 1 symbol"
        //         : "Field Required",
        //     passwordFlag: true
        //   });
        // } else {

        // }
      };
      backHandler = () =>{
        this.props.history.replace("/UserManagement")
      }
  render() {
    const {
        password,
        passwordErrorMsg,
        passwordFlag
      } = this.state;
    return (
      <div className="usr-m-box">
        <div className="usr-m-conatiner">
          <div className="user-m-heading">
            <h5>Change Password</h5>
          </div>
          <div className="fiter-search-box change-pass">
            <div className="inner-coantainer">
              <div className="pass-change-box">
                <div className="pass-input">
                <LabelInput
                  label="User Name"
                  type="text"
                  name="name"
                  id="userName"
                  inputValue={this.state.username}
                //   flag={nameFlag}
                //   errorMsg={nameErrorMsg}
                  onChange={this.nameHandler}
                  disabled
                />
                </div>
                <div className="pass-input">
                <LabelInput
                  label="Role"
                  type="text"
                  name="role"
                  id="role"
                  inputValue={this.state.role}
                //   flag={roleFlag}
                //   errorMsg={roleErrorMsg}
                  onChange={this.roleHandler}
                  disabled
                />
                </div>
                {/* <div className="pass-input">
                <LabelInput
                  label="Cost in Points"
                  type="text"
                  name="costPoints"
                //   inputValue={costPoints}
                //   flag={costPointsFlag}
                //   errorMsg={costPointsErrorMsg}
                  onChange={this.costPointsHandler}
                />
                </div> */}
                <div className="pass-input">
                <LabelInput
                  label="Email"
                  type="text"
                  name="email"
                  id="email"
                  inputValue={this.state.email}
                //   flag={emailFlag}
                //   errorMsg={emailErrorMsg}
                  onChange={this.emailsHandler}
                  disabled
                />
                </div>
                <div className="pass-input">
                  
                <PasswordShowHide
            type={this.state.passType}
            label="New Password"
            id="newPassword"
            name="text"
            inputValue={password}
            errorMsg={passwordErrorMsg}
            flag={passwordFlag}
            onChange={this.passwordHandler}
            containerStyle={{ marginTop: "20px" }}
          />
                </div>
                <div className="generate-btn">
                    <button id="generatePassword" className="filter-btn" onClick={this.passwordGenerateHandler}>
                        Generate Password
                    </button>
                </div>
                <div className="two-pass-btn">
                <button
                  className="filter-btn2 left"
                  id="backBtn"
                  onClick={this.backHandler}
                >
                  Back
                </button>
                <button
                  className="filter-btn right"
                  id="sendBtn"
                  onClick={this.sendHandler}
                >
                  Send
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ChangePassword);
