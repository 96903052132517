import React, { forwardRef,Component } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
// import UnitSelectorContainer from './UnitSelector/UnitSelectorContainer'
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}  />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default class Table  extends Component {
constructor(props){
super(props)
console.log(this.props)
this.state ={
  columns: [
    { title: "From-Unit", field: "from_unit", lookup: this.props.units,},
    { title: "To-Unit", field: "to_unit", 
      lookup: this.props.units,
   },
    { title: "From-Quantity", field: "from_quantity", type:'numeric' },
    { title: "To-Quantity", field: "to_quantity", type:'numeric' },
    { title: "From", field: "from_date", type:'date' },
    { title: "To", field: "to_date", type:'date' },
  ],
  data:this.props.exchangeRates,
  errorMessage:'',
  errorPopupOpen:false,
  recordSaved:"",
  open:false
  }
}
componentWillReceiveProps(nextProps)
{
   console.log(nextProps,nextProps.recordSaved, this.props)
 var open = false
  if(nextProps.recordSaved==200)
  {
      open=true
  }
  else
  {
    open=false
  }
  this.setState({recordSaved:nextProps.recordSaved,open:open, errorMessage:"Record Successfully Saved"})
}
 TransitionDown=(props)=> {
  return <Slide {...props} direction="down" />;
}
 handleClose = () => {
  this.setState({open:false});
}

validateData(record)
{
  
var sameRecords = this.state.data.filter(item=>
  {
    return item == record
  })
  if(record.from_unit == record.to_unit || record.to_date < record.from_date || sameRecords.length >0)
  {
    
    return false
  }
  else
  {
    return true
  }
}
render(){

  return (
    <div className="main-table">
      {(this.state.open)?
      <Snackbar
      open={true}
      onClose={this.handleClose}
      TransitionComponent={this.TransitionDown}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{this.state.errorMessage}</span>}
    />
      :
      <div></div>    
      }
    <MaterialTable
      icons={tableIcons}
      title="NJT Rewards ExchangeRates"
      columns={this.state.columns}
      data={this.state.data}
      editable={{
        onRowAdd: newData =>
          new Promise(async resolve => {
           // setTimeout(() => {
             if(this.validateData(newData)){
             
              const data = [...this.state.data];
              data.push(newData);
              this.state.data=data
              await this.props.saveExchangeRate(newData)
              resolve();
             }
             else{
             
              resolve();
              this.setState({open:true, errorMessage:"Data Enter is not valid"})


             }
              
           // }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            // setTimeout(() => {
            //   resolve();
            //   // console.log("dataaaaaaaaa",oldData,newData)
            //   this.props.updateRecord(newData)
            //   const data = [...this.state.data];
            //   data[data.indexOf(oldData)] = newData;
            //   this.setState({ ...this.state, data });
            // }, 600);
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            // setTimeout(() => {
            //   resolve();
            //   const data = [...this.state.data];
            //   data.splice(data.indexOf(oldData), 1);
            //   this.setState({ ...this.state, data });
            // }, 600);
          })
      }}
    />
    </div>
  );
 }
}