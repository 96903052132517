import { connect } from "react-redux";
import FilterField_View from "./FilterField_View";
import { FilterFieldServer } from "./server";
// import { FilterFieldActions } from "./FilterFieldConstants";
const mapStateToProps = state => {
  console.log(state);
  return {
    FilterField_Get_status:
      state.FilterFieldReducer.FilterField_Status,
    // FilterField_post_status:state.NJT_FilterField_Reducer.FilterField_Status,
    FilterField:
      state.FilterFieldReducer.FilterFieldDetails
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getFilterField: (data) => {
      dispatch(FilterFieldServer.getFilterField(data));
    }
  };
};
const FilterFieldContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterField_View);

export default FilterFieldContainer;
