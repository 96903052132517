
//******************Exchange Rate Server Calls******************;

import {RetailerListActions} from './RetailerListConstants';
import store from '../../Store/store'
import {ROOT_URL, getCookie} from '../../Config/Config';
import {failure_messages} from "Messages"


export const RetailerListServer = {
  //  getRetailerList:getRetailerList,
  getRetailerList:getRetailerList,
  getRetailers:getRetailers
  //  updateRetailerList:updateRetailerList
};

//******************Insert RetailerLists******************;

function getRetailerList(){
      const request=fetch(ROOT_URL+'/api/banners/getRetailerBusinesses', {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          }).then((response)=>{
           response.json().then(response=>{
             if(response.status == "200"){
             // alert(JSON.stringify(response))
            store.dispatch({type:RetailerListActions.RetailerList_GET.SUCCESS,payload:response.data});
            return ;
            }else
            {
              store.dispatch({type:RetailerListActions.RetailerList_GET.FAILED, error:response.error});
              return ;
              }    
           });
          }).catch((error) => {
          //  alert(error)
           store.dispatch({type:RetailerListActions.RetailerList_GET.FAILED, error:failure_messages.Businessparticipants});
            })
      return {type:RetailerListActions.RetailerList_GET.LOADING}

};
  
//******************Get RetailerLists******************;

function getRetailers(){
  const request=fetch(ROOT_URL+'/api/retailer/getRetailers', {
      method: 'Post',
      mode: 'cors',
      headers: { 
        'authorization':getCookie("admin_token"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      }).then((response)=>{
       response.json().then(response=>{
         if(response.status == "200"){
         // alert(JSON.stringify(response))
        store.dispatch({type:RetailerListActions.RetailerList_GET.SUCCESS,payload:response.data});
        return ;
        }else
        {
          store.dispatch({type:RetailerListActions.RetailerList_GET.FAILED, error:response.error});
          return ;
          }    
       });
      }).catch((error) => {
      //  alert(error)
       store.dispatch({type:RetailerListActions.RetailerList_GET.FAILED, error:failure_messages.Businessparticipants});
        })
  return {type:RetailerListActions.RetailerList_GET.LOADING}

};

//******************Update RetailerList******************;

  
// function getRetailerList(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.RetailerList.length>0)
//              store.dispatch({type:RetailerListActions.RetailerList_Get.SUCCESS,payload:response.RetailerList});
//              else
//              store.dispatch({type:RetailerListActions.RetailerList_GET.NEW});
             
//              return ;
//              }else
//              {
//                store.dispatch({type:RetailerListActions.RetailerList_Get.FAILED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:RetailerListActions.RetailerList_Get.FAILED});
//           })
//        return {type:RetailerListActions.RetailerList_GET.LOADING}
 
//     };
  
// function updateRetailerList(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:RetailerListActions.RetailerList_GET.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:RetailerListActions.RetailerList_GET.FAILED});
//         return ;
//         }    
//      });
//     }).catch((error) => {
//       store.dispatch({type:RetailerListActions.RetailerList_GET.FAILED});
//     })
// return {type:RetailerListActions.RetailerList_GET.LOADING}

// };
