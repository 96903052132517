
//******************Exchange Rate Server Calls******************;
import {AuthenticationActions} from './AuthenticationConstants';
import store from '../../Store/store'
import {ROOT_URL} from '../../Config/Config';
import {failure_messages} from 'Messages'

export const AuthenticationServer = {
  //  AuthenticateUser:AuthenticateUser,
  AuthenticateUser:AuthenticateUser,
  //  updateAuthentication:updateAuthentication
};

//******************Insert Authentications******************;

function AuthenticateUser(token){
      const request=fetch(ROOT_URL+'/api/applicationUser/AuthenticateUser', {
          method: 'Post',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', 'x-access-token': token },
          }).then((response)=>{
           response.json().then(response=>{
             
             if(response.status == "200" && !response.AuthError){
            //  alert(JSON.stringify(response))
            store.dispatch({type:AuthenticationActions.Authentication_TOKEN.AUTHORIZED, payload:response.message, message:response.message, error:response.error});
            return;
            }else
            {
              store.dispatch({type:AuthenticationActions.Authentication_TOKEN.NOT_AUTHORIZED, error:response.error});
              return;
              }    
           });
          }).catch((error) => {
          //  alert(error)
           store.dispatch({type:AuthenticationActions.Authentication_TOKEN.FAILED, error:failure_messages.authorizationError});
            })
      return {type:AuthenticationActions.Authentication_TOKEN.LOADING}

};
  
//******************Get Authentications******************;


//******************Update Authentication******************;

  
// function AuthenticateUser(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.Authentication.length>0)
//              store.dispatch({type:AuthenticationActions.Authentication_TOKEN.AUTHORIZED,payload:response.Authentication});
//              else
//              store.dispatch({type:AuthenticationActions.Authentication_TOKEN.NEW});
             
//              return ;
//              }else
//              {
//                store.dispatch({type:AuthenticationActions.Authentication_TOKEN.NOT_AUTHORIZED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:AuthenticationActions.Authentication_TOKEN.NOT_AUTHORIZED});
//           })
//        return {type:AuthenticationActions.Authentication_TOKEN.LOADING}
 
//     };
  
// function updateAuthentication(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:AuthenticationActions.Authentication_TOKEN.AUTHORIZED});
//       return ;
//       }else
//       {
//         store.dispatch({type:AuthenticationActions.Authentication_TOKEN.NOT_AUTHORIZED});
//         return ;
//         }    
//      });
//     }).catch((error) => {
//       store.dispatch({type:AuthenticationActions.Authentication_TOKEN.NOT_AUTHORIZED});
//     })
// return {type:AuthenticationActions.Authentication_TOKEN.LOADING}

// };
