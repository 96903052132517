
//******************Exchange Rate Server Calls******************;

import { UsersInventoryActions } from './UsersInventoryConstants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"


export const UsersInventoryServer = {
  //  UsersInventory:UsersInventory,
  getUsersInventory: getUsersInventory,
  //  updateUsersInventory:updateUsersInventory
};

//****************** UsersInventory with coupons ******************;

//****************** This Api fetch the Quotas of bussiness Id along with total coupons already generated; grouped by all the years of the contract.

function getUsersInventory(data) {
    // alert(JSON.stringify(data))
  const request = fetch(ROOT_URL + '/api/applicationUser/getAllAdminUsers', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log('Business quota details', response)

        store.dispatch({ type: UsersInventoryActions.UsersInventory_GET.SUCCESS, payload: response.data, UsersCount:response.UsersCount, UsersRoles:response.UsersRoles });
        return;
      } else {
        store.dispatch({ type: UsersInventoryActions.UsersInventory_GET.FAILED, error:response.error });
        return;
      }
    }).catch((error) => {
      store.dispatch({ type: UsersInventoryActions.UsersInventory_GET.FAILED,error:failure_messages.unExpectedError });
    })
  }).catch((error) => {
    store.dispatch({ type: UsersInventoryActions.UsersInventory_GET.FAILED,error:failure_messages.unExpectedError });
  })
  return { type: UsersInventoryActions.UsersInventory_GET.LOADING }

};
