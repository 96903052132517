export const DeleteReferenceDataActions= {
	
	DeleteReferenceData_DELETE :{
		NEW:"DeleteReferenceData_DELETE_NEW",
		SUCCESS:"DeleteReferenceData_DELETE_SUCCESS",
		FAILED:"DeleteReferenceData_DELETE_FALIURE",
		LOADING:"DeleteReferenceData_DELETE_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const DeleteReferenceDataStatus ={

	DeleteReferenceData_DELETE :{
		NEW:"DeleteReferenceData_DELETE_NEW",
		SUCCESS:"DeleteReferenceData_DELETE_SUCCESS",
		FAILED:"DeleteReferenceData_DELETE_FALIURE",
		LOADING:"DeleteReferenceData_DELETE_LOADING",
	}

}
