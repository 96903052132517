export const FilterFieldActions= {
	
	
	FilterField_Get :{
		NEW:"FilterField_Get_NEW",
		SUCCESS:"FilterField_Get_SUCCESS",
		FAILED:"FilterField_Get_FALIURE",
		LOADING:"FilterField_Get_LOADING",
		NOT_AUTHORIZED: "FilterField_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	FilterField_Post :{
		NEW:"FilterField_Post_NEW",
		SUCCESS:"FilterField_Post_SUCCESS",
		FAILED:"FilterField_Post_FALIURE",
		LOADING:"FilterField_Post_LOADING",
		NOT_AUTHORIZED: "FilterField_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const FilterFieldStatus ={

	FilterField_Get :{
		NEW:"FilterField_Get_NEW",
		SUCCESS:"FilterField_Get_SUCCESS",
		FAILED:"FilterField_Get_FALIURE",
		LOADING:"FilterField_Get_LOADING",
		NOT_AUTHORIZED: "FilterField_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	FilterField_Post :{
		NEW:"FilterField_Post_NEW",
		SUCCESS:"FilterField_Post_SUCCESS",
		FAILED:"FilterField_Post_FALIURE",
		LOADING:"FilterField_Post_LOADING",
		NOT_AUTHORIZED: "FilterField_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
