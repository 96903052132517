import React, { Component, Fragment } from 'react';

import { Link, withRouter } from 'react-router-dom';
// import {ref_role} from "RefRecordsConstants"
import '../UserManagement.css';
import Dateformat from 'dateformat';
import UserMessage from '../UserMessage';
import UserEmpty from '../UserEmpty';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { Api_Pagination } from 'RefRecordsConstants';
const admin = 'Admin';
const retailer = 'Retailer';
class UsersInventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: '',
      accountFlag: false,
      modalVisible: false,
    };
    //this.initailizeStates();
  }
  changePassword = (item) => {
    // alert(this.props.cookies)
    this.props.cookies.remove('NJTUsers', { path: '/' });
    this.props.cookies.set('NJTUsers', item, { path: '/' });
    this.props.history.push('/ChangePassword');
  };

  editEmail = (item) => {
    this.props.cookies.remove('NJTUsers', { path: '/' });
    this.props.cookies.set('NJTUsers', item, { path: '/' });
    this.props.history.push('/EditEmail');
  };

  render() {
    const { UsersInventory = [], filter, failed } = this.props;
    // alert(JSON.stringify(UsersInventory))
    // const FilteredList = UsersInventory.filter((item) => {
    //   var flag = true;
    //   if (filter.searchText !== '') {
    //     // if(item.role_name =="SMB")
    //     // item.role_name = retailer
    //     // if(item.role_name =="NJT Admin")
    //     // item.role_name = admin

    //     flag = item[filter.searchBy]
    //       ?.toLowerCase()
    //       ?.includes(filter.searchText?.toLowerCase());
    //     // alert(JSON.stringify(item))
    //   }
    //   return flag;
    // });

    const FilteredList = UsersInventory;
    console.log("filter data", FilteredList)

    return (
      <Fragment>
        <table className='table-p'>
          <thead className='heading-tab'>
            <th>Sr. #</th>
            <th>User Name</th>
            <th>Email</th>
            <th>Role</th>
            
            <th>Action</th>
          </thead>
          <tbody className='bodyoftable'>
            {FilteredList.map((res, index) => {
              return (
                <tr key={res.application_user_id}>
                  <td>
                    {Api_Pagination.UsersListLimit *
                      (this.props.activePage - 1) +
                      index +
                      1}
                  </td>
                  <td>{res.user_name}</td>
                  <td>{res.email_address}</td>

                  <td>{res.role_name}</td>
                  <td>
                    <div className='ser-box'>
                      <ul className='filt'>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li className='fa-key-pass'>
                          <Link
                            id="updateEmailLink"
                            title='Update Email'
                            onClick={() => this.editEmail(res)} // to="/EditEmail"
                          >
                            <i class='fas fa-envelope'></i>
                          </Link>
                        </li>
                        <li className='fa-key-pass'>
                          <Link
                            id="changePasswordLink"
                            title='Change Password'
                            onClick={() => this.changePassword(res)} // to="/ChangePassword"
                          >
                            <i class='fas fa-key'></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {FilteredList.length == 0 ? (
          failed ? (
            <UserMessage message={'Failed to get list of users'} LoadingIcon={true} />
          ) : (
            <UserEmpty filter={filter} />
          )
        ) : null}
      </Fragment>
    );
  }
}

export default withRouter(UsersInventory);
