import { connect } from "react-redux";
import NJTAdminView from "./NJTAdminView";
import {ExchangeRateServer} from "./server"
const mapStateToProps = state => {
    return {

        exchangeRate_get_status:state.NJT_ExchangeRate_Get_Reducer.exchangeRate_get_status,
        exchangeRates:state.NJT_ExchangeRate_Get_Reducer.exchangeRates,
        recordSaved: state.NJT_ExchangeRate_Get_Reducer.recordSaved
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getExchageRates: () => {
            ExchangeRateServer.getExchangeRates()
        },
        saveExchangeRate: (data) => {
           ExchangeRateServer.saveExchangeRate(data)
        },
        updateRecord: (data) => {
            ExchangeRateServer.updateRecord(data)
        }
    }
};
const NJTExchangeRateTableContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NJTAdminView)

export default NJTExchangeRateTableContainer;