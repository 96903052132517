import React, {  Fragment, PureComponent } from 'react'
//import './AdministrationLogin.css'
import { AdministrationLoginStatus } from './AdministrationLoginconstants';
import AdministrationLogin from "./AdministrationLoginPage"
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from '../Utils/Loading';
import moment from "moment"
export class AdministrationLoginView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    }

  }

  closeSnackBar = () => {
    this.setState({
      open: false
    })
  }
  
  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.AdministrationLogin_GET_status === AdministrationLoginStatus.AdministrationLogin_GET.SUCCESS){
      toast.success(nextProps.AdministrationLogin_Message)
      var d=new Date()
      d = moment(d, "DD-MM-YYYY").add(1, 'days');
    
      nextProps.cookies.remove('admin_token', { path: '/' });
      nextProps.cookies.remove('njt_admin_userId', { path: '/' });
      nextProps.cookies.set('admin_token', nextProps.AdministrationLoginDetails.access_token, { path: '/', sameSite:true, expires:new Date(d) });
      // alert(JSON.stringify(nextProps.AdministrationLoginDetails.UserId))
      nextProps.cookies.set('njt_admin_userId', nextProps.AdministrationLoginDetails.UserId, { path: '/', sameSite:true, expires:new Date(d)  });
      nextProps.history.replace({
          pathname: "/Admin"
        })
   }
   return null;
 }
  getScreen(status) {
    switch (status) {
      case AdministrationLoginStatus.AdministrationLogin_GET.NEW:
        return (
          <Fragment >
            <AdministrationLogin AdministrationLogin={this.props.AdministrationLogin} />
          </Fragment>
        );


      case AdministrationLoginStatus.AdministrationLogin_GET.SUCCESS:
        
        return (
          <Fragment >
            <AdministrationLogin AdministrationLogin={this.props.AdministrationLogin} />
          </Fragment>
        );
        break;
      case AdministrationLoginStatus.AdministrationLogin_GET.FAILED:
        toast.error(this.props.AdministrationLogin_Message)
        return (
          <div>

            <AdministrationLogin AdministrationLogin={this.props.AdministrationLogin} />

          </div>
        );

      case AdministrationLoginStatus.AdministrationLogin_GET.LOADING:
        this.state.open = true
        return (
          <div style={{ textAlign: "center", height:"65vh", display:"flex",width:"100%", justifyContent:"center", alignItems:"center" }}>
           <Loading/>
          </div>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
    // alert(this.props.AdministrationLogin_GET_status)
    return (
      this.getScreen(this.props.AdministrationLogin_GET_status)
    )
  }
}

export default withRouter(AdministrationLoginView)
