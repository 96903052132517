import React, { Component } from 'react';
import './MultiSelectDropdown.css';
import { Multiselect } from 'multiselect-react-dropdown';

class MultiSelectDropdownCheckbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
    };
  }
  componentDidMount = () => {
    if (this.props.businessCategoryNames) {
      let category_names_array = this.props.businessCategoryNames.split('+');
      console.log(category_names_array, 'ajshdjahsdjahsd');
      let opt = this.props.data;
      let sopt = [];
      for (let i = 0; i < opt?.length; i++) {
        for (let y = 0; y < category_names_array.length; y++) {
          if (opt[i].name == category_names_array[y]) {
            sopt.push(opt[i]);
          }
        }
        // let a = ""+opt[i].name
        // console.log(opt[i].name,category_names_array[a], "ssdsdsdsdsdsdsd")
        // if(opt[i].name == category_names_array[opt[i].name]){
        //   sopt.push(opt[i])
        // }
      }
      console.log(sopt, 'akjsdajshdjas');
      this.props.onSelect(sopt);
      this.setState({
        selected: sopt,
      });
    }
  };
  render() {
    const {
      label = 'Business(s)',
      onChange,
      errorMsg = 'errorMsg',
      flag = false,
      containerStyle,
      loaderStyle,
      readOnly = false,
      disabled,
      inputStyle,
      onSelect,
      onRemove,
      placeHolder = 'Choose a  Role',
      inputValue,
      LOADER,
      data = this.state.options,
    } = this.props;
    // console.log(this.props.businessCategoryNames, "ajhsdhasdha")
    return (
      <>
        <div className='li_container select-dro-mult' style={containerStyle}>
          <label htmlFor='' className='li_label'>
            {label}
          </label>
          <div className='li_input_container'>
            <Multiselect
            //   disablePreSelectedValues={false}
              options={data} // Options to display in the dropdown
              selectedValues={inputValue ? inputValue : this.state.selected} // Preselected value to persist in dropdown
              onSelect={onSelect} // Function will trigger on select event
              onRemove={onSelect} // Function will trigger on remove event
              displayValue='name' // Property name to display in the dropdown options
              onChange={onChange}
              style={inputStyle}
              disabled={disabled}
              readOnly={readOnly}
              value={inputValue ? inputValue : this.state.selected}
              placeholder={placeHolder}
              emptyRecordMsg='No Option Available'
              avoidHighlightFirstOption='false'
              showCheckbox
            //   singleSelect
            />
            <p
              className='li_error_text'
              style={{ display: flag ? 'block' : 'none' }}
            >
              {errorMsg}
            </p>
          </div>
          {this.props.LoadingIcon && (
            <div className='outer-loader'>
              <div className='inner-loader'  style={loaderStyle}>
                <span className='input-group-addon'>
                  <i class='fa fa-refresh fa-spin bc'></i>
                </span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default MultiSelectDropdownCheckbox;

