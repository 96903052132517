import React, { Component, Fragment } from "react";
import "./ReferenceDataList.css";
// import dateFormat from "dateformat";
// import { CSVLink } from "react-csv";
// import { Link } from "react-router-dom";
import ReferenceModal from '../../Cards/UploadBannerCard/ReferenceModal';
import DeleteReferenceDataContainer from "../DeleteReferenceData/DeleteReferenceDataContainer"
import ReferenceTableEmptyAll from "../ReferenceTableEmptyAll"
import moment from "moment";
export class ReferenceDataList extends Component {
  downloadHandler = () => {
    //  alert("downloadHandler works");
  };
// import dateFormat from "dateformat";
// {/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(item.original_value)?
//                     dateFormat(item.original_value,"mm/dd/yyyy"):item.original_value}
  render() {
    // alert(this.props.select)
      var EmptyData=false
      var column=[]
    const { ReferenceDataList = [] } = this.props;
    if(undefined !== ReferenceDataList && ReferenceDataList.length === 0 || ReferenceDataList === null ||ReferenceDataList === undefined )
    {
        EmptyData=true
    }
    else
    {
        column=Object.keys(ReferenceDataList[0])

    }
    return (
      <Fragment>
        <div className="ref-table-box amrigin">
                                <div className="ref-table-head">
                                    
               { column.map((item,index) =>(
                 <div className="ref-table-h" key={index}>
                 {item}
                 </div>
                
                ))}
                <div className="ref-table-h">
                                    Archive
                                    </div>
               </div>
          <div className="ref-table-body">
            {ReferenceDataList.length>0?
              ReferenceDataList.map((item, index) => (
                <div  className="ref-table-box2" key={index}>
                  
                    {Object.keys(ReferenceDataList[0]).map((key,i)=>(
                     <div className="ref-table-text" key={i}>
                      {/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(item[key])?
                    moment(item[key]).format("MM/DD/YYYY")
                    // dateFormat(item[key],"mm/dd/yyyy")
                    :item[key]}
                      </div>
                
                    ))}
                     <div className="ref-table-text border">
                                     <ReferenceModal 
                                     selectedTable = {this.props.selectedTable}
                                     getReferenceDataList = {this.props.getReferenceDataList}
                                     data={item}/>
                                     <DeleteReferenceDataContainer
                                    data={item}                         
                                    selectedTable = {this.props.selectedTable}
                                    getReferenceDataList = {this.props.getReferenceDataList}
                                     />
                                      {/* <Link className="table-link" onClick={this.props.deleteHandler}>Delete</Link> */}
                                     </div>

                  </div>
                    )):<ReferenceTableEmptyAll 
                    select={ this.props.select}
                    />
                    }
                    
                 
                </div>
             
          </div>

        
      </Fragment>
    );
  }
}

export default ReferenceDataList;
