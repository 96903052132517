import { connect } from "react-redux";
import DeleteExchangeRatesView from "./DeleteExchangeRates_View";
import {DeleteExchangeRatesServer} from "./server"
import {DeleteExchangeRatesActions} from './DeleteExchangeRatesConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        DeleteExchangeRates_Status:state.DeleteExchangeRatesReducer.DeleteExchangeRates_Status,
        DeleteExchangeRates:state.DeleteExchangeRatesReducer.DeleteExchangeRatesDetails,
        DeleteExchangeRates_Message:state.DeleteExchangeRatesReducer.DeleteExchangeRates_Message
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        handleDeleteExchangeRates: (data) => {

           dispatch( DeleteExchangeRatesServer.handleDeleteExchangeRates(data) )
        },
        resetReducerState:async ()=>{
            await dispatch({type:DeleteExchangeRatesActions.DeleteExchangeRates_DELETE.LOADING})
             dispatch({type:DeleteExchangeRatesActions.DeleteExchangeRates_DELETE.NEW})
    }
    }
};
const DeleteExchangeRatesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteExchangeRatesView)

export default DeleteExchangeRatesContainer;