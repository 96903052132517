
//******************Exchange Rate Server Calls******************;

import {ExchangeRatesPointsToCouponActions} from './ExchangeRatesPointsToCouponconstants';
import store from '../../Store/store'
import {ROOT_URL, getCookie} from '../../Config/Config';
import {failure_messages} from 'Messages'


export const ExchangeRatesPointsToCouponServer = {
    getCouponExchangeRates:getCouponExchangeRates,
    saveExchangeRatesPointsToCoupon:saveExchangeRatesPointsToCoupon,
    updateExchangeRatesPointsToCoupon:updateExchangeRatesPointsToCoupon
};

//******************Insert ExchangeRatesPointsToCoupons******************;

function saveExchangeRatesPointsToCoupon(data){
      const request=fetch(ROOT_URL+'/api/exchangeRate/postExchangeRate', {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
         //    alert(JSON.stringify(response))
             if(response.status == "200"){
               console.log(response)
            store.dispatch({type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.SUCCESS,payload:response.data, message:response.message});
            return ;
            }else
            {
              store.dispatch({type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.FAILED, message:response.error});
              return ;
              }    
           }).catch((error) => {
            store.dispatch({type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.FAILED, error:failure_messages.unExpectedError});
          })
          }).catch((error) => {
            store.dispatch({type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.FAILED, error:failure_messages.unExpectedError});
          })
      return {type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.LOADING}

};
  
//******************Get ExchangeRatesPointsToCoupons******************;


//******************Update ExchangeRatesPointsToCoupon******************;

  
function getCouponExchangeRates(data){
//  alert(JSON.stringify(data))
      const request=fetch(ROOT_URL+'/api/exchangeRate/getCouponExchangeRates/', {
          method: 'POST',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
            response.json().then(response=>{
              if(response.status == "200"){
                console.log(response)
                if(response.exchangeRates.length>0)
             store.dispatch({type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Get.SUCCESS,payload:response.exchangeRates});
             else
             store.dispatch({type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.NEW});
             
             return ;
             }else
             {
               store.dispatch({type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Get.FAILED, error:response.error});
               return ;
               }    
            }).catch((error) => {
            store.dispatch({type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Get.FAILED, error:failure_messages.ExchangeRateGetError});
          })
           }).catch((error) => {
            store.dispatch({type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Get.FAILED, error:failure_messages.ExchangeRateGetError});
          })
       return {type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.LOADING}
 
    };
  
function updateExchangeRatesPointsToCoupon(data){
  const request=fetch(ROOT_URL+'/api/exchangeRate/UpdateExchangeRate', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
    }).then((response)=>{
     response.json().then(response=>{
       if(response.status == "200"){
         console.log(response)
      store.dispatch({type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.SUCCESS, payload:data.data, message:response.message});
      return ;
      }else
      {
        store.dispatch({type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.FAILED, error:response.error});
        return ;
        }    
     });
    }).catch((error) => {
   //   alert(error)
      store.dispatch({type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.FAILED, error:failure_messages.unExpectedError});
    })
return {type:ExchangeRatesPointsToCouponActions.ExchangeRatesPointsToCoupon_Post.LOADING}

};
