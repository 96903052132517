

import {Units_Actions} from './constants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';


export const Unitserver = {
    getUnits:getUnits,
   getUnitsData:getUnitsData
};

 

function getUnits(){
    const request=fetch(ROOT_URL+'/api/units/getUnits', {
        method: 'POST',
        headers: {'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8','authorization':getCookie("admin_token")},
        
        mode: 'cors',
        }).then((response)=>{
        console.log('********'+response);
         response.json().then(data=>{
          console.log("data:......" , data.units)
          // alert("ok")
           if(response.status == "200"){
         //   console.log("888888",data.medicineList)
          store.dispatch({type:Units_Actions.Units_Get.SUCCESS,payload:data.units});
          return ;
          }else
          if(response.status=='500'){
            store.dispatch({type:Units_Actions.Units_Get.FAILED});
            return ;
            }    
         });
        }).catch((error) => {
         store.dispatch({type:Units_Actions.Units_Get.OFFLINE});
          })
    return {type:Units_Actions.Units_Get.NEW}
};

function arrayToObject(array){
   return array.reduce((obj, item) => {
     obj[item.id] = item.unit_name
     return obj
   }, {});
}

 async function getUnitsData(){
    return  fetch(ROOT_URL+'/api/units/getUnits', {
        method: 'POST',
        headers: {'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8','authorization':getCookie("admin_token")},
        
        mode: 'cors',
        }).then((response)=>{
        // console.log('********'+response);
         return response.json().then(data=>{
          console.log("data:......" , data.units)
          // alert("ok")
           if(response.status == "200"){
       
          return  arrayToObject(data.units)
          //return ;
          }else
          if(response.status=='500'){
           // store.dispatch({type:Units_Actions.Units_Get.FAILED});
            return {};
            }    
         });
        }).catch((error) => {
            return {};
        // store.dispatch({type:Units_Actions.Units_Get.OFFLINE});
          })
    
};

  