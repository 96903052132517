import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import "../UploadBannerCard/UploadBannerCard.css";
import LabelInput from '../../Inputs/LabelInput';
// import AddRefrenceDataContainer from "../../ReferenceDataConfiguration/AddRefrenceData/AddRefrenceDataContainer"
import RefrenceDataUpdateContainer from "../../ReferenceDataConfiguration/RefrenceDataUpdate/RefrenceDataUpdateContainer"
// import dateFormat from "dateformat";

import {ref_globals}from "RefRecordsConstants"
import moment from 'moment';
const modalRoot = document.getElementById('send_portal')

class Modal extends React.Component {
  constructor(props) {
    super(props)
  
    this.state = {
      stationId: "",
      stationIdErrorMsg: "Field Required",
      stationIdFlag: false,
      password: "",
      passwordErrorMsg: "Field Required",
      passwordFlag: false,
      stationName: "",
      stationNameErrorMsg: "Field Required",
      stationNameFlag: false,
      stationTown: "",
      stationTownErrorMsg: "Field Required",
      stationTownFlag: false,
      stationAction: "",
      stationActionErrorMsg: "Field Required",
      stationActionFlag: false,
      data:props.data,
      prevVersion:false,
      dataKeys:Object.keys(props.data)
      // tmpData:props.data
    }
    if(this.state.dataKeys.length > 1)
    if(ref_globals.version == this.state.dataKeys[1])
    this.state.prevVersion = props.data[this.state.dataKeys[1]]

    // if(props.data && Object.keys(props.data).length >2)
    // if(ref_globals.version == key)
    // this.state.prevVersion = data[key]
  }
  // stationIdHandler
  fieldChangeHandler = (key, e, i) => {
    // alert(key)
    // alert(e.target.value)
    if(i > 0){
     var data = {...this.state.data}
     data[key] = e.target.value
    //  alert(this.props.data[key])
     this.setState({
       data:data
     })
    }

    // this.setState({
    //   stationId: e,
    //   stationIdFlag: false,
      
    // });
  };
  // stationNameHandler
  stationNameHandler = e => {
    this.setState({
      stationName: e,
      stationNameFlag: false,
      
    });
  };
  // stationTownHandler
  stationTownHandler = e => {
    this.setState({
      stationTown: e,
      stationTownFlag: false,
      
    });
  };

   // stationActionHandler
   stationActionHandler = e => {
    this.setState({
      stationAction: e,
      stationActionFlag: false,
      
    });
  };
  // done function
  doneHandler = () => {
    //alert('test')

    if (this.state.stationId === "") {
      this.setState({ stationIdFlag: true });
    } else if (this.state.stationName === "") {
      this.setState({ stationNameFlag: true });
    } else if (this.state.stationTown === "") {
      this.setState({ stationTownFlag: true });
    } else if (this.state.stationAction === "") {
      this.setState({ stationActionFlag: true });
    }else {
      var data = {
        stationId: this.state.stationId,
        stationName: this.state.stationName,
        stationTown: this.state.stationTown,
        stationAction: this.state.stationAction,
        
      };
      this.props.saveUploadCouponImage(data)
    }
  };
  componentDidMount(){
    document.body.style.overflow = "hidden"
  }
  componentWillUnmount(){
    document.body.style.overflow = "auto"
  }
  render() {

    const {
      // stationId,
      // stationIdErrorMsg,
      // stationIdFlag,
      // stationName,
      // stationNameErrorMsg,
      // stationNameFlag,
      // stationTown,
      // stationTownErrorMsg,
      // stationTownFlag,
      // stationAction,
      // stationActionErrorMsg,
      // stationActionFlag,
      // tmpData,
      data,
    } = this.state;
    const {
      selectedTable = ""
    } = this.props

    return ReactDOM.createPortal(
      <div
        style={{
          position: 'fixed',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.3)',
          minHeight: '100vh',
          zIndex:'999999999999'
        }}
        // onClick={this.props.onClose}
      >
        <div
          style={{
            padding: 20,
            background: '#fff',
            borderRadius: '2px',
            display: 'inline-block',
            minHeight: '200px',
            margin: '1rem',
            position: 'relative',
            minWidth: '400px',
            boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
            justifySelf: 'center',
          }}
        >
          
          <div className="main-del-box">
                <div className="del-pop-text ref-model">
        {selectedTable}
                </div>
                <div className="ref-input-b">
                  {this.state.dataKeys.map((key,i)=>{
                    // if(isDate(data[key]))
                    // alert(data[key])
                    return(
                    <div className="ref-input" key={i}>
                    <LabelInput
                    id={"refInput"+i}
                    label = {key}
                    type="text"
                    name={key}
                    inputValue={/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(data[key])?
                    moment(data[key]).format("MM/DD/YYYY")
                    // dateFormat(data[key],"mm/dd/yyyy")
                    :data[key]}
                    errorMsg={"Field required"}
                    flag={data[key] === ""?true:false}
                    onChange={(e)=>this.fieldChangeHandler(key, e, i)}
                    />
                    </div>
                    )
                  })

      }
                    {/* <div className="ref-input">
                    <LabelInput
                    label = 'Station_Name'
                    type="text"
                    name="stationName"
                    inputValue={stationName}
                    errorMsg={stationNameErrorMsg}
                    flag={stationNameFlag}
                    onChange={this.stationNameHandler}
                    />
                    </div>
                    <div className="ref-input">
                    <LabelInput
                    label = 'Station_Town'
                    type="text"
                    name="stationTown"
                    inputValue={stationTown}
                    errorMsg={stationTownErrorMsg}
                    flag={stationTownFlag}
                    onChange={this.stationTownHandler}
                    />
                    </div>
                    <div className="ref-input">
                    <LabelInput
                    label = 'Station_Action'
                    type="text"
                    name="stationAction"
                    inputValue={stationAction}
                    errorMsg={stationActionErrorMsg}
                    flag={stationActionFlag}
                    onChange={this.stationActionHandler}
                    />
                    </div> */}
                </div>
            </div>
            <div className="del-pop-bnt refpadd">
                <button id="cancelBtn" onClick={this.props.onClose} className="btn-yes bo">Cancel</button>
                    <RefrenceDataUpdateContainer 
                    getReferenceDataList = {this.props.getReferenceDataList}
                    prevVersion = {this.state.prevVersion}
                    data={data} prevData={this.props.data} selectedTable={selectedTable}/>
                {/* <button className="btn-yes right" onClick={this.doneHandler}>Done</button> */}
                {/* <button onClick={this.props.onClose} className="btn-close">No</button> */}
            </div>
          {/* <button onClick={this.props.onClose}>Close</button> */}
        </div>
        {this.props.children}
      </div>,
      modalRoot,
    )
  }
}


 class ReferenceModal extends Component {
  constructor(props) {
    super(props)
    this.state = {showModal: false}
  }

  handleShowMessageClick = () => {
    // if(this.props.data)

    this.setState({showModal: true})
  }
  handleAddDataPopup = (props) =>{
    // alert(JSON.stringify(props))
  }
  handleCloseModal = () => this.setState({showModal: false})
  render() {
    // alert(JSON.stringify(this.props))
    return (
      <div
        // style={{
        //   height: '100%',
        // }}
      >
       
          {/* <button onClick={()=>alert(this.props.selectedTable)} className="send-btn">
            Add Data
          </button> */}
        {/* <AddRefrenceDataContainer  selectedTable = {this.props.selectedTable}/> */}
          <button id="editBtn" onClick={this.handleShowMessageClick} className="edit">
            Edit
          </button>
          {this.state.showModal ? (
            
            <Modal onClose={this.handleCloseModal}
            data = {this.props.data}
            selectedTable = {this.props.selectedTable}
            getReferenceDataList ={this.props.getReferenceDataList}
            >
            </Modal>
          ) : null}
       
      </div>
    )
  }
}

export default ReferenceModal
