import React, { Component, Fragment } from "react";
//import './FilterField.css'
import { FilterFieldStatus } from "./FilterFieldConstants";
import FilterField from "./FilterField";
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar/";
// import Loading from "../../Utils/Loading";

export class FilterFieldView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mount: false,
      table: props.table
    };
  }
  closeSnackBar = () => {
    this.setState({
      open: false
    });
  };
  componentDidMount() {
    // this.props.getFilterField();
    // console.log("FilterField",this.props.getFilterField())
  }
  static getDerivedStateFromProps(props, state) {
    if (props.table !== state.table) {
      var data = {
        table_name: props.table
      };

      //alert(props.table)
      props.getFilterField(data);
      return {
        table: props.table
      };
    } else return null;
  }
  getScreen(status) {
    // alert(status)
    switch (status) {
      case FilterFieldStatus.FilterField_Get.NEW:
        //  alert(JSON.stringify( this.props.FilterField))
        return (
          <FilterField
            FilterField={this.props.FilterField}
            inputValue={this.props.inputValue}
            flag={this.props.flag}
            errorMsg="Field Required"
            onChangeEvent={this.props.onChangeEvent}
            disabled={this.props.disabled}
          />
        );

      case FilterFieldStatus.FilterField_Get.SUCCESS:
        return (
          <FilterField
            FilterField={this.props.FilterField}
            inputValue={this.props.inputValue}
            flag={this.props.flag}
            errorMsg="Field Required"
            onChangeEvent={this.props.onChangeEvent}
            disabled={this.props.disabled}
          />
        );
      case FilterFieldStatus.FilterField_Get.FAILED:
        //  alert(JSON.stringify( this.props.FilterField))
        return (
          <Fragment>
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              //  TransitionComponent={<Slide {...props} direction="up" />}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={
                <span id="message-id">Unable to get list of applicants</span>
              }
            />
            <FilterField
              FilterField={this.props.FilterField}
              inputValue={this.props.inputValue}
              flag={this.props.flag}
              errorMsg="Field Required"
              onChangeEvent={this.props.onChangeEvent}
              disabled={this.props.disabled}
            />
          </Fragment>
        );

      case FilterFieldStatus.FilterField_Get.LOADING:
        this.state.open = true;
        return (
        //   <div style={{ textAlign: "center",width: "10%", float: "left", paddingTop:"24px" }}>
        //   <Loading/>
        // </div>

        <FilterField
        FilterField={this.props.FilterField}
        inputValue={this.props.inputValue}
        flag={this.props.flag}
        errorMsg="Field Required"
        onChangeEvent={this.props.onChangeEvent}
        disabled={this.props.disabled}
        LoadingIcon={true}
        loaderStyle={{left:"80px", bottom:"-4px"}}

      />
      //   <div className='outer-loader'>
      //   <div className='inner-loader'  style={{left:"715px"}}>
      //     <span className='input-group-addon'>
      //       <i class='fa fa-refresh fa-spin bc'></i>
      //     </span>
      //   </div>
      // </div>
        );
      default:
        return <div />;
    }
  }
  render() {
    // alert(this.props.history)
    return this.getScreen(this.props.FilterField_Get_status);
  }
}

export default withRouter(FilterFieldView);
