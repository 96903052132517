import React, { Component, Fragment } from 'react'
//import './DeleteReferenceData.css'
import { DeleteReferenceDataStatus } from './DeleteReferenceDataConstants';
import DeleteReferenceData from "./DeleteReferenceData"
import { withRouter } from 'react-router-dom';
//import Fragment from "../ConfirmationContract/ConfirmationContract"
//import Snackbar from '@material-ui/core/Snackbar/';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from '../../Utils/Loading';

export class DeleteReferenceDataView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      isLoading:false
    }
 // props.resetReducerState()
  }
  closeSnackBar = () => {
  
  }

  delete_Handler =()=>{
    // data={item}                         
    // selectedTable = {this.props.selectedTable}
    // alert(JSON.stringify(this.props.data)+'\n'+this.props.selectedTable)
    const data = {
      tableName:this.props.selectedTable,
      data:JSON.stringify(this.props.data),
      getReferenceDataList:this.props.getReferenceDataList
    }
    this.state.isLoading = true
    this.props.handleDeleteReferenceData(data)
  }

  getScreen(status) {
    switch (status) {
      case DeleteReferenceDataStatus.DeleteReferenceData_DELETE.NEW:
       // setRecentlyDeleted={this.props.setRecentlyDeleted}
               
        return (
          <Fragment >
            <DeleteReferenceData 
            delete_Handler = {this.delete_Handler}
            data = {this.props.data}
            selectedTable = {this.props.selectedTable}
            />
          </Fragment>
        );


      case DeleteReferenceDataStatus.DeleteReferenceData_DELETE.SUCCESS:
        if(this.state.isLoading)
       { 
        this.state.isLoading = false
        toast.success(this.props.DeleteReferenceData_Message)
        //  this.props.getReferenceDataList(this.props)
       }
       return (
          <Fragment >
            <DeleteReferenceData 
             delete_Handler = {this.delete_Handler}
             data = {this.props.data}
             selectedTable = {this.props.selectedTable}
            />
          </Fragment>
        );
        break;
      case DeleteReferenceDataStatus.DeleteReferenceData_DELETE.FAILED:
        if(this.state.isLoading)
       { 
        this.state.isLoading = false
         toast.error(this.props.DeleteReferenceData_Message)
       }
        return (
          <Fragment>
            
            <DeleteReferenceData 
               delete_Handler = {this.delete_Handler}
               data = {this.props.data}
               selectedTable = {this.props.selectedTable}
            />

          </Fragment>
        );

      case DeleteReferenceDataStatus.DeleteReferenceData_DELETE.LOADING:
        if(this.state.isLoading)
        return (
          <div 
          // className = {this.props.className}
          style={{
            textAlign: "flex-end",
            display: "flex",
            justifyContent: "flex-end",
            alignSelf: "flex-end",
            paddingLeft:20
          }}
          >
           
            <Loading/>
          </div>
        )
        else
        return (
          <Fragment>
            
            <DeleteReferenceData 
               delete_Handler = {this.delete_Handler}
               data = {this.props.data}
               selectedTable = {this.props.selectedTable}
            />

          </Fragment>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
    // alert(this.props.DeleteReferenceData_Status)
    return (
      this.getScreen(this.props.DeleteReferenceData_Status)
    )
  }
}

export default withRouter(DeleteReferenceDataView)
