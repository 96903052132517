import { combineReducers } from 'redux';

import {
  NJT_ExchangeRate_Get_Reducer,
  NJT_ExchangeRate_Post_Reducer,
} from '../Components/ExchangeRateAdmin/reducer';
import UnitsReducer from '../Components/ExchangeRateAdmin/UnitSelector/reducer';
import { NJT_BusinessEnrollment_Post_Reducer } from '../Components/EnrollmentForm/reducer';
import ExchangeRatesDollarToPoints_Post_Reducer from '../Components/ExchangeRatesDollarToPoints/reducer';
import ExchangeRatesPointsToCoupon_Post_Reducer from '../Components/ExchangeRatesPointsToCoupon/reducer';
import AuthenticationReducer from '../Components/Authentication/AuthenticationReducer';
import AdministrationLoginReducer from '../Components/AdministrationLoginPage/AdministrationLoginReducer';
import AdministrationLogoutReducer from '../Components/AdministrationMain/AdministrationLogout/AdministrationLogoutReducer';
import DeleteExchangeRatesReducer from '../Components/ExchangeRatesDollarToPoints/DeleteExchangeRates/DeleteExchangeRatesReducer';
import FilterTableReducer from '../Components/ViewChangeLogs/FilterTable/reducer';
import FilterFieldReducer from '../Components/ViewChangeLogs/FilterField/reducer';
import ViewChangeLogsTableReducer from '../Components/ViewChangeLogs/ViewChangeLogsTable/reducer';
// import BusinessMessagesReducer from '../Components/BusinessMessages/reducer';
import ReferenceDataListReducer from '../Components/ReferenceDataConfiguration/ReferenceDataList/ReferenceDataList_Reducer';
import ReferenceTableDropDownReducer from '../Components/ReferenceDataConfiguration/ReferenceTableDropDown/ReferenceTableDropDown_Reducer';
import RefrenceDataUpdateReducer from '../Components/ReferenceDataConfiguration/RefrenceDataUpdate/RefrenceDataUpdateReducer';
import DeleteReferenceDataReducer from '../Components/ReferenceDataConfiguration/DeleteReferenceData/DeleteReferenceDataReducer';
import AddRefrenceDataReducer from '../Components/ReferenceDataConfiguration/AddRefrenceData/AddRefrenceDataReducer';
import AddNewUserReducer from '../Components/UserManagement/AddNewUser/AddNewUserReducer';
import UsersInventoryReducer from '../Components/UserManagement/UsersInventory/UsersInventoryReducer';
import ChangePasswordReducer from '../Components/UserManagement/ChangePassword/ChangePasswordReducer';
import ChangeEmailReducer from '../Components/UserManagement/ChangeEmail/ChangeEmailReducer';
import RetailerListReducer from '../Components/RetailerList/RetailerListReducer';
import UserRoleListReducer from '../Components/UserManagement/AddNewUser/UserRoleList/UserRoleListReducer';
// import GetDisplayTypesReducer from '../Components/BusinessContract/GetDisplayTypes/GetDisplayTypesReducer';
// import BusinessCategoryReducer from '../Components/BusinessProfile/BusinessCategory/BusinessCategoryReducer';

const reducer = combineReducers({
  NJT_ExchangeRate_Get_Reducer,
  NJT_ExchangeRate_Post_Reducer,
  NJT_BusinessEnrollment_Post_Reducer,
  UnitsReducer,
  ExchangeRatesDollarToPoints_Post_Reducer,
  ExchangeRatesPointsToCoupon_Post_Reducer,
  AuthenticationReducer,
  AdministrationLoginReducer,
  AdministrationLogoutReducer,
  DeleteExchangeRatesReducer,
  FilterTableReducer,
  FilterFieldReducer,
  ViewChangeLogsTableReducer,
  // BusinessMessagesReducer,
  ReferenceDataListReducer,
  ReferenceTableDropDownReducer,
  RefrenceDataUpdateReducer,
  DeleteReferenceDataReducer,
  AddRefrenceDataReducer,
  AddNewUserReducer,
  UsersInventoryReducer,
  ChangePasswordReducer,
  ChangeEmailReducer,
  RetailerListReducer,
  UserRoleListReducer
});
export default reducer;
