import React, { Component, Fragment } from "react";
import "./ViewChangeLogsTable.css";
// import dateFormat from "dateformat";
import {ref_table} from 'RefRecordsConstants'
import moment from "moment";
// import Tooltip from '@material-ui/core/Tooltip';
import RotateLoading from "../../Utils/RotateLoading";
// req.body.field_name == 'points_send_dateTime'

//import { CSVLink } from "react-csv";

// const items = [
//   {
//     userName: "Jhon_connor",
//     tableName: "tbl_businesses",
//     field_name: "business_name",
//     transaction_type_id: "update",
//     original_value: "Junkin's Hair Salon",
//     new_value: "Murphy's Hair Salon",
//     transaction_datetime: "Oct 2nd 2019 - 4:30pm" //mmm dd yyyy - HH:MM TT
//   },
//   {
//     userName: "Jhon_connor",
//     tableName: "tbl_businesses",
//     field_name: "business_name",
//     transaction_type_id: "update",
//     original_value: "Junkin's Hair Salon",
//     new_value: "Murphy's Hair Salon",
//     transaction_datetime: "Oct 2nd 2019 - 4:30pm"
//   },
//   {
//     userName: "Jhon_connor",
//     tableName: "tbl_businesses",
//     field_name: "business_name",
//     transaction_type_id: "update",
//     original_value: "Junkin's Hair Salon",
//     new_value: "Murphy's Hair Salon",
//     transaction_datetime: "Oct 2nd 2019 - 4:30pm"
//   },
//   {
//     userName: "Jhon_connor",
//     tableName: "tbl_businesses",
//     field_name: "business_name",
//     transaction_type_id: "update",
//     original_value: "Junkin's Hair Salon",
//     new_value: "Murphy's Hair Salon",
//     transaction_datetime: "Oct 2nd 2019 - 4:30pm"
//   }
// ];
export class ViewChangeLogsTable extends Component {
  downloadHandler = () => {
    //  alert("downloadHandler works");
  };

  render() {
    const { ViewChangeLogsTable = [] } = this.props;
    // console.log("ViewChangeLogsTable :", JSON.stringify(ViewChangeLogsTable));
    // const headers = [
    //   { label: "table_name", key: "table_name" },
    //   { label: "field_name", key: "field_name" },
    //   { label: "transaction_type_id", key: "transaction_type_id" },
    //   { label: "original_value", key: "original_value" },
    //   { label: "new_value", key: "new_value" },
    //   { label: "transaction_datetime", key: "transaction_datetime" }
    // ];
    
    // const data = ViewChangeLogsTable
      // (undefined !== ViewChangeLogsTable && ViewChangeLogsTable.length === 0) ||
      // ViewChangeLogsTable === null ||
      // ViewChangeLogsTable === undefined
      //   ? ""
      //   : ViewChangeLogsTable;

    return (
      <Fragment>
        <div className="vclt_conatiner padd-handle">
          <div className="vclt-col-wrap">
            <div
              style={{
                marginBottom:
                  (undefined !== ViewChangeLogsTable &&
                    ViewChangeLogsTable.length === 0) ||
                  ViewChangeLogsTable === null ||
                  ViewChangeLogsTable === undefined
                    ? "40px"
                    : null
              }}
              className="vclt-column-box header"
            >
              <div className="vclt-col-12">
              <h5>Sr. #</h5>
            </div>
              <div className="vclt-col-1">
                <h5>Table Name</h5>
              </div>
              <div className="vclt-col-1 vclt-col-f">
                <h5>Field Name</h5>
              </div>
              <div className="vclt-col-1 vclt-col-a">
                <h5>Action</h5>
              </div>
              {
                this.props.tableID == ref_table.tbl_promotional_points_log ?
                null:
                <div className="vclt-col-1">
                <h5>Original Value</h5>
                </div>
              }
              {/* <div className="vclt-col-1">
                <h5>Original Value</h5>
              </div> */}
              <div className="vclt-col-1">
                <h5>New Value</h5>
              </div>
              {
                this.props.tableID == ref_table.tbl_promotional_points_log ?
                <div className="vclt-col-1">
                  <h5>Points</h5>
                </div>
                :
                null
              }
              <div className="vclt-col-1">
                <h5>Date & Time of Change</h5>
              </div>
            </div>
            <div className="scroll-vclt">
            {this.props.filterMsg =="No log records found" ?
            <h3 style={{ margin: "40px" }}>
                {this.props.filterMsg}
          </h3>:
            (undefined !== ViewChangeLogsTable &&
              ViewChangeLogsTable.length === 0) ||
            ViewChangeLogsTable === null ||
            ViewChangeLogsTable === undefined ? (
              <>
                {!this.props.hideText &&
                <div className="shakir" style={{display:"flex", justifyContent:"center"}}>
                  
              <h3 style={{ margin: "40px" }}>
     {this.props.showLoader &&      <span> <RotateLoading LoadingIcon={true} loaderStyle={{left:"-25px"}} /> </span> }     Please select filters to view the required records</h3>
                </div>
  }
              <div className="icon-vclt">
              {this.props.hideText &&
                <h3 style={{ margin: "40px" }}>
                Please Wait...
                <span>
                {this.props.LoadingIcon && (
                  <div className='outer-loader'>
              <div className='inner-loader'  style={{left:"-30px", bottom:"-2px"}}>
                <span className='input-group-addon'>
                  <i class='fa fa-refresh fa-spin bc'></i>
                </span>
              </div>
            </div>
          )}
             </span>
             </h3>
        }
          </div>          
         
              </>
              
            ) : (
              ViewChangeLogsTable.map((item, index) => (
                <div className="vclt-column-box-in border" key={index}>
                  <div className="vclt-col-12">
                  <p>{index+1}</p>
                </div>
                  <div className="vclt-col-1">
                    <p>{item.table_name}</p>
                  </div>
                  <div className="vclt-col-1 vclt-col-f">
                    <p>{item.field_name}</p>
                  </div>
                  <div className="vclt-col-1 vclt-col-a">
                    <p>
                      {item.transaction_type_name}
                    </p>
                  </div>
                  {/* <div className="vclt-col-1 url-b">
                    <p>{/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(item.original_value)?
                    dateFormat(item.original_value,"mm/dd/yyyy"):item.original_value}</p>
                  </div> */}

                  {
                    this.props.tableID == ref_table.tbl_promotional_points_log ?
                    null:
                    <div className="vclt-col-1 url-b">
                    <p>{/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(item.original_value)?
                    moment(item.original_value).format("MM/DD/YYYY")
                    // dateFormat(item.original_value,"mm/dd/yyyy")
                    :item.original_value}</p>
                  </div>
                  }
                   
                  <div className="vclt-col-1  url-b" data-toggle="tooltip" data-placement="top" title={item.new_value}>
                    <p>{item.field_name === "points_send_dateTime"? moment(new Date(item.new_value)).format("MM/DD/YYYY - hh:mm"):
                  /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(item.new_value)?
                      moment(item.new_value).format("MM/DD/YYYY")
                    // dateFormat(item.new_value,"mm/dd/yyyy")
                    :item.new_value?.toString()?.split(',')?.length>1?item?.new_value?.split(',')[0]+'...':item.new_value}</p>
                  </div>
                  
                  {
                    this.props.tableID == ref_table.tbl_promotional_points_log ?
                    <div className="vclt-col-1">
                      <p>{item.number_of_points}</p>
                    </div>
                    :
                    null
                  }

                  <div className="vclt-col-1">
                    <p>
                      {
                        moment(item.transaction_datetime).format("MM/DD/YYYY - hh:mm")
                      // dateFormat(item.transaction_datetime,
                        // "mm/dd/yyyy - HH:MM")
                        }
                    </p>
                  </div>
                </div>
                
              ))
            )}
            </div>
          </div>
        </div>
        {/* <button className="vcl_btn2" onClick={this.downloadHandler}>
          <CSVLink data={data} headers={headers} style={{ color: "white" }}>
            <i className="material-icons vcl_icon">get_app</i>
          </CSVLink>
        </button> */}
      </Fragment>
    );
  }
}

export default ViewChangeLogsTable;
