import React, { Component } from 'react'
import "../HeaderSection/HeaderBranding.css"
import { Link, withRouter} from "react-router-dom";
// import logo from "../../Assets/Images/logo-2.png"
// import {withCookies} from 'react-cookie'
import { ADMIN_END_BRANDING_IMAGE_URL,STATIC_IMAGES_URL } from "../../Config/Config"
import AdministrationLogout from '../AdministrationMain/AdministrationLogout/AdministrationLogoutContainer'
class HeaderBranding extends Component {
    constructor(props){
        super(props)
        this.state = {
            isSignIn: false,
        }
     //   alert(this.props.cookies)
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.location.pathname == "/")
        return { isSignIn: false};
        else
        return { isSignIn: true};
       
    }
    render() {
        return (
            <>
            
       
            <div className="main-header">
            <div className="container">
            <div className="row" style={{alignItems:"center"}}>
                <div className="col-8">
                {/* <div className="header-log"> */}
                <div className="left-hb">
                <Link className="in-header-link" id="homeLink" to={this.state.isSignIn ?"/Admin":"/"}><img className="logo" src={ADMIN_END_BRANDING_IMAGE_URL+'Njt-Logo-admin.png'} width="70px" height="70px"  alt="Logo not Find"/></Link>
                <div className="icon-hb">
                    <img src={STATIC_IMAGES_URL+'admin.svg'} alt="" />
                </div>
                <div className="title-hb">
                    Admin Support
                </div>
                </div>
                </div>
                <div className="col-4">         
                 <div className="in-header-btn-box-hb">
                    { this.state.isSignIn ?
                        <AdministrationLogout
                            cookies={this.props.cookies}
                           className="inner-header-btn-hb" /> 
                           :null
                    }
                </div>
                    </div>
            </div>
        </div>
      
            </div>
            </>
        )
    }
}

export default withRouter(HeaderBranding)

