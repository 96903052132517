import React, { Component } from "react";

import "./CustomLabelDropDownInput.css";

const Data = [
  { value: "selectState", name: "Select State" },
  { value: "NJ", name: "NJ" },
  { value: "NY", name: "NY" },
  { value: "PA", name: "PA" }
];

export class LabelDropDownInput extends Component {
  constructor() {
    super();
  }

  render() {
    const {
      data = Data,
      onChange,
      containerStyle,
      inputValue,
      label = "label",
      errorMsg = "errorMsg",
      flag = false,
      name,
      value = "",
      firstName = value,
      id = "",
      disabled,
      hideFirst = false,
      icon="expand_more"
    } = this.props;
    return (
      <div className="clddi_input_container" style={containerStyle}>
        <label htmlFor="" className="clddi_label">
          {label}
        </label>
        <div className="clddi_div1">
          <div className="clddi_container">
            <select
              id={id}
              className="clddi_input"
              value={inputValue}
              onChange={onChange}
            >
             {hideFirst? <option value={value}>{firstName}</option>:null}
              {!disabled ? (
                data.map((item, index) => {
                  return (
                    <option
                      value={JSON.stringify(item)}
                      key={id.length ? item[id] : index}
                    >
                      {item[name]}
                    </option>
                  );
                })
              ) : (
                <option value={inputValue} key={1}>
                  {inputValue}
                </option>
              )}
            </select>
              <i className="material-icons clddi_icon">{icon}</i>
          </div>
          <p
            className="clddi_error_text"
            style={{ display: flag ? "block" : "none" }}
          >
            {errorMsg}
          </p>
        </div>
      </div>
    );
  }
}

export default LabelDropDownInput;
