import { DeleteExchangeRatesStatus, DeleteExchangeRatesActions } from './DeleteExchangeRatesConstants';

const DeleteExchangeRates_initialState = {
    DeleteExchangeRates_Status: DeleteExchangeRatesStatus.DeleteExchangeRates_DELETE.NEW,
    DeleteExchangeRates_Message: '',
    DeleteExchangeRatesDetails: [],
}
export default  function  (state = DeleteExchangeRates_initialState, action) {
    switch (action.type) {
        case DeleteExchangeRatesActions.DeleteExchangeRates_DELETE.LOADING:
            return { ...state, DeleteExchangeRates_Status: DeleteExchangeRatesStatus.DeleteExchangeRates_DELETE.LOADING }
            case DeleteExchangeRatesActions.DeleteExchangeRates_DELETE.NEW:
                return { ...state, DeleteExchangeRates_Status: DeleteExchangeRatesStatus.DeleteExchangeRates_DELETE.NEW, DeleteExchangeRates_Message:"" }
    
        case DeleteExchangeRatesActions.DeleteExchangeRates_DELETE.FAILED:
            return { ...state,  DeleteExchangeRates_Status: DeleteExchangeRatesStatus.DeleteExchangeRates_DELETE.FAILED, DeleteExchangeRates_Message:action.error}
        case DeleteExchangeRatesActions.DeleteExchangeRates_DELETE.SUCCESS:
          
          return { ...state,  DeleteExchangeRates_Status: DeleteExchangeRatesStatus.DeleteExchangeRates_DELETE.SUCCESS, DeleteExchangeRatesDetails:action.payload, DeleteExchangeRates_Message:action.message}
          
        default:
            return { ...state
            }
    }
}
