import React, { Component, Fragment } from "react";
//import './AddRefrenceData.css'
import { AddRefrenceDataStatus } from "./AddRefrenceDataConstants";
import AddRefrenceData from "./AddRefrenceData";
import { withRouter } from "react-router-dom";
//import Fragment from "../ConfirmationContract/ConfirmationContract"
//import Snackbar from '@material-ui/core/Snackbar/';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Loading from "../../Utils/Loading";

export class AddRefrenceDataView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      selectedTable: props.selectedTable,
      isLoading: false,
    };
    // props.resetReducerState()
  }
  // static getDerivedStateFromProps(props, state) {
  //   console.log(props.selectedTable, state.selectedTable);
  //   if (
  //     JSON.stringify(props.selectedTable) !==
  //     JSON.stringify(state.selectedTable)
  //   ) {
  //     if (props.selectedTable != "") {
  //       //alert(JSON.stringify(props.selectedTable))

  //       const data = {
  //         tableName: props.selectedTable,
  //       };
  //       props.getFieldsOfTable(data);
  //     } else {
  //       props.resetReducerState();
  //     }
  //     //      props.getReferenceDataList(props.selectedTable);

  //     return {
  //       selectedTable: props.selectedTable,
  //       isLoading: true,
  //     };
  //   } else return null;
  // }

  addHandler = (data) => {
    var data1 = this.props.RefrenceDataFields
    data1.forEach((e)=>{
      delete e.value
      delete e.flag
    })
    var data = {
      tableName:this.props.selectedTable,
      data:data
    }
    //alert(JSON.stringify(data))
    this.state.isLoading = true
    this.props.handleAddRefrenceData(data)
   
  };
  getScreen(status) {
    switch (status) {
      case AddRefrenceDataStatus.RefrenceData_Add.NEW:
        // setRecentlyDeleted={this.props.setRecentlyDeleted}

        return (
          <Fragment>
            <AddRefrenceData
              addHandler={this.addHandler}
              selectedTable={this.props.selectedTable}
              RefrenceDataFields={this.props.RefrenceDataFields}
            />
          </Fragment>
        );


      case AddRefrenceDataStatus.RefrenceData_Add.SUCCESS:
      
        if (this.state.isLoading) {
          // toast.success(this.props.AddRefrenceData_Message);
          // this.props.getReferenceDataList(this.props);
          this.state.isLoading = false;
          // this.props.resetReducerState()
        }
        return (
          <Fragment>
            <AddRefrenceData
              addHandler={this.addHandler}
              selectedTable={this.props.selectedTable}
              RefrenceDataFields={this.props.RefrenceDataFields}
            />
          </Fragment>
        );
        break;
      case AddRefrenceDataStatus.RefrenceData_Add.FAILED:
        if (this.state.isLoading) {
          toast.error(this.props.AddRefrenceData_Message);
          this.state.isLoading = false;
        }
        return (
          <Fragment>
            <AddRefrenceData
              addHandler={this.addHandler}
              selectedTable={this.props.selectedTable}
              RefrenceDataFields={this.props.RefrenceDataFields}
            />
          </Fragment>
        );

      case AddRefrenceDataStatus.RefrenceData_Add.LOADING:
        return (
          <div
            style={{
              textAlign: "flex-end",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Loading />
          </div>
        );
        break;
      default:
        return <div />;
    }
  }
  render() {
    // alert(this.props.AddRefrenceData_Status)
    return this.getScreen(this.props.AddRefrenceData_Status);
  }
}

export default withRouter(AddRefrenceDataView);
