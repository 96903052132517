import React, { Component } from "react";
import CustomDropDownInput from "../../Inputs/CustomDropDownInput/CustomDropDownInput";
import RotateLoading from "../../Utils/RotateLoading";

class FilterField extends Component {
  render() {
    console.log("Child :", this.props.FilterField)
    const {
      inputValue,
      flag,
      errorMsg,
      onChangeEvent,
      data,
      disabled,
      FilterField
    } = this.props;
    return (
      <div className="vcl_div vcl_div3 dss">
        <div className="vcl_p1">
          Filter: <span className="vcl_sp1">Field</span> 
           <span> <RotateLoading LoadingIcon={this.props.LoadingIcon} loaderStyle={this.props.loaderStyle} /> </span>
        </div>
        <CustomDropDownInput
          // data={data}
          id="COLUMN_NAME"
          FirstName="Select"
          data={FilterField}
          value="COLUMN_NAME"
          name="COLUMN_NAME"
          inputValue={inputValue}
          flag={flag}
          errorMsg={errorMsg}
          onChangeEvent={onChangeEvent}
          disabled={disabled}
        />
     
      </div>
    );
  }
}

export default FilterField;
