import {
    FilterTableStatus,
    FilterTableActions
  } from "./FilterTableConstants";
  
  const FilterTable_initialState = {
    FilterTable_Status: FilterTableStatus.FilterTable_Get.NEW,
    FilterTable_Error: "",
    FilterTableDetails: []
  };
  export default function(state = FilterTable_initialState, action) {
    switch (action.type) {
      case FilterTableActions.FilterTable_Get.LOADING:
        return {
          ...state,
          FilterTable_Status:
            FilterTableStatus.FilterTable_Get.LOADING
        };
      case FilterTableActions.FilterTable_Get.NEW:
        return {
          ...state,
          FilterTable_Status:
            FilterTableStatus.FilterTable_Get.NEW,
          FilterTableDetails: []
        };
      case FilterTableActions.FilterTable_Get.SUCCESS:
        return {
          ...state,
          FilterTable_Status:
            FilterTableStatus.FilterTable_Get.SUCCESS,
          FilterTableDetails: action.payload
        };
      case FilterTableActions.FilterTable_Get.FAILED:
        return {
          ...state,
          FilterTable_Status:
            FilterTableStatus.FilterTable_Get.FAILED
        };
  
      default:
        return {
          ...state,
          FilterTable_Error: "",
          FilterTable_Status: FilterTableStatus.FilterTable_Get.NEW
        };
    }
  }
  