import {
    ReferenceTableDropDownStatus,
    ReferenceTableDropDownActions
  } from "./ReferenceTableDropDown_Constants";
  
  const ReferenceTableDropDown_initialState = {
    ReferenceTableDropDown_Status: ReferenceTableDropDownStatus.ReferenceTableDropDown_Get.NEW,
    error: "",
    message:"",
    dataTable: []
  };
  export default function(state = ReferenceTableDropDown_initialState, action) {
    switch (action.type) {
      case ReferenceTableDropDownActions.ReferenceTableDropDown_Get.LOADING:
        return {
          ...state,
          ReferenceTableDropDown_Status:
            ReferenceTableDropDownStatus.ReferenceTableDropDown_Get.LOADING,
            dataTable: []
        };
      case ReferenceTableDropDownActions.ReferenceTableDropDown_Get.NEW:
        return {
          ...state,
          ReferenceTableDropDown_Status:
            ReferenceTableDropDownStatus.ReferenceTableDropDown_Get.NEW,
            dataTable: []
        };
      case ReferenceTableDropDownActions.ReferenceTableDropDown_Get.SUCCESS:
       
        let arr=action.payload.data
        // arr = arr.unshift({ value: "", name: "Select Table" }) 
        // alert(JSON.stringify([{ value: "", name: "Select Table" }, ...arr]))
        return {
          ...state,
          ReferenceTableDropDown_Status:
            ReferenceTableDropDownStatus.ReferenceTableDropDown_Get.SUCCESS,
            dataTable: [{ value: "", name: "Select Table" }, ...arr]
        };
      case ReferenceTableDropDownActions.ReferenceTableDropDown_Get.FAILED:
        return {
          ...state,
          ReferenceTableDropDown_Status:
            ReferenceTableDropDownStatus.ReferenceTableDropDown_Get.FAILED,
            dataTable: [],
            error:action.payload
        };
  
      default:
        return {
          ...state,
          
        };
    }
  }
  