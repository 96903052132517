import { DeleteReferenceDataStatus, DeleteReferenceDataActions } from './DeleteReferenceDataConstants';

const DeleteReferenceData_initialState = {
    DeleteReferenceData_Status: DeleteReferenceDataStatus.DeleteReferenceData_DELETE.NEW,
    DeleteReferenceData_Message: '',
    DeleteReferenceDataDetails: [],
}
export default  function  (state = DeleteReferenceData_initialState, action) {
    switch (action.type) {
        case DeleteReferenceDataActions.DeleteReferenceData_DELETE.LOADING:
            return { ...state, DeleteReferenceData_Status: DeleteReferenceDataStatus.DeleteReferenceData_DELETE.LOADING }
            case DeleteReferenceDataActions.DeleteReferenceData_DELETE.NEW:
                return { ...state, DeleteReferenceData_Status: DeleteReferenceDataStatus.DeleteReferenceData_DELETE.NEW, DeleteReferenceData_Message:"" }
    
        case DeleteReferenceDataActions.DeleteReferenceData_DELETE.FAILED:
            return { ...state,  DeleteReferenceData_Status: DeleteReferenceDataStatus.DeleteReferenceData_DELETE.FAILED, DeleteReferenceData_Message:action.error}
        case DeleteReferenceDataActions.DeleteReferenceData_DELETE.SUCCESS:
          
          return { ...state,  DeleteReferenceData_Status: DeleteReferenceDataStatus.DeleteReferenceData_DELETE.SUCCESS, DeleteReferenceDataDetails:action.payload, DeleteReferenceData_Message:action.message}
          
        default:
            return { ...state
            }
    }
}
