import React from 'react'

const RotateLoading = (props) => {
    const {loaderStyle, LoadingIcon} = props;
    return (
        <>
             {LoadingIcon && (
              
            <div className='outer-loader'>
              <div className='inner-loader' style={loaderStyle}>                
                <span className='input-group-addon'>
                  <i className='fa fa-refresh fa-spin bc'></i>
                </span>
              </div>
            </div>
               
             )}
        </>
    )
}

export default RotateLoading
