import { connect } from "react-redux";
import RefrenceDataUpdateView from "./RefrenceDataUpdate_View";
import {RefrenceDataUpdateServer} from "./server"
import {RefrenceDataUpdateActions} from './RefrenceDataUpdateConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        RefrenceDataUpdate_Status:state.RefrenceDataUpdateReducer.RefrenceDataUpdate_Status,
        RefrenceDataUpdate:state.RefrenceDataUpdateReducer.RefrenceDataUpdateDetails,
        RefrenceDataUpdate_Message:state.RefrenceDataUpdateReducer.RefrenceDataUpdate_Message
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        handleRefrenceDataUpdate: (data) => {

           dispatch( RefrenceDataUpdateServer.handleRefrenceDataUpdate(data) )
        },
        resetReducerState:async ()=>{
           // await dispatch({type:RefrenceDataUpdateActions.RefrenceData_Update.LOADING})
             dispatch({type:RefrenceDataUpdateActions.RefrenceData_Update.NEW})
    }
    }
};
const RefrenceDataUpdateContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RefrenceDataUpdateView)

export default RefrenceDataUpdateContainer;